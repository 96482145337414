import React from 'react';

import { PAGES } from 'common/const';

const ConfirmationResult = ({ state }) => {
    return (
        <div className="page-content payment-page payment-success-page">
            <div className="order-main">
                <section className="step-wrapper">
                    <div className="header">
                        <a href={PAGES.LANDING} className="navbar-brand">
                            <img
                                className="logo"
                                title="Home"
                                alt="logo"
                                src="/img/logo_ld_large.png"
                            />
                        </a>
                    </div>
                    <div className="step-provider">
                        <div className="order-main container paid-successfully">
                            <div className="step-page">
                                <i className="icon-done" />
                                <div className="page-title text-center">
                                    Thank you for your confirmation!
                                </div>
                                <div className="page-note text-center">
                                    {`You have ${state} the offer`}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ConfirmationResult;
