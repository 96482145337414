import React from 'react';
import { array, func, any, string, bool } from 'prop-types';
import ReactSelect from 'react-select';

export class Select extends React.Component {
    static propTypes = {
        id: string.isRequired,
        label: string.isRequired,
        value: string,
        options: array,
        placeholder: string,
        required: bool,
        error: any,
        className: string,
        setFieldValue: func.isRequired,
        setFieldTouched: func.isRequired
    };

    state = {
        value: this.props.value
    };

    handleChange = selected => {
        const { id, setFieldValue, setFieldTouched } = this.props;

        if (selected != null) {
            setFieldValue(id, selected.value);
            setFieldTouched(id, true);
            this.setState({ value: selected.value });
        } else {
            setFieldValue(id, '');
            this.setState({ value: '' });
        }
    };

    render() {
        const {
            id,
            error,
            label,
            className,
            options,
            placeholder,
            required
        } = this.props;
        const { value } = this.state;

        return (
            <div className={`form-group ${className}`}>
                <label className="form-label">
                    {label} {required && <span>(Required)</span>}
                </label>
                <ReactSelect
                    id={id}
                    multi={false}
                    closeOnSelect={true}
                    value={
                        options
                            ? options.find(option => option.value === value)
                            : ''
                    }
                    onChange={data => {
                        this.handleChange(data);
                    }}
                    options={options}
                    placeholder={placeholder}
                    isSearchable
                    className="select"
                />
                <div className={error ? 'error' : ''}>
                    {error && <div className="input-feedback">{error}</div>}
                </div>
            </div>
        );
    }
}
