import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { startNewOrder } from './actions';

import './order.css';
import './PaidSuccessResult-style.css';

const PaidSuccessResult = ({ startNewOrder }) => {
    return (
        <div className="order-main paid-successfully">
            <div className="step-page">
                <i className="icon-done" />
                <div className="page-title text-center">
                    Thank you for your order!
                </div>
                <div className="page-note text-center">
                    You have ordered successfully. We'll get on it asap.
                </div>

                <div className="text-center">
                    <button
                        type="button"
                        className="btn btn-next"
                        onClick={startNewOrder}
                    >
                        Continue Order
                    </button>
                    <span>
                        {' '}
                        or&nbsp;
                        <Link
                            className="text-btn"
                            to={`/orders`}
                            onClick={startNewOrder}
                        >
                            view your orders
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ startNewOrder }, dispatch);
};

export default connect(null, mapDispatchToProps)(PaidSuccessResult);
