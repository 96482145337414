import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, array, number, object } from 'prop-types';
import get from 'lodash/get';

import { PAGES } from 'common/const';
import { UploadBox, Alert, toJS } from 'components';
import { getFileExt } from '../common/fileUtils';
import chatBox from '../common/chatBox';
import FileFormatList from './FileFormatList';
import { uploadFile, closeMessage } from './actions';
import { FILE_ERROR_TYPES, FILE_UPLOADING_ERROR } from './constants';

import './FileUpload-style.css';

class FileUpload extends React.Component {
    static propTypes = {
        supportedFileSize: number,
        supportedFileTypes: array,
        uploadingError: object,
        uploadFile: func,
        closeMessage: func
    };
    static defaultProps = {
        supportedFileTypes: []
    };
    state = {
        formatInputError: null
    };

    onFileInput = files => {
        const { orderId, uploadFile } = this.props;
        const file = files[0];
        const formatInputError = this.validateFile(file);

        this.setState({
            formatInputError
        });

        uploadFile(orderId, files);
    };

    validateFile = file => {
        const { supportedFileSize, supportedFileTypes } = this.props;
        const errs = [];

        if (
            !supportedFileTypes.some(type => {
                return (
                    type.toLowerCase() === getFileExt(file.name).toLowerCase()
                );
            })
        ) {
            errs.push(FILE_ERROR_TYPES.NON_SUPPORTED_FILE_TYPE);
        }

        if (file.size > supportedFileSize) {
            errs.push(FILE_ERROR_TYPES.EXCEEDED_LIMIT);
        }

        return errs.length ? errs[0] : null;
    };

    closeMessage = () => {
        const { closeMessage } = this.props;

        closeMessage();

        this.setState({
            formatInputError: null
        });
    };

    componentDidUpdate() {
        const { user, uploadingError } = this.props;
        const { formatInputError } = this.state;
        const sessionKey = (user && user.userUid) + 'UPLOAD_FILE_SUPPORT';

        if (uploadingError || formatInputError) {
            chatBox.triggerMessage(sessionKey, FILE_UPLOADING_ERROR.CHAT_MSGS);
        }
    }

    render() {
        const { supportedFileTypes, uploadingError } = this.props;
        const { formatInputError } = this.state;

        return (
            <div className="step-page upload-file">
                {(formatInputError || uploadingError) && (
                    <Alert
                        type="danger"
                        title="Unsupported file"
                        message={
                            FILE_UPLOADING_ERROR[
                                get(uploadingError, 'message', '')
                            ] || FILE_UPLOADING_ERROR.ERROR_MSG
                        }
                        onClose={this.closeMessage}
                        className="error-message container-full"
                    />
                )}
                <div className="page-title text-center">Upload file</div>
                <div className="page-note text-center">
                    A translation quote for all your desired languages is a few
                    clicks away
                </div>
                <div className="upload-content-box">
                    <UploadBox
                        id="addlingo-upload-box"
                        name="upload-trans-source"
                        textContent="an English source file and get instant quote"
                        onNewFiles={this.onFileInput}
                        disabled={false}
                    />
                    <div className="section file-formats-section">
                        <h5>Supported file formats</h5>
                        <FileFormatList formats={supportedFileTypes} />
                        <div className="read-more-link">
                            <a
                                href={PAGES.LANDING + PAGES.SUPPORT}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Read more about supported file formats
                            </a>
                        </div>

                        <div className="read-more-link">
                            <a
                                href={PAGES.LANDING + PAGES.STATEMENT}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Read more about LocalizeDirect security
                                statement
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        supportedFileSize: state.getIn(['order', 'supportedFileSize']),
        supportedFileTypes: state.getIn(['order', 'supportedFileTypes']),
        uploadingError: state.getIn(['order', 'fileUploading', 'error'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            uploadFile,
            closeMessage
        },
        dispatch
    );
};

export default DragDropContext(HTML5Backend)(
    connect(mapStateToProps, mapDispatchToProps)(toJS(FileUpload))
);
