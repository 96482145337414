import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';
import { PAYMENT } from './actionConstants';

const INITIAL_STATE = fromJS({
    loading: false,
    error: null,
    invoiceId: null,
    invoice: null,
    user: null,
    paymentConfig: null
});

const handlers = {
    [PAYMENT.LOCK_PAYMENT](state, { isLock }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], isLock);
        });
    },
    [PAYMENT.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
        });
    },
    [PAYMENT.EXECUTING](state) {
        return state.setIn(['loading'], true);
    },
    [PAYMENT.ON_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['loading'], false);
        });
    },
    [PAYMENT.ON_INVOICE_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], false);

            mutableState.setIn(['paymentConfig'], data.config);
            mutableState.setIn(['invoice'], data.invoice);
            mutableState.setIn(['invoiceId'], data.invoiceNumber);
            mutableState.setIn(['user'], data.user);
        });
    },
    [PAYMENT.ON_AUTHORIZE_PAYMENT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['paymentMethod'], fromJS(data.paymentMethod));
            mutableState.setIn(['payment', 'tok'], data.paymentMethod.tok);
        });
    },
    [PAYMENT.ON_PAYMENT_INTENT_CREATED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(
                ['payment', 'payment_intent_client_secret'],
                fromJS(data.payment_intent_client_secret)
            );
        });
    },
    [PAYMENT.ON_EXECUTED_PAYMENT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['state'], 'Paid');
            mutableState.setIn(['loading'], false);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
