export const CUSTOMER = {
    CLOSE_MESSAGE: 'CUSTOMER_CLOSE_MESSAGE',
    FETCH_ORDER_LIST: 'CUSTOMER_FETCH_ORDER_LIST',
    ON_ORDER_LIST_FETCHED: 'CUSTOMER_ON_ORDER_LIST_FETCHED',
    ON_ORDER_LIST_FAILED: 'CUSTOMER_ON_ORDER_LIST_FAILED',
    FETCH_QUESTION_LIST: 'CUSTOMER_FETCH_QUESTION_LIST',
    ON_QUESTION_LIST_FETCHED: 'CUSTOMER_ON_QUESTION_LIST_FETCHED',
    ON_QUESTION_LIST_FAILED: 'CUSTOMER_ON_QUESTION_LIST_FAILED',
    FETCH_ANSWER_LIST: 'CUSTOMER_FETCH_ANSWER_LIST',
    ON_ANSWER_LIST_FETCHED: 'CUSTOMER_ON_ANSWER_LIST_FETCHED',
    ON_ANSWER_LIST_FAILED: 'CUSTOMER_ON_ANSWER_LIST_FAILED',
    ADD_ANSWER: 'CUSTOMER_ADD_ANSWER',
    ON_ANSWER_ADDED: 'CUSTOMER_ON_ANSWER_ADDED',
    ON_ADD_ANSWER_FAILED: 'CUSTOMER_ON_ADD_ANSWER_FAILED'
};
