import React from 'react';
import classNames from 'classnames';
import ReactCountryFlag from 'react-country-flag';
import { Checkbox } from 'components';
import TMIcon from './TMIcon';

const LangSelectItem = ({
    value,
    label,
    pricePerWord,
    pricePerLang,
    hasTM,
    checked,
    className,
    onSelect
}) => {
    const itemClass = classNames('lang-check', className);
    const id = `chb_${value}`;
    const onItemClick = () => {
        onSelect({ value, label });
    };

    return (
        <li className={itemClass}>
            <div className="name">
                <span className="country-flag">
                    <ReactCountryFlag
                        code={value.substring(2).toLowerCase()}
                        svg
                    />
                </span>
                {label} {hasTM && <TMIcon />}
            </div>
            <div className="price-per-word">{pricePerWord}</div>
            <div className="price-per-lang">{pricePerLang}</div>
            <div className="check">
                <Checkbox
                    className="lang-selection-checkbox"
                    value={value}
                    id={id}
                    checked={checked}
                    onChange={onItemClick}
                />
            </div>
        </li>
    );
};

export default LangSelectItem;
