export const PAYMENT = {
    LOCK_PAYMENT: 'PAYMENT_LOCK_PAYMENT',
    CLOSE_MESSAGE: 'PAYMENT_CLOSE_MESSAGE',
    EXECUTING: 'PAYMENT_EXECUTING',
    ON_INVOICE_FETCHED: 'PAYMENT_ON_INVOICE_FETCHED',
    ON_AUTHORIZE_PAYMENT: 'PAYMENT_ON_AUTHORIZE_PAYMENT',
    ON_PAYMENT_INTENT_CREATED: 'PAYMENT_ON_PAYMENT_INTENT_CREATED',
    ON_EXECUTED_PAYMENT: 'PAYMENT_ON_EXECUTED_PAYMENT',
    ON_FAILED: 'PAYMENT_ON_FAILED'
};
