const env = process.env.NODE_ENV || 'development';
const target = process.env.REACT_APP_TARGET || 'dev';
const apiUrls = {
    production: {
        dev: 'https://dev-api-onlineshop.localizedirect.com',
        prod: 'https://api-order.localizedirect.com'
    },
    development: {
        dev: 'https://dev-api-onlineshop.localizedirect.com',
        local: 'http://localhost:4008'
    }
};

const slackWebHookUrls = {
    local:
        'https://hooks.slack.com/services/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',
    dev:
        'https://hooks.slack.com/services/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',
    prod:
        'https://hooks.slack.com/services/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'
};

const AGILE_CRM_LOCAL = {
    KEY: '4u0kua1f6od0urj0v86n1h07lt',
    DOMAIN: 'lynhdang',
    SRC: 'https://lynhdang.agilecrm.com/stats/min/agile-min.js'
};

const agileCRMVars = {
    dev: AGILE_CRM_LOCAL,
    prod: {
        KEY: '8g8ks37vsr7f5kpo0qm9dilmfm',
        DOMAIN: 'localizedirect',
        SRC: 'https://d1gwclp1pmzk26.cloudfront.net/agile/agile-cloud.js',
        WHITE_LIST: 'bG9jYWxpemVkaXJlY3Q='
    }
};

export const API_URL = apiUrls[env][target];
export const SLACK_WEBHOOK_URL =
    slackWebHookUrls[target] || slackWebHookUrls.local;
export const AGILE_CRM_VAR = agileCRMVars[target] || AGILE_CRM_LOCAL;

export const APP_NAME = 'Addlingo';

export const PAGES = {
    LANDING: 'https://localizedirect.com',
    ABOUT: '/about',
    PRICING: '/pricing',
    ORDERS: '/orders',
    ORDER: '/order',
    PROFILE: '/profile',
    TOOL: '/tool',
    BLOG: '/blog',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    SUPPORT: '/support',
    PAYMENT: '/payment',
    DELIVERY: '/delivery',
    CONFIRMATION: '/confirmation',
    OFFERS: '/offers',
    STATEMENT: '/security-statement'
};

export const AUTHED_PAGE_HOME = '/order/upload';

export const THEMES = {
    BLUE: 'blue',
    INDIGO: 'indigo',
    PURPLE: 'purple',
    PINK: 'pink',
    RED: 'red',
    ORANGE: 'orange',
    YELLOW: 'yellow',
    GREEN: 'green',
    TEAL: 'teal',
    CYAN: 'cyan',
    DARK: 'dark',
    LIGHT: 'light'
};

export const PAYMENT_METHOD = {
    CARD: 'card',
    PAYPAL: 'paypal'
};

const PaypalEnvs = {
    production: {
        dev: 'sandbox',
        prod: 'production'
    },
    development: {
        dev: 'sandbox',
        local: 'sandbox'
    }
};

export const CURRENCIES = {
    EUR: { CODE: 'EUR', ICON: '€' },
    USD: { CODE: 'USD', ICON: '$' }
};

export const DEFAULT_CURRENCY = {
    CODE: 'EUR',
    ICON: '€',
    NAME: 'EURO',
    ZERO_DECIMAL: 100
};

export const DEFAULT_CONTACT_EMAIL = 'pm@localizedirect.com';

export const PAYPAL_ENV = PaypalEnvs[env][target];

export const CAN_BYPASS_PAYMENT_EMAILS = ['@localizedirect.com'];
