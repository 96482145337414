import { AGILE_CRM_VAR } from './const';

function onCRMContactCreated(contact) {
    var tags = 'Source Inbound, Registered LocalizeDirect, Prospect';

    window._agile.set_email(contact.email);
    window._agile.add_tag(tags, {
        success: function() {},
        error: function() {}
    });
}

export function createCRMContact(email) {
    if (window._agile && email) {
        const contact = {};
        contact.email = email;

        window._agile.create_contact(contact, {
            success: function(data) {
                onCRMContactCreated(contact);
            },
            error: function(data) {
                if (data.error.search(/duplicate/i) !== -1) {
                    onCRMContactCreated(contact);
                }
            }
        });
    }
}

export function loadCRMScripts() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = '_agile_min_js';
    script.async = true;
    script.src = AGILE_CRM_VAR.SRC;
    document.body.appendChild(script);

    window.Agile_API = window.Agile_API || {};
    window.Agile_API.on_after_load = function() {
        window._agile.set_account(
            AGILE_CRM_VAR.KEY,
            AGILE_CRM_VAR.DOMAIN,
            false
        );

        if (AGILE_CRM_VAR.WHITE_LIST) {
            window._agile_set_whitelist(AGILE_CRM_VAR.WHITE_LIST);
        }
        window._agile.track_page_view();
        //_agile.set_tracking_domain('{{ $.Site.Params.siteDomain }}');
        window._agile_execute_web_rules();
    };
}
