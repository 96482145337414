import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bool, string, func } from 'prop-types';

import { Modal, toJS } from 'components';
import { closeModal, showModal } from './actions';
import { FORM_TYPES } from './constants';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import EditProfileForm from './EditProfileForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';

import './auth.css';

class AuthModal extends React.Component {
    static propTypes = {
        show: bool,
        type: string,
        onClose: func
    };

    onSignUpClick = () => {
        const { onOpen } = this.props;

        onOpen(FORM_TYPES.SIGN_UP);
    };

    onSignInClick = () => {
        const { onOpen } = this.props;

        onOpen(FORM_TYPES.SIGN_IN);
    };

    buildSignInHeader = () => {
        return (
            <div className="action-container">
                <div className="title">Log in</div>
                <div className="sign-in-help">
                    Don’t have an account?{' '}
                    <span
                        className="text-btn clickable"
                        onClick={this.onSignUpClick}
                    >
                        Sign up now
                    </span>
                </div>
            </div>
        );
    };

    buildSignUpHeader = () => {
        return (
            <div className="action-container">
                <div className="title">Sign up</div>
                <div className="sign-up-help">
                    Already have a Localize Direct account?{' '}
                    <span
                        className="text-btn clickable"
                        onClick={this.onSignInClick}
                    >
                        Log in
                    </span>
                </div>
            </div>
        );
    };

    buildEditProfileHeader = () => {
        return (
            <div className="action-container">
                <div className="title">Edit your information</div>
                <div className="sign-up-help" />
            </div>
        );
    };

    buildForgotPasswordHeader = () => {
        return (
            <div className="action-container">
                <div className="title">Request password reset</div>
                <div className="sign-in-help">
                    We'll send you an email to reset your password.
                </div>
            </div>
        );
    };

    buildUpdatePasswordHeader = () => {
        return (
            <div className="action-container">
                <div className="title">Reset your password</div>
                <div className="sign-up-help" />
            </div>
        );
    };

    render() {
        const { show, type, onClose } = this.props;
        let Form = SignInForm;
        let header = <div className="auth-header logo-icon" />;
        let action = this.buildSignInHeader();
        let className = 'sign-in-modal';

        switch (type) {
            case FORM_TYPES.SIGN_UP:
                Form = SignUpForm;
                action = this.buildSignUpHeader();
                className = 'sign-up-modal';

                break;
            case FORM_TYPES.EDIT_PROFILE:
                Form = EditProfileForm;
                action = this.buildEditProfileHeader();
                className = 'edit-profile-modal';

                break;
            case FORM_TYPES.FORGOT_PASSWORD:
                Form = ForgotPasswordForm;
                action = this.buildForgotPasswordHeader();
                className = 'forgot-password-modal';

                break;
            case FORM_TYPES.UPDATE_PASSWORD:
                Form = ChangePasswordForm;
                action = this.buildUpdatePasswordHeader();
                className = 'forgot-password-modal';

                break;
            default: // Login
        }

        return (
            <Modal
                show={show}
                onClose={onClose}
                header={header}
                className={`auth-modal ${className}`}
            >
                {show && <Form header={action} />}
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const authModal = state.getIn(['auth', 'modal']).toJS();

    return {
        show: authModal.open,
        type: authModal.type
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onClose: closeModal,
            onOpen: showModal
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AuthModal));
