export function requestData(type) {
    return {
        type
    };
}

export function receiveData(type, data) {
    return {
        data,
        type
    };
}
