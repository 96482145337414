import React from 'react';
import { bool, func, any } from 'prop-types';
import { TextInput } from './Inputs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as helper from './help';
import { addAnswer } from './actions';

const Comment = ({ author = 'You', content }) => {
    return (
        <div className="comment d-flex flex-row mt-3">
            <div className="avatar-icon">
                <i className="far fa-user-circle" />
            </div>

            <div className="thread-comment">
                <span className="author">{author}</span>
                <span className="comment-content">{content}</span>
            </div>
        </div>
    );
};

const CommentInput = ({ author = 'You', submitting, onSubmit }) => {
    return (
        <div className="comment comment-input d-flex flex-row mt-4">
            <div className="avatar-icon">
                <i className="far fa-user-circle" />
            </div>
            <div className="thread-comment d-flex flex-row w-100 pr-2">
                <span className="author">{author}</span>
                <TextInput
                    onChange={onSubmit}
                    useEnter
                    autofocus
                    rows="2"
                    loading={submitting}
                />
            </div>
        </div>
    );
};

const CommentList = ({ answers, members, authorName }) =>
    Array.isArray(answers)
        ? answers.map(answer => (
              <Comment
                  key={answer.id}
                  author={authorName(members, answer.createdBy)}
                  {...answer}
              />
          ))
        : null;

const Commands = ({ onReplyClick, onResolveClick, isResolved }) => {
    return (
        <div
            className="reaction-commands position-absolute btn-group btn-group-sm"
            role="group"
            aria-label="Basic example"
        >
            <button
                type="button"
                className="btn btn-reply"
                onClick={onReplyClick}
                disabled={isResolved}
            >
                Reply
            </button>
        </div>
    );
};

const ANSWERS_TO_SHOW = 3;

class QuestionThread extends React.PureComponent {
    static propTypes = {
        id: any,
        active: bool,
        thread: any,
        onSelect: func,
        measure: func
    };

    state = {
        submitting: false,
        replying: false,
        showAllComments: false
    };

    render() {
        const { thread, authorName } = this.props;
        const { submitting, replying, showAllComments } = this.state;
        const isResolved = thread.state === 8;
        const { answers } = thread;

        return (
            <div className="question card position-relative">
                <Commands
                    onReplyClick={this.handleOnReplyClick}
                    onResolveClick={this.handleOnResolveClick}
                    isResolved={isResolved}
                />
                <div
                    className={`card-body ${
                        isResolved ? 'question-resolved' : ''
                    }`}
                >
                    <div className="thread-start comment d-flex flex-row">
                        <Comment
                            author={authorName(
                                thread.members,
                                thread.createdBy
                            )}
                            content={thread.content}
                        />
                    </div>
                    <div className="thread-follows container my-2 ml-2">
                        {answers && answers.length > ANSWERS_TO_SHOW && (
                            <span
                                onClick={this.showAllComments}
                                className="show"
                            >
                                {showAllComments
                                    ? 'Show latest comments'
                                    : 'Show all comments'}
                            </span>
                        )}
                        <CommentList
                            answers={
                                showAllComments
                                    ? answers
                                    : thread.answers.slice(-ANSWERS_TO_SHOW)
                            }
                            authorName={authorName}
                            members={thread.members}
                        />
                        {replying && !isResolved && (
                            <CommentInput
                                onSubmit={this.handleSubmitComment}
                                submitting={submitting}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    showAllComments = () => {
        this.setState({
            showAllComments: !this.state.showAllComments
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.active !== this.props.active ||
            prevProps.thread !== this.props.thread
        ) {
            this.props.measure && this.props.measure();
        }
    }

    handleOnReplyClick = () => {
        this.setState({
            replying: true
        });
    };

    handleSubmitComment = content => {
        const { id, addAnswer, selectedSourceText } = this.props;
        if (addAnswer && (!!content && content.trim().length > 0)) {
            this.setState({ submitting: true });
            addAnswer({
                questionId: id,
                content,
                sourceText: selectedSourceText,
                callback: err => {
                    if (err) {
                        this.setState({ submitting: false });
                        Error.captureStackTrace(err);
                        throw err;
                    } else {
                        this.setState({ submitting: false });
                    }
                }
            });
        }
    };

    handleOnResolveClick = () => {
        const { thread, onResolveQuestion } = this.props;
        if (onResolveQuestion) {
            this.setState({ submitting: true });
            onResolveQuestion(thread)
                .then(_ => {
                    this.setState({ submitting: false });
                })
                .catch(err => {
                    this.setState({ submitting: false });
                    Error.captureStackTrace(err);
                    throw err;
                });
        }
    };
}

const mapStateToProps = (state, ownProps) => {
    return {
        authorName: (members, authorId) => {
            const userUid = state.getIn(['auth', 'user', 'userUid']);
            return helper.authorName(members, authorId, userUid);
        }
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            addAnswer
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionThread);
