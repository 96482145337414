import React from 'react';
import { string, array } from 'prop-types';
import QuestionThread from './QuestionThread';

import './SourceDetailsView-style.css';

class SourceDetailsView extends React.PureComponent {
    static propTypes = {
        id: string,
        data: array,
        selectedSourceText: string
    };

    static defaultProps = {
        data: []
    };

    render() {
        const { data, selectedSourceText } = this.props;

        return (
            <div className="source-details-view">
                {data &&
                    data.map(thread => (
                        <QuestionThread
                            key={thread.id}
                            id={thread.id}
                            thread={thread}
                            selectedSourceText={selectedSourceText}
                        />
                    ))}
            </div>
        );
    }
}

export default SourceDetailsView;
