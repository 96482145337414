import React from 'react';
import { Alert } from 'components';
const DEFAULT_TITLE = 'Oops! Something went wrong';
const DEFAULT_MSG = `We encountered an unexpected problem. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`;

const ErrorHandler = ({ error, onClose }) => {
    const title = DEFAULT_TITLE;
    const message = DEFAULT_MSG;

    return (
        <Alert
            type="danger"
            title={title}
            message={message}
            onClose={onClose}
            className="error-message"
        />
    );
};

export default ErrorHandler;
