import React from 'react';
import { array, func, string, object } from 'prop-types';
import classNames from 'classnames';
import MenuItem from './MenuItem';
import TextInput from '../inputs/TextInput';
import onClickOutside from 'react-onclickoutside';

import './InputSelect-style.css';

class InputSelect extends React.Component {
    static propTypes = {
        value: string,
        selectedOption: object,
        options: array,
        onSelectChange: func,
        onInputChange: func
    };

    static defaultProps = {
        value: '',
        selectedOption: {},
        options: []
    };

    state = {
        show: false
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    toggleShow = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    buildMenuItems = (options, selectedOption, filter = '') => {
        const elements = [];
        const { groupDirection } = this.props;

        options.forEach((option, index) => {
            const found =
                !filter ||
                option.label.toLowerCase().includes(filter.toLocaleLowerCase());

            if (found) {
                elements.push(
                    <MenuItem
                        key={`${index}-${option.value}`}
                        option={option}
                        selectedOption={selectedOption}
                        searchName={option.searchName}
                        direction={groupDirection}
                        onClick={this.onItemClick}
                    />
                );
            }
        });

        return elements;
    };

    onItemClick = option => {
        const { onSelectChange } = this.props;
        onSelectChange(option);

        setTimeout(() => {
            this.setState({
                show: false
            });
        }, 100);
    };

    onTextInput = text => {
        const { onInputChange } = this.props;

        this.setState({
            show: true
        });

        onInputChange(text);
    };

    render() {
        const { value, placeholder, selectedOption, options } = this.props;
        const { show } = this.state;

        const menuItems = this.buildMenuItems(options, selectedOption, value);
        const btnClass = classNames('input-select');
        const menuClass = classNames('dropdown-menu', {
            show: show && menuItems.length > 0
        });

        return (
            <div className={btnClass}>
                <div onClick={this.toggleShow}>
                    <TextInput
                        value={value}
                        placeholder={placeholder}
                        onChange={this.onTextInput}
                    />
                </div>
                <div className={menuClass}>{menuItems}</div>
            </div>
        );
    }
}

export default onClickOutside(InputSelect);
