import { loadStripe } from '@stripe/stripe-js';

let stripePromise = null;

export default function getStripe(stripeKey) {
    if (!stripePromise && stripeKey) {
        stripePromise = loadStripe(stripeKey);
    }

    return stripePromise;
}
