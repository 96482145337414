import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import { logOut } from './actions';
import { withRouter } from 'react-router-dom';

const isTokenExpired = token => {
    let expired = true;

    try {
        const decoded = decode(token);
        if (decoded.exp > Date.now() / 1000) {
            expired = false;
        }
    } catch (err) {}

    return expired;
};

const UserSessionCheck = props => {
    const { logOut, children, history } = props;
    const token = Cookies.get('token');

    if (!!token && isTokenExpired(token)) {
        logOut(history);

        return <span />;
    } else {
        return children;
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            logOut
        },
        dispatch
    );
};

export default withRouter(connect(null, mapDispatchToProps)(UserSessionCheck));
