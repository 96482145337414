import React from 'react';
import { DragSource } from 'react-dnd';

const Source = {
    canDrag(props) {
        return true;
    },

    isDragging(props, monitor) {
        return monitor.getItem().id === props.id;
    },

    beginDrag(props, monitor, component) {
        const item = { id: props.id };
        return item;
    },

    endDrag(props, monitor, component) {
        if (!monitor.didDrop()) {
            return;
        }

        props.onDragEnd();
    }
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

class RowSelection extends React.PureComponent {
    render() {
        // Your component receives its own props as usual
        const { selectedRowIndex } = this.props;

        // These props are injected by React DnD,
        // as defined by your `collect` function above:
        const { connectDragSource } = this.props;

        return connectDragSource(
            <div className="start-line">
                Drag to define target area (translation will start from row{' '}
                {selectedRowIndex})
            </div>
        );
    }
}

export default DragSource('row-start-translate', Source, collect)(RowSelection);
