import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, object, string, func } from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import {
    calculateFile,
    onFileSettingInput,
    removeFile,
    downloadFile
} from './actions';

import { Tab, toJS } from 'components';
import SettingTable from './SettingTable';
import { getAdditionalOptions } from './SettingSelect';
import FileDownload from './FileDownload';

import './FileSettingInput-style.css';

const DEFAULT_START_ROW = 1;

class FileSettingInput extends React.Component {
    static propTypes = {
        fileContent: object,
        fileSettings: array,
        fileName: string,
        fileType: string,
        onFileSettingInput: func,
        downloadFile: func
    };
    state = {
        sheets: null,
        sheetStartRows: [],
        sheetIndex: 0
    };

    static getDerivedStateFromProps(props, state) {
        const { allLanguages, fileContent, fileSettings } = props;
        let sheets = [];
        let sheetStartRows = [];

        if (!state.sheets && allLanguages && fileContent) {
            const numberOfSheet = fileContent.numberOfSheet || 1;
            sheets = new Array(numberOfSheet);
            sheetStartRows = new Array(numberOfSheet);

            for (let i = 0; i < numberOfSheet; i++) {
                sheetStartRows[i] = DEFAULT_START_ROW;
                const headerRow = fileContent.sheets[i].headerRow;

                if (fileSettings) {
                    const currentSetting = fileSettings[i];
                    const selectedSource = get(currentSetting, 'sourceColumn');
                    const selectedTargets = get(
                        currentSetting,
                        'targetColumns',
                        []
                    );
                    const selectedAdditionals = get(
                        currentSetting,
                        'additionalColumns',
                        []
                    );
                    const existStartRow = get(
                        selectedSource,
                        'startRow',
                        get(
                            selectedTargets[0],
                            'startRow',
                            get(selectedAdditionals[0], 'startRow')
                        )
                    );

                    if (existStartRow) {
                        sheetStartRows[i] = existStartRow;
                    }

                    sheets[i] = new Array(headerRow.length);
                    for (let j = 0; j < headerRow.length; j++) {
                        sheets[i][j] = {};
                    }

                    if (selectedSource) {
                        sheets[i][selectedSource.col] = {
                            id: 'sourceLanguage',
                            name: selectedSource.name
                        };
                    }

                    selectedTargets.forEach(target => {
                        sheets[i][target.col] = {
                            id: target.lang,
                            name: target.name,
                            group: 'targetLanguages'
                        };
                    });

                    selectedAdditionals.forEach(additional => {
                        sheets[i][additional.col] = {
                            id: additional.type,
                            name: additional.name,
                            group: additional.type
                        };
                    });
                } else {
                    const additionalOptions = getAdditionalOptions();

                    sheets[i] = headerRow.map(col => {
                        // FIXME: refactor the sourceLanguage checking here
                        let item = {};

                        if (col.toLowerCase() === 'english') {
                            item = { id: 'sourceLanguage', name: col };
                        }

                        const langItem = allLanguages.find(lang => {
                            return (
                                lang.name.toLowerCase() === col.toLowerCase()
                            );
                        });

                        if (langItem) {
                            item = {
                                id: langItem.id,
                                name: langItem.name,
                                group: 'targetLanguages'
                            };
                        }

                        const additionalItem = additionalOptions.find(
                            additional => {
                                return (
                                    additional.label.toLowerCase() ===
                                    col.toLowerCase()
                                );
                            }
                        );

                        if (additionalItem) {
                            item = {
                                id: additionalItem.value,
                                name: additionalItem.label,
                                group:
                                    additionalItem.group || additionalItem.value
                            };
                        }

                        return item;
                    });
                }
            }

            return {
                sheets,
                sheetStartRows
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    onDownload = () => {
        const { orderId, fileName, downloadFile } = this.props;

        downloadFile(orderId, fileName);
    };

    onColumnSetting = data => {
        const { fileContent } = this.props;
        const { sheets } = this.state;
        const key = data.group ? data.group : data.value;
        const { value, columnIndex, sheet } = data;
        let sheetUpdate = sheets[sheet];

        if (!sheetUpdate) {
            sheetUpdate = new Array(fileContent.sheets[sheet].headerRow.length);
        }

        switch (key) {
            case 'targetLanguages':
                const existsTargetIndex = sheetUpdate.findIndex(
                    element => element.id === value
                );

                if (existsTargetIndex) {
                    sheetUpdate[existsTargetIndex] = {};
                }

                sheetUpdate[columnIndex] = {
                    id: value,
                    name: data.label,
                    group: key
                };
                break;
            case 'sourceLanguage':
                const existsSourceIndex = sheetUpdate.findIndex(
                    element => element.id === 'sourceLanguage'
                );

                if (existsSourceIndex > -1) {
                    sheetUpdate[existsSourceIndex] = {};
                }

                sheetUpdate[columnIndex] = { id: 'sourceLanguage' };
                break;
            default:
                sheetUpdate[columnIndex] = {
                    id: data.value,
                    name: data.label,
                    group: key
                };
        }

        sheets[data.sheet] = sheetUpdate;

        this.setState({
            sheets
        });
    };

    onRowSetting = (sheet, startRow) => {
        const { sheetStartRows } = this.state;

        sheetStartRows[sheet] = startRow;

        this.setState({
            sheetStartRows
        });
    };

    onContinueClick = () => {
        const { orderId, calculateFile, onFileSettingInput } = this.props;
        const { sheets, sheetStartRows } = this.state;

        onFileSettingInput(sheets);
        calculateFile(orderId, sheets, sheetStartRows);
    };

    isValidSheet = sheet => {
        return !!sheet.find(col => col.id === 'sourceLanguage');
    };

    validateInput = () => {
        const {
            fileContent: { numberOfSheet }
        } = this.props;
        const { sheets } = this.state;

        if (!sheets) {
            return false;
        }

        for (let i = 0; i < numberOfSheet; i++) {
            const sheet = sheets[i] || [];
            if (this.isValidSheet(sheet)) {
                return true;
            }
        }

        return false;
    };

    getTabItems = sheetNames => {
        const { sheets } = this.state;
        const items = [];

        sheetNames.forEach((element, index) => {
            const isValid = sheets && this.isValidSheet(sheets[index]);
            items.push({
                label: element.sheetName,
                icon: isValid && <i className="fas fa-check" />
            });
        });

        return items;
    };

    onTabSelect = index => {
        this.setState({
            sheetIndex: index
        });
    };

    onCopySettingsClick = () => {
        const { sheets } = this.state;
        const newSheets = cloneDeep(sheets);

        for (let i = 1; i < newSheets.length; i++) {
            newSheets[0].forEach((value, index) => {
                newSheets[i][index] = value;
            });
        }

        this.setState({
            sheets: newSheets
        });
    };

    render() {
        const { fileContent, fileName, removeFile } = this.props;
        const { sheets, sheetStartRows, sheetIndex } = this.state;

        if (!fileContent) {
            return <span />;
        }

        const isDone = this.validateInput();

        return (
            <div className="step-page setup-file">
                <div className="page-title">Setup file</div>
                <div className="page-note">
                    {sheets && sheets.length > 1
                        ? 'Select source and target columns for each of the sheets you want to translate'
                        : 'Select the source column you want to translate'}
                </div>

                {sheets && sheets.length > 1 && (
                    <div className="copy-settings">
                        <button
                            type="button"
                            className="btn btn-copy-settings"
                            onClick={this.onCopySettingsClick}
                        >
                            Apply settings
                        </button>
                        <span className="label">
                            from first sheet to all sheets
                        </span>
                    </div>
                )}

                <div className="file-setting-row">
                    <Tab
                        items={this.getTabItems(fileContent.sheets)}
                        onSelect={this.onTabSelect}
                    />
                    <SettingTable
                        sheet={sheetIndex}
                        startRow={sheetStartRows[sheetIndex]}
                        data={fileContent.sheets[sheetIndex]}
                        selectedData={sheets ? sheets[sheetIndex] : []}
                        onColumnSetting={this.onColumnSetting}
                        onRowSetting={this.onRowSetting}
                    />

                    <div className="btn-container">
                        <div className="container-full">
                            <FileDownload
                                fileName={fileName}
                                onDownload={this.onDownload}
                                onRemove={removeFile}
                            />
                            <button
                                type="button"
                                className="btn btn-next"
                                disabled={!isDone}
                                onClick={this.onContinueClick}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        allLanguages: state.getIn(['order', 'languages', 'all'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            onFileSettingInput,
            calculateFile,
            removeFile,
            downloadFile
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(FileSettingInput));
