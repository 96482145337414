import React from 'react';
import { func, string } from 'prop-types';
import { MenuDropdown } from 'components';

import { CURRENCIES } from 'common/const';

import './CurrencySelection-style.css';

class CurrencySelection extends React.PureComponent {
    static propTypes = {
        value: string,
        onSelect: func.isRequired
    };

    state = {
        editable: false
    };

    onSelect = option => {
        const { onSelect } = this.props;

        this.setState({
            editable: false
        });

        onSelect(option.value);
    };

    buildOptions = () => {
        const value = this.props.value || CURRENCIES.EUR.CODE;
        const options = Object.keys(CURRENCIES).map(key => ({
            value: key,
            label: CURRENCIES[key].CODE,
            selected: value === key
        }));

        return options;
    };

    onChangeClick = () => {
        this.setState({
            editable: true
        });
    };

    render() {
        const value = this.props.value || CURRENCIES.EUR.CODE;
        const options = this.buildOptions();
        const selectedOption = options.find(option => option.value === value);

        return (
            <MenuDropdown
                className="currency-selection"
                selectedOption={selectedOption}
                options={options}
                onChange={this.onSelect}
            />
        );
    }
}

export default CurrencySelection;
