import React from 'react';
import ReactTooltip from 'react-tooltip';
import countries from 'i18n-iso-countries'; // for converting country code 2 <-> 3
import { array, func } from 'prop-types';
import isEqual from 'lodash/isEqual';
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography
} from 'react-simple-maps';
import GAME_DATA from './data';

import './WorldMap-style.css';

const wrapperStyles = {
    width: '100%',
    maxWidth: 1500,
    margin: '0 auto',
    fontFamily: 'Roboto, sans-serif'
};

const COLORS = {
    selected: '#3651b3',
    translated: '#36b36b',
    partial: '#E0E0AD',
    highlighted: '#ADE0AD',
    untranslated: '#D0E8EB',
    danger: '#ff9999',
    border: '#607D8B',
    unavailable: '#D5DBDC'
};

function getCountriesByLanguage(languageCode) {
    const countries = {};

    for (let key in GAME_DATA) {
        const country = GAME_DATA[key];
        const lang =
            country.languages &&
            country.languages.find(
                element => element.toLowerCase() === languageCode.toLowerCase()
            );

        if (lang) {
            countries[key] = country;
        }
    }

    return countries;
}

function getSupportedCountries() {
    const supportedCountries = [];

    // Get supported countries in 3 digits code
    for (let countryKey in GAME_DATA) {
        const langs = GAME_DATA[countryKey].languages;

        if (langs && langs.length) {
            supportedCountries.push(
                countries.alpha2ToAlpha3(countryKey.toUpperCase())
            );
        }
    }

    return supportedCountries;
}

function getTranslatedCountries() {
    const translatedCountries = [];

    // Get supported countries in 3 digits code
    for (let countryKey in GAME_DATA) {
        const langs = GAME_DATA[countryKey].languages;

        if (langs && langs.length === 1 && langs.includes('enUS')) {
            translatedCountries.push(
                countries.alpha2ToAlpha3(countryKey.toUpperCase())
            );
        }
    }

    return translatedCountries;
}

class WorldMap extends React.Component {
    static propTypes = {
        supportedLangs: array,
        selectedValue: array, // selected languages
        onSelect: func
    };
    static defaultProps = {
        supportedLangs: [],
        selectedValue: [],
        allLanguages: []
    };

    state = {
        supportedCountries: getSupportedCountries(),
        translatedCountries: getTranslatedCountries(),
        selectedCountries: []
    };

    static getDerivedStateFromProps({ selectedValue }, state) {
        const selectedCountries = [];

        // Get selected countries in 3 digits code
        selectedValue.forEach(element => {
            const countryList = getCountriesByLanguage(element.id);

            for (let selectedKey in countryList) {
                const countryCode = countries.alpha2ToAlpha3(
                    selectedKey.toUpperCase()
                );

                if (!selectedCountries.includes(countryCode)) {
                    selectedCountries.push(countryCode);
                }
            }
        });

        if (
            !isEqual(selectedCountries.sort(), state.selectedCountries.sort())
        ) {
            return {
                selectedCountries
            };
        }

        return null;
    }

    componentDidMount() {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 500);
    }

    handleClick = country => {
        const { onSelect } = this.props;
        const selectedLangs = this.getLanguageObjs(country.id);

        if (selectedLangs.length && onSelect) {
            onSelect(selectedLangs);
        }
    };

    getLanguageObjs = countryCode3Digits => {
        const { supportedLangs } = this.props;
        const selectedCountryCode = countries.alpha3ToAlpha2(
            countryCode3Digits.toUpperCase()
        );
        const selectedLangCodes = GAME_DATA[selectedCountryCode]
            ? GAME_DATA[selectedCountryCode].languages || []
            : [];
        const langObjs = [];

        selectedLangCodes.forEach(element => {
            const found = supportedLangs.find(
                lang => lang.id.toLowerCase() === element.toLowerCase()
            );

            if (found) {
                langObjs.push({
                    id: found.id,
                    name: found.name
                });
            }
        });

        return langObjs;
    };

    getLanguageNames = langObjs => {
        const names = [];

        langObjs.forEach(lang => {
            names.push(lang.name);
        });

        return names.join(' + ');
    };

    render() {
        const {
            selectedCountries,
            supportedCountries,
            translatedCountries
        } = this.state;

        return (
            <div className="world-map">
                <div style={wrapperStyles}>
                    <ComposableMap
                        projectionConfig={{
                            scale: 310,
                            rotation: [-11, 0, 0]
                        }}
                        width={1400}
                        height={900}
                        style={{
                            width: '100%',
                            height: 'auto'
                        }}
                    >
                        <ZoomableGroup center={[0, 20]}>
                            <Geographies
                                geography={'world-50m.json'}
                                disableOptimization
                            >
                                {(geographies, projection) =>
                                    geographies.map((geography, i) => {
                                        let defaultColor = COLORS.unavailable;
                                        let hoverColor = COLORS.unavailable;
                                        let unsupported = true;
                                        const langs = this.getLanguageObjs(
                                            geography.id
                                        );
                                        const gameData =
                                            GAME_DATA[
                                                countries.alpha3ToAlpha2(
                                                    geography.id
                                                )
                                            ];

                                        if (
                                            selectedCountries.includes(
                                                geography.id
                                            )
                                        ) {
                                            defaultColor = COLORS.selected;
                                            hoverColor = COLORS.highlighted;
                                            unsupported = false;
                                        } else if (
                                            translatedCountries.includes(
                                                geography.id
                                            )
                                        ) {
                                            defaultColor = COLORS.translated;
                                            hoverColor = COLORS.translated;
                                            unsupported = false;
                                        } else if (
                                            supportedCountries.includes(
                                                geography.id
                                            )
                                        ) {
                                            defaultColor = COLORS.untranslated;
                                            hoverColor = COLORS.highlighted;
                                            unsupported = false;
                                        }

                                        const tooltipString = unsupported
                                            ? undefined
                                            : gameData
                                            ? `<div className="map-notation">
													${geography.properties.name}${
                                                  langs.length
                                                      ? ' - ' +
                                                        this.getLanguageNames(
                                                            langs
                                                        )
                                                      : ''
                                              }<br />${gameData.population.toLocaleString(
                                                  'en'
                                              )}M potential gamers<br />$${gameData.revenue.toLocaleString(
                                                  'en'
                                              )}M market</div>`
                                            : `${geography.properties.name}${
                                                  langs.length
                                                      ? ' - ' +
                                                        this.getLanguageNames(
                                                            langs
                                                        )
                                                      : ''
                                              }`;

                                        return (
                                            geography.id !== 'ATA' && (
                                                <Geography
                                                    key={i}
                                                    data-tip={tooltipString}
                                                    data-type="info"
                                                    data-html={true}
                                                    data-class={'map-notation'}
                                                    geography={geography}
                                                    projection={projection}
                                                    onClick={this.handleClick}
                                                    style={{
                                                        default: {
                                                            fill: defaultColor,
                                                            stroke:
                                                                COLORS.border,
                                                            strokeWidth: 1,
                                                            outline: 'none'
                                                        },
                                                        hover: {
                                                            fill: hoverColor,
                                                            stroke:
                                                                COLORS.border,
                                                            strokeWidth: 1,
                                                            outline: 'none'
                                                        },
                                                        pressed: {
                                                            fill: hoverColor,
                                                            stroke:
                                                                COLORS.border,
                                                            strokeWidth: 1,
                                                            outline: 'none'
                                                        }
                                                    }}
                                                />
                                            )
                                        );
                                    })
                                }
                            </Geographies>
                        </ZoomableGroup>
                    </ComposableMap>
                    <ReactTooltip />
                </div>
                <div className="notes row">
                    <div className="col-md-4">
                        <div className="note-item">
                            <span className="tm-icon" />
                            <span className="text">Partially translated</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="note-item">
                            <span className="rect done" />
                            <span className="text">Translation done</span>
                        </div>
                        <div className="note-item">
                            <span className="rect unsupport" />
                            <span className="text">Unsupported</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="note-item">
                            <span className="rect selection" />
                            <span className="text">Your selection</span>
                        </div>
                        <div className="note-item">
                            <span className="rect not" />
                            <span className="text">Not translated</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorldMap;
