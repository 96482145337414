import React from 'react';
import classNames from 'classnames';
import { func, string, bool } from 'prop-types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { Spinner } from './Progress';

export class TextInput extends React.PureComponent {
    static propTypes = {
        value: string,
        name: string,
        placeholder: string,
        className: string,
        useEnter: bool,
        useDebounce: bool,
        autofocus: bool,
        clearable: bool,
        onChange: func
    };
    static defaultProps = {
        value: '',
        useEnter: false
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            value: this.props.value
        };

        if (props.useDebounce) {
            this.onChange = debounce(this.debounceChange, 1000);
        } else {
            this.onChange = this.props.onChange;
        }
    }

    onInput = event => {
        const { useEnter } = this.props;

        this.setState({
            value: event.target.value
        });

        if (!useEnter && this.onChange) {
            this.onChange(event.target.value);
        }
    };

    debounceChange = value => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    };

    onKeyDown = e => {
        const { loading, useEnter } = this.props;
        const { value } = this.state;

        if (loading) {
            e.preventDefault();
            return false;
        }

        if (e.keyCode === 13 && !e.shiftKey) {
            if (useEnter && this.onChange) {
                this.onChange(value);
            }

            e.preventDefault();
        }
    };

    onClear = () => {
        this.onChange('');

        this.setState({
            value: ''
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loading && !this.props.loading) {
            this.setState({
                value: ''
            });
        }
    }

    render() {
        const {
            loading,
            className,
            placeholder,
            name,
            rows,
            autofocus,
            clearable
        } = this.props;
        const { value } = this.state;
        const componentClass = classNames('text-input', className, {
            loading: loading
        });

        return (
            <div className={componentClass}>
                {clearable && !isEmpty(value) && (
                    <i
                        className="fas fa-times clickable clear-icon"
                        onClick={this.onClear}
                    />
                )}
                {!rows && (
                    <input
                        type="text"
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        onChange={this.onInput}
                        onKeyDown={this.onKeyDown}
                        autoFocus={autofocus}
                    />
                )}

                {rows && (
                    <textarea
                        style={{ width: '100%' }}
                        rows={rows}
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        onChange={this.onInput}
                        onKeyDown={this.onKeyDown}
                        autoFocus={autofocus}
                    />
                )}
                {loading && <Spinner />}
            </div>
        );
    }
}
