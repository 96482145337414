import React from 'react';
import { object } from 'prop-types';
import get from 'lodash/get';

import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

class PaymentSummary extends React.PureComponent {
    static propTypes = {
        quotation: object
    };

    render() {
        const { quotation } = this.props;
        const currency = CURRENCIES[quotation.currency] || DEFAULT_CURRENCY;
        const discounts = get(quotation, ['discounts']);

        return (
            <div className="summary payment-summary row">
                <div className="col-md-6" />
                <div className="col-md-6">
                    <div className="cost">
                        <span className="label">Translation cost</span>
                        <span className="value bold-2">
                            {`${currency.ICON} ${quotation.sumCost}`}
                        </span>
                    </div>

                    <div className="fee">
                        <span className="label">Management fee</span>
                        <span className="value">
                            {`${currency.ICON} ${quotation.totalFeeAmount}`}
                        </span>
                    </div>

                    {discounts && (
                        <div className="discount">
                            <span className="label">Discount</span>
                            <span className="value">{`- ${
                                currency.ICON
                            } ${quotation.totalDiscountAmount * -1}`}</span>
                        </div>
                    )}
                    {quotation.minimumAdjustmentFee && (
                        <div className="min-adjustment-fee">
                            <span className="label">Min. adjustment fee</span>
                            <span className="value">{`${currency.ICON} ${quotation.minimumAdjustmentFee}`}</span>
                        </div>
                    )}
                    <hr className="line-1" />
                    <div className="sub-total-payed">
                        <span className="label">Total excl. TAX</span>
                        <span className="value bold-1">{`${currency.ICON} ${quotation.netCost}`}</span>
                    </div>
                    <div className="sub-total-payed">
                        <span className="label">
                            Credit card processing fee
                        </span>
                        <span className="value bold-1">{`${
                            currency.ICON
                        } ${quotation.totalTransactionFee || 0}`}</span>
                    </div>
                    <div className="vat">
                        <span className="label">VAT</span>
                        <span className="value">{`${
                            currency.ICON
                        } ${get(quotation, ['tax', 'amount'])}`}</span>
                    </div>
                    <hr className="line-2" />
                    <div className="total-payed">
                        <span className="label bold-1">Total to pay</span>
                        <span className="value bold">{`${currency.ICON} ${quotation.totalCost}`}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentSummary;
