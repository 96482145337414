import Cookies from 'js-cookie';
import { API_URL } from 'common/const';

const DEFAULT_OPTIONS = {
    method: 'POST',
    headers: {}
};
const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

export function request({
    path = '/api/v1/db',
    params = {},
    options = {},
    host = API_URL,
    serviceURI = '',
    useDefaultHeader = true
} = {}) {
    const reqOptions = {
        ...DEFAULT_OPTIONS,
        ...options
    };
    const token = Cookies.get('token');
    reqOptions.headers = {
        ...(useDefaultHeader ? DEFAULT_HEADERS : {}),
        ...reqOptions.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {})
    };
    const queryString = queryParams(params);

    const requestURL = `${host}${serviceURI}${path}${
        queryString.length > 0 ? `?${queryString}` : ''
    }`;
    return fetch(requestURL, reqOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 403) {
        //unauthorized
        return window.location.replace('/logout');
    }

    if (response.status === 204) {
        return 204;
    }

    const contentType = response.headers.get('content-type');
    if (contentType.includes('application/json')) {
        return handleJSONResponse(response);
    } else if (
        contentType.includes('text/html') ||
        contentType.includes('text/plain')
    ) {
        return handleTextResponse(response);
    } else if (contentType.includes('application/octet-stream')) {
        return handleFileResponse(response);
    } else {
        throw new Error(`Content type ${contentType} not supported !`);
    }
}

function handleJSONResponse(response) {
    const requestId = response.headers
        ? response.headers.get('x-request-id')
        : 'unknown';

    return response.json().then(json => {
        if (response.ok) {
            return json;
        } else {
            if (
                json.code === 'SECURE_SERVICE_FORBIDDEN' ||
                json.code === 'FORBIDDEN' ||
                json.code === 'AUTHX_FAILED' ||
                json.code === 'UNAUTHORIZED' ||
                json.message === 'ERROR_UNAUTHORIZED'
            ) {
                return window.location.replace('/logout');
            }

            const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
                requestId
            });
            return Promise.reject(error);
        }
    });
}

function handleTextResponse(response) {
    const requestId = response.headers
        ? response.headers.get('x-request-id')
        : 'unknown';

    return response.text().then(text => {
        if (response.ok) {
            return text;
        } else {
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
                err: text,
                requestId
            });
        }
    });
}

function handleFileResponse(response) {
    return response.blob();
}

function queryParams(params) {
    return Object.entries(params)
        .reduce((qs, [k, v]) => {
            if (Array.isArray(v)) {
                v.forEach(p => {
                    qs.push(
                        `${encodeURIComponent(k)}=${encodeURIComponent(p)}`
                    );
                });
            } else {
                qs.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }

            return qs;
        }, [])
        .join('&');
}
