import { request } from './request';

export function getOrderList({ offset = 0, limit = 50 }) {
    return request({
        path: encodeURI(
            `/purchase/customer/orders?p.offset=${offset}&p.limit=${limit}`
        ),
        options: {
            method: 'GET'
        }
    });
}

export function getQuestionList(id) {
    return request({
        path: encodeURI(`/qanda/order/${id}/question`),
        options: {
            method: 'GET'
        }
    });
}

export function postAnswer(questionId, content) {
    return request({
        path: `/qanda/question/${questionId}/answer`,
        options: {
            method: 'POST',
            body: JSON.stringify({ content, state: 0, tags: [] })
        }
    });
}

export function getMergedFile(orderId, fileName) {
    return request({
        path: `/purchase/order/resource/${orderId}/merged/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}

export function getFile(orderId, fileName) {
    return request({
        path: `/purchase/order/resource/${orderId}/${fileName}`,
        options: {
            method: 'GET'
        }
    });
}
