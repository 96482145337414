export const toIntCurrencyString = num => {
    if (isNaN(num) || num === '') {
        return '';
    }

    try {
        //const n = parseFloat(num).toFixed(2);
        return Number(num).toLocaleString('en');
    } catch {
        return num;
    }
};

export const toFloatCurrencyString = num => {
    if (isNaN(num) || num === '') {
        return '';
    }

    try {
        const n = parseFloat(num).toFixed(2);
        return Number(n).toLocaleString('en', {
            minimumFractionDigits: 2
        });
    } catch {
        return num;
    }
};
