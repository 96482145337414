import React from 'react';
import { connect } from 'react-redux';

import { WorldMap } from 'map';

const LangSelectionMapView = props => {
    return (
        <div className="lang-selection-map-view">
            <WorldMap {...props} />
        </div>
    );
};

export default connect(null, null)(LangSelectionMapView);
