import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { logOut, showModal } from './actions';
import { FORM_TYPES } from './constants';

class SignOutPage extends React.Component {
    componentDidMount() {
        const { logOut, showModal, history } = this.props;

        logOut(history);
        showModal(FORM_TYPES.SIGN_IN);
    }

    render() {
        return <div />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            logOut,
            showModal
        },
        dispatch
    );
};

export default withRouter(connect(null, mapDispatchToProps)(SignOutPage));
