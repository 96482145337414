import React from 'react';
import { Field } from './Field';

export const TextFieldInput = ({
    type,
    id,
    label,
    error,
    value = '',
    onChange,
    className = '',
    showFeedbackError = true,
    onPaste,
    ...props
}) => {
    const handleOnPaste = event => {
        event.preventDefault();
    };

    return (
        <Field
            id={id}
            label={label}
            error={error}
            type={type}
            value={value}
            onChange={onChange}
            className={className}
            showFeedbackError={showFeedbackError}
            {...props}
            render={({ id, type, value, onChange, rows, ...props }) => {
                return !!rows ? (
                    <textarea
                        id={id}
                        className="form-input"
                        value={value}
                        onChange={onChange}
                        rows={rows}
                        {...props}
                    />
                ) : (
                    <input
                        id={id}
                        className="form-input"
                        type={type}
                        value={value}
                        onChange={onChange}
                        autoComplete="off"
                        {...props}
                        onPaste={onPaste === 'off' ? handleOnPaste : undefined}
                    />
                );
            }}
        />
    );
};
