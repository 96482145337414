export const MESSAGES = {
    UNEXPECTED_ERROR: 'Oops! Something went wrong!',
    ACCOUNT_NOT_FOUND: 'Login failed! User name or Password invalid.', //"Email or password doesn't match",
    ACCOUNT_INACTIVE: 'Account not active',
    EMAIL_EXISTS: 'Email already exists',
    SERVER_ERROR: 'Server error! Please contact system admin',
    TOKEN_NOT_FOUND: 'Token not found',
    EMAIL_NOT_FOUND: 'The email you entered does not belong to any account',
    FORGOT_PW_EMAIL_SENT: `We sent an email to $0, which contains a link to reset your password`,
    FORGOT_PW_PASSWORD_CHANGED: `Your password has been changed`,
    PASS_CONFIRM_PASS_NOT_MATCH: 'Your passwords do not match'
};

export function getMessage({ token, httpCode }, replacements = []) {
    let message = MESSAGES[token];
    if (message === undefined) {
        if (httpCode === 500) {
            message = MESSAGES.SERVER_ERROR;
        } else {
            message = MESSAGES.UNEXPECTED_ERROR;
        }
    }
    replacements.forEach((replacement, idx) => {
        message = message.replace(`$${idx}`, replacement);
    });
    return message;
}
