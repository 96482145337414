import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

//import { dateTimeToString, DATE_TIME_FORMAT } from 'common/timeUtils';

import { TextInput, Spinner, toJS } from 'components';
import { PAGES, DEFAULT_CONTACT_EMAIL } from 'common/const';
import { queryParamsToObject } from 'common/urlQueryUtils';

import { fetchOrder, confirmOrder, closeMessage } from './actions';
import ConfirmationResult from './ConfirmationResult';
import LangSummaryTable from './LangSummaryTable';
import PaymentInfo from './PaymentInfo';
import PaymentSummary from './PaymentSummary';
import ErrorHandler from './ErrorHandler';

import './OrderConfirmationPage-style.css';

class OrderConfirmationPage extends React.PureComponent {
    static defaultProps = {
        payment: {},
        order: {},
        user: {}
    };

    state = {
        customerComments: ''
    };

    isInputDone = () => {
        const { customerComments } = this.state;

        return !!customerComments;
    };

    componentDidMount() {
        const { loading, fetchOrder, location } = this.props;
        const params = queryParamsToObject(location.search);

        if (!loading && params.iid) {
            fetchOrder(params.iid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, fetchOrder, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const prevQueryParams = queryParamsToObject(prevProps.location.search);

        if (
            !loading &&
            currentQueryParams.iid &&
            currentQueryParams.iid !== prevQueryParams.iid
        ) {
            fetchOrder(currentQueryParams.iid);
        }
    }

    handleCustomerCommentsChange = value => {
        this.setState({ customerComments: value });
    };

    onAcceptClick = () => {
        const { loading, confirmOrder, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const { customerComments } = this.state;

        if (!loading && currentQueryParams.iid && customerComments) {
            confirmOrder({
                orderId: currentQueryParams.iid,
                state: 'accept',
                comments: customerComments
            });
        }
    };

    onRejectClick = () => {
        const { loading, confirmOrder, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const { customerComments } = this.state;

        if (!loading && currentQueryParams.iid && customerComments) {
            confirmOrder({
                orderId: currentQueryParams.iid,
                state: 'reject',
                comments: customerComments
            });
        }
    };

    onCommentsInput = comments => {
        this.setState({
            customerComments: comments
        });
    };

    render() {
        const {
            loading,
            actionState,
            user,
            error,
            order,
            project = {},
            closeMessage
        } = this.props;
        const errorAlert = (
            <ErrorHandler error={error} onClose={closeMessage} />
        );

        if (actionState === 'accept' || actionState === 'reject') {
            return (
                <ConfirmationResult
                    state={actionState === 'accept' ? 'accepted' : 'rejected'}
                />
            );
        }

        const { customerComments } = this.state;

        return (
            <div className="page-content order-offer-page">
                {loading && <Spinner />}
                {error && errorAlert}
                <div className="order-main">
                    <section className="step-wrapper">
                        <div className="header">
                            <a href={PAGES.LANDING} className="navbar-brand">
                                <img
                                    className="logo"
                                    title="Home"
                                    alt="logo"
                                    src="/img/logo_ld_large.png"
                                />
                            </a>
                        </div>
                        <div className="step-page checkout-and-pay container">
                            <div className="page-title">Service offer</div>
                            {project.quoteExpirationDate && (
                                <span className="alert-warning-title alert-warning-color">
                                    (This offer is valid til: &nbsp;&nbsp;
                                    {get(project, 'quoteExpirationDate', '')})
                                </span>
                            )}

                            <div className="summary-container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="order-info">
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Order number
                                                </div>
                                                <div className="col-5 value">
                                                    {get(order, 'id', '')}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 label">
                                                    Order date
                                                </div>
                                                <div className="col-5 value">
                                                    {get(
                                                        order,
                                                        'orderDate',
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <LangSummaryTable
                                            data={order ? order.projects : []}
                                        />
                                        <PaymentSummary order={order} />

                                        {project.notes && (
                                            <div className="summary order-notes">
                                                <hr className="line-1" />
                                                <p className="label">Notes</p>
                                                <pre className="note-text">
                                                    {project.notes}
                                                </pre>
                                            </div>
                                        )}
                                    </div>

                                    <div className="summary-info-col col-lg-5">
                                        <PaymentInfo user={user} />

                                        {project.state !== 'ACCEPTED' &&
                                            project.state !== 'REJECTED' && (
                                                <div className="summary payment-info">
                                                    <div className="title">
                                                        Your comments{' '}
                                                        <span className="required-text">
                                                            {' '}
                                                            (Required)
                                                        </span>
                                                    </div>
                                                    <div className="content-container">
                                                        <div className="user-profile">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="value">
                                                                        <TextInput
                                                                            placeholder="Please type a text incase you want to Accept, or please help tell us why you want to Reject this offer..."
                                                                            rows={
                                                                                5
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .onCommentsInput
                                                                            }
                                                                            value={
                                                                                customerComments
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className="container-full">
                                    {project.state === 'SENT' && (
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-reject"
                                                onClick={this.onRejectClick}
                                                disabled={!this.isInputDone()}
                                            >
                                                Reject
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-accept"
                                                onClick={this.onAcceptClick}
                                                disabled={!this.isInputDone()}
                                            >
                                                Accept
                                            </button>
                                        </div>
                                    )}

                                    {project.state === 'ACCEPTED' && (
                                        <div className="text-success">
                                            <i className="fas fa-check" />{' '}
                                            Accepted
                                        </div>
                                    )}
                                    {project.state === 'REJECTED' && (
                                        <div className="text-fail">
                                            <i className="fas fa-ban" />{' '}
                                            Rejected
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="footer container">
                            <div className="contact-info">
                                For any questions regarding your order, please
                                contact: &nbsp;
                                <span className="bold">
                                    {get(
                                        project,
                                        'ldContactPerson',
                                        DEFAULT_CONTACT_EMAIL
                                    )}
                                </span>
                            </div>
                            <div>
                                Localize Direct AB, Henckels Torg 4, SE-252 36,
                                Helsingborg, Sweden
                            </div>
                            <div>+46 42 181962 | VAT No: SE556783457601</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['tracker', 'loading']),
        error: state.getIn(['tracker', 'error']),
        actionState: state.getIn(['tracker', 'actionState']),
        order: state.getIn(['tracker', 'order']),
        user: state.getIn(['tracker', 'user']),
        project: state.getIn(['tracker', 'project'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchOrder,
            confirmOrder,
            closeMessage
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(OrderConfirmationPage));
