import React from 'react';
import classNames from 'classnames';
import { func, string, bool } from 'prop-types';

import './TextInput-style.css';

class TextInput extends React.Component {
    static propTypes = {
        value: string,
        name: string,
        placeholder: string,
        className: string,
        inputClass: string,
        autofocus: bool,
        disabled: bool,
        onChange: func
    };

    onInput = event => {
        const { onChange } = this.props;

        onChange(event.target.value);
    };

    componentDidMount() {
        const { autofocus } = this.props;

        if (autofocus) {
            this.input.focus();
        }
    }

    render() {
        const {
            value = '',
            className,
            inputClass = '',
            placeholder,
            name,
            rows,
            autofocus,
            disabled
        } = this.props;
        const componentClass = classNames('text-input', className);

        return (
            <div className={componentClass}>
                {!rows && (
                    <input
                        ref={input => {
                            this.input = input;
                        }}
                        disabled={disabled}
                        type="text"
                        className={inputClass}
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        onChange={this.onInput}
                        onKeyDown={this.onKeyDown}
                        autoFocus={autofocus}
                    />
                )}

                {rows && (
                    <textarea
                        ref={input => {
                            this.input = input;
                        }}
                        className={inputClass}
                        rows={rows}
                        value={value}
                        placeholder={placeholder}
                        name={name}
                        disabled={disabled}
                        onChange={this.onInput}
                        onKeyDown={this.onKeyDown}
                        autoFocus={autofocus}
                    />
                )}
            </div>
        );
    }
}

export default TextInput;
