import React from 'react';
import { func, string, bool } from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from 'components';

class CheckboxItem extends React.Component {
    static propTypes = {
        value: string,
        label: string,
        checked: bool,
        disabled: bool,
        className: string,
        onChange: func
    };

    onItemClick = () => {
        const { value, label, onChange } = this.props;

        onChange({ value, label });
    };

    render() {
        const { className, value, label, checked, disabled } = this.props;
        const itemClass = classNames('checkbox-item', className, {
            disabled: disabled
        });

        return (
            <div className={itemClass}>
                <Checkbox
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onChange={this.onItemClick}
                />
                {!!label && <label onClick={this.onItemClick}>{label}</label>}
            </div>
        );
    }
}

export default CheckboxItem;
