export const FILE_ERROR_TYPES = {
    EXCEEDED_LIMIT: 'EXCEEDED_LIMIT',
    NON_SUPPORTED_FILE_TYPE: 'NON_SUPPORTED_FILE_TYPE',
    UPLOAD_FAILED: 'UPLOAD_FAILED'
};

export const FILE_UPLOADING_ERROR = {
    CHAT_MSGS: [
        `Heyo, our online service currently only support a limited set of files. You can learn more here:\n
        https://localizedirect.com/posts/ld-format`,
        `If you still want to get your current file translated, just ping us, we are happy to help!`
    ],
    FILE_NAME_CONTAIN_SPECIAL_CHAR_NOT_ALLOW: `Your file name contains unsupported characters. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`,
    ERROR_MSG: `Your file could not be processed automatically. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`
};

export const ORDERING_STATES = {
    INIT: 'INIT',
    FILE_UPLOAD: 'FILE_UPLOAD',
    FILE_SETTING: 'FILES_SETTING',
    LANG_SETTING: 'LANG_SETTING',
    ORDER_INFO: 'ORDER_INFO',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAYMENT_LOCK: 'PAYMENT_LOCK',
    PAID: 'PAID',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED'
};

export const ORDER_STEPS = {
    STEP_1: 'Step 1 - Upload file',
    STEP_2: 'Step 2 - Setup file',
    STEP_3: 'Step 3 - Select languages',
    STEP_4: 'Step 4 - Add information',
    STEP_5: 'Step 5 - Complete & Pay',
    STEP_6: 'Step 5 - Change currency',
    STEP_END: 'Step END - Paid'
};

export const MIN_PAYMENT_AMOUNT = 100;
export const MAX_UPLOAD_FILE_SIZE = 8; // in Mb
