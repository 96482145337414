import React from 'react';

import { DEFAULT_CURRENCY } from 'common/const';
import TMIcon from './TMIcon';

import './LangSummaryTable-style.css';

const LangSummaryTable = ({ data, currency = DEFAULT_CURRENCY }) => {
    const buildLangItems = langDataRows => {
        const items = [];

        langDataRows.forEach((data, index) => {
            items.push(
                <li
                    key={`lang-sum-item-${index}`}
                    className="lang-summary-item"
                >
                    <div className="cell no">{index + 1}</div>
                    <div className="cell name">{data.name}</div>
                    <div className="cell count">
                        <div className="icon">{data.hasTM && <TMIcon />}</div>
                        {data.wordCount}
                    </div>
                    <div className="cell cost-per-word">{data.costPerWord}</div>
                    <div className="cell total-cost">{data.totalCost}</div>
                </li>
            );
        });

        return items;
    };

    return (
        <div className="lang-summary-table">
            <div className="header">
                <div className="cell no">&nbsp;</div>
                <div className="cell name">Language</div>
                <div className="cell count">Source word count</div>
                <div className="cell cost-per-word">
                    {`Cost per word (${currency.ICON})`}
                </div>
                <div className="cell total-cost">
                    {`Total (${currency.ICON})`}
                </div>
            </div>
            <ul className="body">{buildLangItems(data)}</ul>
        </div>
    );
};

export default LangSummaryTable;
