import React from 'react';
import { array, func, string } from 'prop-types';
import { SearchInput, Tab } from 'components';
import LangSelectItem from './LangSelectItem';
import CurrencySelection from './CurrencySelection';
import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

const TAB_ITEMS = [
    { value: 'all', label: 'All', icon: <div className="active-line" /> },
    {
        value: 'recommended',
        label: 'Recommended',
        icon: <div className="active-line" />
    }
];

const TAB_ITEM_DEFAULT = 'all';

const getDisplaySelectingItems = (search, filter, allLanguages) => {
    const items = allLanguages.filter(element => {
        const isSearched = !(
            search &&
            search.length > 0 &&
            element.name.toLowerCase().search(search.toLowerCase()) === -1
        );

        const useFilter = !filter || filter === 'recommended';

        return isSearched && (useFilter ? element.recommended : true);
    });

    return items;
};

class LangSelectionListView extends React.PureComponent {
    static propTypes = {
        selectedValue: array,
        allLanguages: array,
        selectedCurrency: string,
        onSelect: func,
        onCurrencySelect: func
    };
    static defaultProps = {
        selectedValue: [],
        allLanguages: []
    };

    state = {
        selectingItems: getDisplaySelectingItems(
            '',
            TAB_ITEM_DEFAULT,
            this.props.allLanguages
        ),
        selectedTab: TAB_ITEM_DEFAULT
    };

    isSelectedAll = () => {
        const { selectingItems } = this.state;
        const { selectedValue } = this.props;

        for (let i = 0; i < selectingItems.length; i++) {
            const found = selectedValue.find(
                option => option.id === selectingItems[i].id
            );

            if (!found) {
                return false;
            }
        }

        return true;
    };

    onLangItemSelect = lang => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect([{ id: lang.value, name: lang.label }]);
        }
    };

    onLangAllSelect = () => {
        const { onSelect, selectedValue } = this.props;
        const { selectingItems } = this.state;

        if (onSelect) {
            if (this.isSelectedAll()) {
                onSelect(selectingItems);
            } else {
                const unSelectedIds = selectingItems.filter(element => {
                    const found = selectedValue.find(
                        option => option.id === element.id
                    );

                    return !found;
                });

                onSelect(unSelectedIds);
            }
        }
    };

    onSearchInput = text => {
        const { selectedTab } = this.state;
        const { allLanguages } = this.props;

        this.setState({
            search: text,
            selectingItems: getDisplaySelectingItems(
                text,
                selectedTab,
                allLanguages
            )
        });
    };

    onTabSelect = value => {
        const { search } = this.state;
        const { allLanguages } = this.props;

        this.setState({
            selectedTab: value,
            selectingItems: getDisplaySelectingItems(
                search,
                value,
                allLanguages
            )
        });
    };

    buildSelectingItems = () => {
        const { selectedValue, allLanguages, selectedCurrency } = this.props;
        const { selectedTab, search } = this.state;
        const selectingItems = getDisplaySelectingItems(
            search,
            selectedTab,
            allLanguages
        );
        const items = [];

        selectingItems.forEach(element => {
            const checked = selectedValue.find(
                option => option.id === element.id
            );
            const price =
                selectedCurrency === CURRENCIES.USD.CODE
                    ? element.priceUSD || element.price
                    : element.price;

            items.push(
                <LangSelectItem
                    key={`selecting-lang-${element.id}`}
                    value={element.id}
                    label={element.name}
                    pricePerWord={price}
                    pricePerLang={element.cost}
                    hasTM={element.hasTM}
                    checked={!!checked}
                    onSelect={this.onLangItemSelect}
                />
            );
        });

        return items;
    };

    render() {
        const {
            selectedValue,
            allLanguages,
            selectedCurrency,
            onCurrencySelect
        } = this.props;
        const currency = CURRENCIES[selectedCurrency] || DEFAULT_CURRENCY;

        return (
            <div className="lang-selection-list-view">
                <SearchInput
                    placeholder="Search a language"
                    onChange={this.onSearchInput}
                />
                <Tab items={TAB_ITEMS} onSelect={this.onTabSelect} />
                <div className="select-all">
                    <span className="counter">{`Selected ${selectedValue.length}/${allLanguages.length}`}</span>
                    <span className="currency-select">
                        <CurrencySelection
                            value={selectedCurrency}
                            onSelect={onCurrencySelect}
                        />
                    </span>
                    <LangSelectItem
                        key="selecting-lang-all"
                        label="Select all"
                        value="all"
                        checked={this.isSelectedAll()}
                        onSelect={this.onLangAllSelect}
                    />
                </div>
                <div className="area">
                    <div className="header">
                        <div className="name">Languages</div>
                        <div className="price-per-word">{`Price per word (${currency.ICON})`}</div>
                        <div className="price-per-lang">
                            {`Price per language (${currency.ICON})`}
                        </div>
                        <div className="check" />
                    </div>
                    <ul className="selection-area">
                        {this.buildSelectingItems()}
                    </ul>
                </div>
            </div>
        );
    }
}

export default LangSelectionListView;
