import React from 'react';
import { any, array, func, string } from 'prop-types';
import ReactSelect from 'react-select';

import './Select-style.css';

class Select extends React.Component {
    static propTypes = {
        value: any,
        options: array,
        placeholder: string,
        className: string,
        onChange: func
    };

    render() {
        const {
            className,
            value,
            options,
            placeholder,
            onChange,
            ...rest
        } = this.props;

        const selectedOptions = Array.isArray(value)
            ? options.filter(option => {
                  return (
                      option.value === value ||
                      (value && value.includes && value.includes(option.value))
                  );
              })
            : options.find(option => option.value === value);

        return (
            <ReactSelect
                {...rest}
                value={selectedOptions}
                onChange={data => {
                    onChange(data);
                }}
                options={options}
                placeholder={placeholder}
                className={`select ${className || ''}`}
            />
        );
    }
}

export default Select;
