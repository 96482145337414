import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';
import get from 'lodash/get';

import { toJS } from 'components';
import { getCountryName } from '../common/countryData';
import { fetchUserCompanyInfo, showModal } from './actions';
import { FORM_TYPES } from './constants';

import './UserProfile-style.css';

class UserProfile extends React.Component {
    static propTypes = {
        user: object,
        company: object
    };

    componentDidMount() {
        const { user, company, fetchUserCompanyInfo } = this.props;

        if (!company) {
            fetchUserCompanyInfo(user);
        }
    }

    onEditClick = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.EDIT_PROFILE);
    };

    render() {
        const { user, company = {} } = this.props;
        const countryCode = get(company, ['address', 'country'], '');

        return (
            <div className="user-profile">
                <div className="row">
                    <div className="col-md-6">
                        <div className="label">First name</div>
                        <div className="value">{user.firstName}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="label">Last name</div>
                        <div className="value">{user.lastName}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="label">Email</div>
                        <div className="value">{user.email}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="label">Country/ Region</div>
                        <div className="value">
                            {getCountryName(countryCode)}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="label">Company name</div>
                        <div className="value">
                            {get(company, ['companyName'], '')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="label">Address</div>
                        <div className="value">
                            {get(company, ['address', 'street'], '')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="label">City</div>
                        <div className="value">
                            {get(company, ['address', 'city'], '')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="label">VAT number</div>
                        <div className="value">
                            {get(company, ['vatNumber'], '')}
                        </div>
                    </div>
                </div>

                <div className="submit-btns">
                    <span
                        className="text-btn clickable"
                        onClick={this.onEditClick}
                    >
                        Edit your payment info
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        company: state.getIn(['auth', 'company'])
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserCompanyInfo,
            showModal
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(UserProfile));
