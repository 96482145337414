import React from 'react';
import classNames from 'classnames';
import { func, string, bool } from 'prop-types';

import './Checkbox-style.css';

class Checkbox extends React.Component {
    static propTypes = {
        value: string,
        checked: bool,
        disabled: bool,
        className: string,
        onChange: func
    };
    state = {
        value: this.props.value
    };

    onChange = () => {
        const { value, onChange } = this.props;

        onChange(value);
    };

    render() {
        const { className, checked, disabled } = this.props;
        const componentClass = classNames('checkbox', className, {
            checked: checked,
            disabled: disabled
        });

        return (
            <span className={componentClass} onClick={this.onChange}>
                {checked && <i className="fas fa-check" />}
            </span>
        );
    }
}

export default Checkbox;
