import React from 'react';
import { func, object, string } from 'prop-types';
import canBypassPayment from '../common/canBypassPayment';
import StripePayProvider from '../components/payment/stripe/PayProvider';
import RequestPayProvider from '../components/payment/stripe/PaymentRequestProvider';
//import PaypalPayProvider from '../components/payment/paypal/PayProvider';
import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

const PAY_METHODS = {
    CARD: 'card',
    SAVED_CARD: 'saved_card',
    PAYPAL: 'paypal',
    BYPASS: 'bypass'
};

class PaymentMethod extends React.PureComponent {
    static propTypes = {
        invoice: object.isRequired,
        selectedMethod: string,
        user: object.isRequired,
        payment: object.isRequired,
        currency: object,
        executePayment: func.isRequired,
        setupPayment: func.isRequired,
        lockPayment: func.isRequired,
        onSelect: func
    };
    state = {
        canMakeRequestPayment: false
    };

    changeMethod = e => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(e.target.value);
        }
    };

    onRequestPayProviderReady = isSupport => {
        this.setState({
            canMakeRequestPayment: isSupport
        });
    };

    onAddOrderClick = () => {
        const { executePayment } = this.props;

        executePayment({}, PAY_METHODS.BYPASS);
    };

    render() {
        const { user, invoice, selectedMethod } = this.props;
        const { canMakeRequestPayment } = this.state;
        const currency = CURRENCIES[invoice.currency] || DEFAULT_CURRENCY;

        return (
            <div className="summary payment-method">
                <div className="title">Payment method</div>
                <div className="content-container">
                    <div>
                        <input
                            id="credit-card-item"
                            type="radio"
                            name="payment-method"
                            value={PAY_METHODS.CARD}
                            checked={selectedMethod === PAY_METHODS.CARD}
                            onChange={this.changeMethod}
                        />
                        <label htmlFor="credit-card-item" className="selection">
                            Credit card
                        </label>
                        <span className="items">
                            <img
                                title="Visa card"
                                alt="Visa card"
                                src="/img/logo_visa.png"
                            />
                            <img
                                title="Master card"
                                alt="Master card"
                                src="/img/logo_mastercard.png"
                            />
                            <img
                                title="American Express card"
                                alt="American Express card"
                                src="/img/logo_amercan_express.png"
                            />
                        </span>
                        {selectedMethod === PAY_METHODS.CARD && (
                            <StripePayProvider
                                {...this.props}
                                currency={currency}
                            />
                        )}
                    </div>
                    {/*
                    <div>
                        <input
                            id="paypal-item"
                            type="radio"
                            name="payment-method"
                            value={PAY_METHODS.PAYPAL}
                            checked={selectedMethod === PAY_METHODS.PAYPAL}
                            onChange={this.changeMethod}
                        />
                        <label htmlFor="paypal-item" className="selection">
                            Paypal
                        </label>
                        <span className="items">
                            <img
                                title="Paypal card"
                                alt="Paypal card"
                                src="/img/logo_paypal.png"
                            />
                        </span>
                        
                            //Issue: user clicking on the pay button while the paypal scripts still on loading.
                            //Quick solution: load the Paypal when page loading so that have enough time for scripts to load, 
                            //then will show it when Paypal selected.
                         }
                        <PaypalPayProvider
                            {...this.props}
                            currency={currency}
                            show={selectedMethod === PAY_METHODS.PAYPAL}
                        />
                    </div>
                    */}
                    {canMakeRequestPayment && (
                        <div>
                            <input
                                id="saved-card-item"
                                type="radio"
                                name="payment-method"
                                value={PAY_METHODS.SAVED_CARD}
                                checked={
                                    selectedMethod === PAY_METHODS.SAVED_CARD
                                }
                                onChange={this.changeMethod}
                            />
                            <label
                                htmlFor="saved-card-item"
                                className="selection"
                            >
                                Saved card
                            </label>
                            <span className="items">
                                <img
                                    className="card-icon"
                                    title="Visa card"
                                    alt="Visa card"
                                    src="/img/apple-pay-icon.jpg"
                                />
                                <img
                                    className="card-icon"
                                    title="Master card"
                                    alt="Master card"
                                    src="/img/google-pay-icon.png"
                                />
                                <img
                                    className="card-icon"
                                    title="American Express card"
                                    alt="American Express card"
                                    src="/img/window-pay-icon.png"
                                />
                            </span>
                        </div>
                    )}
                    <RequestPayProvider
                        {...this.props}
                        currency={currency}
                        show={selectedMethod === PAY_METHODS.SAVED_CARD}
                        onReady={this.onRequestPayProviderReady}
                    />
                    {canBypassPayment(user) && (
                        <div>
                            <input
                                id="bypass-payment-item"
                                type="radio"
                                name="payment-method"
                                value={PAY_METHODS.BYPASS}
                                checked={selectedMethod === PAY_METHODS.BYPASS}
                                onChange={this.changeMethod}
                            />
                            <label
                                htmlFor="bypass-payment-item"
                                className="selection"
                            >
                                Bypass payment
                            </label>
                            {selectedMethod === PAY_METHODS.BYPASS && (
                                <div className="checkout">
                                    <div
                                        className="btn btn-next"
                                        onClick={this.onAddOrderClick}
                                    >
                                        <span className="invisible">{`Pay ${currency.ICON} ${invoice.totalCost}`}</span>
                                        <span className="visible-text">
                                            Add Order
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PaymentMethod;
