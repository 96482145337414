import React from 'react';
import classNames from 'classnames';

import './FileDownload-style.css';

const FileDownload = ({ className, fileName, onDownload, onRemove }) => {
    const componentClass = classNames('file-info', className);

    return (
        <div className={componentClass}>
            <div className="file-download clickable" onClick={onDownload}>
                <span className="file-name">{fileName}</span>
            </div>
            {!!onRemove && <div className="line" />}
            {!!onRemove && (
                <div className="file-delete">
                    <i
                        className="fas fa-trash clickable icon-delete"
                        onClick={onRemove}
                    />
                </div>
            )}
        </div>
    );
};

export default FileDownload;
