import get from 'lodash/get';
import { requestData, receiveData } from '../api/actions';
import * as paymentApi from 'services/payment';

import { PAYMENT } from './actionConstants';

export const lockPayment = isLock => {
    return {
        type: PAYMENT.LOCK_PAYMENT,
        isLock
    };
};

export function closeMessage() {
    return {
        type: PAYMENT.CLOSE_MESSAGE
    };
}

export function fetchInvoice(invoiceId) {
    return (dispatch, getState) => {
        dispatch(requestData(PAYMENT.EXECUTING));

        paymentApi
            .getInvoice(invoiceId)
            .then(payload => {
                const invoicePayload = get(payload, ['data'], {});
                const config = get(payload, ['cfg'], {});

                const invoice = invoicePayload.invoice;
                const user = invoicePayload.user;
                const invoiceNumber = get(payload, 'invoiceNumber');

                dispatch(
                    receiveData(PAYMENT.ON_INVOICE_FETCHED, {
                        config,
                        invoice,
                        invoiceNumber,
                        user
                    })
                );
            })
            .catch(e => {
                dispatch(receiveData(PAYMENT.ON_FAILED, e));
            });
    };
}

export const setupPayment = () => (dispatch, getState) => {
    const invoiceId = getState().getIn(['payment', 'invoiceId']);

    dispatch(requestData(PAYMENT.EXECUTING));

    return paymentApi
        .setupInvoicePayment({ invoiceId })
        .then(payload =>
            dispatch(receiveData(PAYMENT.ON_AUTHORIZE_PAYMENT, payload.data))
        )
        .catch(e => {
            dispatch(receiveData(PAYMENT.ON_FAILED, e));
        });
};

export const executePayment = (token, method, params) => (
    dispatch,
    getState
) => {
    const invoiceId = getState().getIn(['payment', 'invoiceId']);

    return paymentApi
        .executeInvoicePayment({
            invoiceId,
            token,
            method,
            params
        })
        .then(payload => {
            if (payload.requires_action) {
                dispatch(
                    receiveData(PAYMENT.ON_PAYMENT_INTENT_CREATED, payload)
                );
            } else {
                dispatch(
                    receiveData(PAYMENT.ON_EXECUTED_PAYMENT, payload.data)
                );
            }
        })
        .catch(e => {
            dispatch(receiveData(PAYMENT.ON_FAILED, e));
        });
};

export const startNewOrder = () => {
    window.location = '/';
};
