import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';

import { ORDER, COMMON, PAYMENT } from './actionConstants';
import { LOG_OUT } from '../auth/actionConstants';

const INITIAL_STATE = fromJS({
    csrfToken: null,
    id: null,
    loading: false,
    calculating: false,
    error: null,
    fetched: false,
    currentStep: 1,
    supportedFileTypes: [],
    supportedFileSize: 0,
    fileUploading: {
        data: null,
        rawFile: null,
        assets: [],
        error: null
    },
    quotation: {
        data: {}
    },
    fileSettings: {
        targets: null
    },
    info: {
        data: null
    },
    checkout: {
        data: null
    },
    langSelection: {},
    languages: {
        loading: false,
        all: null
    },
    payment: {
        isLock: false
    },
    paymentMethod: null,
    state: null
});

const handlers = {
    [ORDER.START_NEW_ORDER]() {
        return INITIAL_STATE;
    },
    [LOG_OUT]() {
        return INITIAL_STATE;
    },
    [ORDER.ON_NEXT](state, { payload }) {
        const currentStep = state.get('currentStep');

        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], payload || currentStep + 1);
        });
    },
    [ORDER.ON_BACK](state) {
        const currentStep = state.get('currentStep');

        if (currentStep > 0) {
            return state.withMutations(mutableState => {
                mutableState.setIn(['currentStep'], currentStep - 1);
            });
        } else {
            return state;
        }
    },
    [ORDER.REFRESH_STEPS](state) {
        return state.setIn(['currentStep'], 1);
    },
    [ORDER.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['fileUploading', 'error'], null);
        });
    },
    [ORDER.ON_UPLOAD_FILE_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['calculating'], false);
            if (data.code === 'INVOICE_ALREADY_PAID') {
                mutableState.setIn(['error'], data);
            } else {
                mutableState.setIn(['fileUploading', 'error'], fromJS(data));
            }
        });
    },
    [ORDER.ON_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['calculating'], false);
        });
    },
    [ORDER.INIT_NEW_ORDER](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['id'], null);
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], true);
        });
    },
    [ORDER.ON_NEW_ORDER_INITED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 1);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['fetched'], true);
            mutableState.setIn(['id'], data.id);
            mutableState.setIn(['csrfToken'], data.csrfToken);
            mutableState.setIn(['supportedFileTypes'], data.supportedFileTypes);
            mutableState.setIn(['supportedFileSize'], data.supportedFileSize);
            mutableState.setIn(['payment', 'stripeKey'], data.stripeKey);
            mutableState.setIn(['paymentMethod'], data.paymentMethod);
            mutableState.setIn(['state'], data.state);
        });
    },
    [ORDER.FETCH_ORDER](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], true);
        });
    },
    [ORDER.ON_ORDER_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['fetched'], true);
            mutableState.setIn(['id'], data.id);
            mutableState.setIn(['supportedFileTypes'], data.supportedFileTypes);
            mutableState.setIn(['supportedFileSize'], data.supportedFileSize);
            mutableState.setIn(['fileUploading', 'data'], fromJS(data.file));
            mutableState.setIn(
                ['fileUploading', 'assets'],
                fromJS(data.assets)
            );
            mutableState.setIn(['quotation', 'data'], fromJS(data.quotation));
            mutableState.setIn(
                ['fileSettings', 'targets'],
                fromJS(data.targets)
            );
            mutableState.setIn(['info', 'data'], fromJS(data.info));
            mutableState.setIn(['checkout', 'data'], fromJS(data.checkout));
            mutableState.setIn(['payment', 'stripeKey'], data.stripeKey);
            mutableState.setIn(['paymentMethod'], fromJS(data.paymentMethod));
            mutableState.setIn(['state'], data.state);
        });
    },
    [COMMON.FETCH_LANGUAGES](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['languages', 'loading'], true);
        });
    },
    [COMMON.ON_LANGUAGES_FETCHED](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['languages', 'loading'], false);
            mutableState.setIn(['languages', 'all'], fromJS(action.data));
        });
    },
    [ORDER.REMOVE_FILE](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 1);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['quotation', 'data'], null);
            mutableState.setIn(['fileSettings', 'targets'], null);
            mutableState.setIn(['info', 'data'], null);
            mutableState.setIn(['checkout', 'data'], null);
        });
    },
    [ORDER.UPLOAD_FILE](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_FILE_UPLOADED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 2);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['fileUploading', 'data'], fromJS(data.file));
            mutableState.setIn(['state'], data.state);
        });
    },
    [ORDER.ON_ASSET_FILE_UPLOADED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(
                ['fileUploading', 'assets'],
                fromJS(data.assets)
            );
        });
    },
    [ORDER.ON_ASSET_FILE_REMOVED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(
                ['fileUploading', 'assets'],
                fromJS(data.assets)
            );
        });
    },
    [ORDER.ON_FILE_SETTING_CONFIRMED](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 2);
        });
    },
    [ORDER.GET_FILE_CONTENT](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_FILE_CONTENT_GOT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['fileUploading', 'rawFile'], fromJS(data));
        });
    },
    [ORDER.CALCULATE_FILE](state, action) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_FILE_CALCULATED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 3);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['fileUploading', 'data'], fromJS(data.file));
            mutableState.setIn(['quotation', 'data'], fromJS(data.quotation));
            mutableState.setIn(
                ['fileSettings', 'targets'],
                fromJS(data.targets)
            );
            mutableState.setIn(['checkout', 'data'], null);
        });
    },
    [ORDER.CALCULATE_PRICE](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_PRICE_CALCULATED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['quotation', 'data'], fromJS(data.quotation));
        });
    },
    [ORDER.ON_ORDER_INFORMATION_SAVED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['currentStep'], 5);
            mutableState.setIn(['info', 'data'], fromJS(data.info));
            mutableState.setIn(['state'], data.state);
        });
    },
    [ORDER.CHECKOUT_ORDER](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], true);
        });
    },
    [ORDER.ON_ORDER_CHECKOUT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['currentStep'], 4);
            mutableState.setIn(['quotation', 'data'], fromJS(data.quotation));
            mutableState.setIn(
                ['fileSettings', 'targets'],
                fromJS(data.targets)
            );
            mutableState.setIn(['checkout', 'data'], fromJS(data.checkout));
            mutableState.setIn(['state'], data.state);
        });
    },
    [ORDER.ON_ORDER_CURRENCY_CHANGED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['calculating'], false);
            mutableState.setIn(['quotation', 'data'], fromJS(data.quotation));
        });
    },
    [PAYMENT.LOCK_PAYMENT](state, { isLock }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['payment', 'isLock'], isLock);
            mutableState.setIn(['calculating'], !!isLock);
        });
    },
    [PAYMENT.ON_AUTHORIZE_PAYMENT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['paymentMethod'], fromJS(data.paymentMethod));
            mutableState.setIn(['payment', 'tok'], data.paymentMethod.tok);
            mutableState.setIn(['state'], data.state);
        });
    },
    [PAYMENT.ON_PAYMENT_INTENT_CREATED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(
                ['payment', 'payment_intent_client_secret'],
                fromJS(data.payment_intent_client_secret)
            );
        });
    },
    [PAYMENT.ON_EXECUTED_PAYMENT](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['currentStep'], 6);
            mutableState.setIn(['paymentMethod'], fromJS(data.paymentMethod));
            mutableState.setIn(['state'], data.state);
            mutableState.setIn(['calculating'], false);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
