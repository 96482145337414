import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import { onStepBack } from './actions';
import { MIN_PAYMENT_AMOUNT } from './constants';
import { Message } from 'components';
import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

const LangSelectionTotal = ({
    selectedOptions,
    wordCount,
    quotation,
    onLanguageSelect,
    onCheckout
}) => {
    const onCheckoutClick = () => {
        onCheckout();
    };

    const onLangItemSelect = (langId, langName) => {
        if (onLanguageSelect) {
            onLanguageSelect([{ id: langId, name: langName }]);
        }
    };

    const buttons = [];
    const currency = CURRENCIES[get(quotation, 'currency')] || DEFAULT_CURRENCY;

    selectedOptions.forEach(element => {
        buttons.push(
            <Message
                key={`alert-lang-${element.id}`}
                type="primary"
                message={element.name}
                value={element.id}
                onClose={onLangItemSelect}
            />
        );
    });

    return (
        <div className="total-section row container-full">
            <div className="section count col-md-2">
                <div className="bold-text">Source word count</div>
                <div className="value">{wordCount}</div>
            </div>
            <div className="section langs col-md-7">
                <div>
                    Translate from <span className="bold-text">English</span> to{' '}
                    <span className="bold-text">
                        {selectedOptions.length} language(s)
                    </span>
                </div>
                <div className="selected-langs">{buttons}</div>
            </div>
            <div className="total col-md-3">
                <div className="sum">
                    <span className="title bold-text">Total </span>
                    <span className="value">
                        {get(currency, 'ICON')} {get(quotation, 'sumCost', 0)}
                    </span>
                </div>
                <div className="note">VAT not included</div>
                <div className="note">{`Minimum order amount is ${get(
                    currency,
                    'ICON'
                )} ${MIN_PAYMENT_AMOUNT}`}</div>
            </div>
            <button
                type="button"
                className="btn btn-next"
                disabled={!(quotation && quotation.sumCost)}
                onClick={onCheckoutClick}
            >
                Next
            </button>
        </div>
    );
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ onStepBack }, dispatch);
};

export default connect(null, mapDispatchToProps)(LangSelectionTotal);
