import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import decode from 'jwt-decode';
import { showModal, clearUserSession } from './actions';
import { FORM_TYPES } from './constants';

const isLoggedIn = () => {
    const token = Cookies.get('token');
    return !!token && !isTokenExpired(token);
};

const isTokenExpired = token => {
    let expired = true;

    try {
        const decoded = decode(token);
        if (decoded.exp > Date.now() / 1000) {
            expired = false;
        }
    } catch (err) {}

    return expired;
};

const AuthenticationCheck = props => {
    const { showModal, type, children } = props;

    if (isLoggedIn()) {
        return children;
    } else {
        clearUserSession();
        showModal(type || FORM_TYPES.SIGN_IN);

        return <span />;
    }
};

const mapStateToProps = (state, ownProps) => {
    const authModal = state.getIn(['auth', 'modal']).toJS();

    return {
        show: authModal.open,
        type: authModal.type
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            showModal
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(
    AuthenticationCheck
);
