import React from 'react';
import { notifyToSlack } from 'common/gtmScripts';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });

        notifyToSlack({
            userEmail: 'User',
            stepName: 'User interface',
            status: 'error',
            errorCode: `${error}`
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="container-full text-center">
                    <h1>Something went wrong.</h1>
                    <p>
                        We encountered an unexpected problem. Please contact our
                        Customer Support at{' '}
                        <a
                            href="mailto:sales@localizedirect.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            sales@localizedirect.com
                        </a>{' '}
                        if you have any enquiries.
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}
