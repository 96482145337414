import { request } from './request';

const multipart = files => {
    let data = new FormData();

    files.reduce((data, file) => {
        data.append('files', file, `${file.name}`);
        return data;
    }, data);

    return data;
};

export function initOrder() {
    return request({
        path: '/purchase/order/init',
        options: {
            method: 'GET'
        }
    });
}

export function getOrder(id, csrf_token) {
    return request({
        path: `/purchase/order/init/${id}`,
        options: {
            method: 'GET',
            headers: {
                csrf_token
            }
        }
    });
}

export function fetchLanguages() {
    return request({
        path: '/purchase/price',
        options: {
            method: 'GET'
        }
    });
}

export function postFile(orderId, csrf_token, files) {
    return request({
        path: `/purchase/order/resource/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: multipart(files)
        },
        useDefaultHeader: false
    });
}

export function postAssetFile(orderId, csrf_token, { name, file }) {
    const data = new FormData();

    data.append('files', file, name);

    return request({
        path: `/purchase/order/asset/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: data
        },
        useDefaultHeader: false
    });
}

export function postFileSettings(orderId, csrf_token, settings) {
    return request({
        path: `/purchase/order/file-setting/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify(settings)
        }
    });
}

export function postInstanceQuote(orderId, csrf_token, settings) {
    const { langs, currency } = settings;
    return request({
        path: `/purchase/order/v2/instance-quote/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify({ targets: langs, currency })
        }
    });
}

export function postLanguageSettings(
    orderId,
    csrf_token,
    { source, targets, currency }
) {
    return request({
        path: `/purchase/order/language-setting/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify({
                source,
                targets,
                currency
            })
        }
    });
}

export function postCurrencySettings(orderId, csrf_token, { currency }) {
    return request({
        path: `/purchase/order/currency-setting/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify({
                currency
            })
        }
    });
}

export function postOrderInformation(orderId, csrf_token, info) {
    return request({
        path: `/purchase/order/info/${orderId}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify(info)
        }
    });
}

export function getFile(orderId, csrf_token, fileName) {
    return request({
        path: `/purchase/order/resource/${orderId}/${fileName}`,
        options: {
            method: 'GET',
            headers: {
                csrf_token
            }
        }
    });
}

export function getAssetFile(orderId, csrf_token, fileName, referOrderId) {
    return request({
        path: `/purchase/order/asset/${orderId}/${fileName}${
            referOrderId ? `?refer=${referOrderId}` : ''
        }`,
        options: {
            method: 'GET',
            headers: {
                csrf_token
            }
        }
    });
}

export function deleteAssetFile(orderId, csrf_token, fileName) {
    return request({
        path: `/purchase/order/asset/${orderId}/${fileName}`,
        options: {
            method: 'DELETE',
            headers: {
                csrf_token
            }
        }
    });
}

export function getQuoteFile(orderId, csrf_token) {
    return request({
        path: `/purchase/order/pay/quote/${orderId}/download`,
        options: {
            method: 'GET',
            headers: {
                csrf_token
            }
        }
    });
}
