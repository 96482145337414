import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { PAYMENT_METHOD } from 'common/const';

const ErrorMessage = error => {
    return (
        <div className="alert alert-danger" role="alert">
            {error.message || ''}
        </div>
    );
};

class CheckoutForm extends Component {
    static propTypes = {
        invoice: object.isRequired,
        user: object.isRequired,
        payment: object.isRequired,
        currency: object,
        executePayment: func.isRequired,
        lockPayment: func.isRequired
    };

    state = {
        error: null
    };

    submit = async ev => {
        const {
            stripe,
            elements,
            user: { firstName, lastName },
            executePayment,
            lockPayment
        } = this.props;

        /*const tok = await stripe.createToken({
            name: `${firstName} ${lastName}`,
            email
        });
        const { error, token } = tok;*/

        lockPayment(true);

        const cardElement = elements.getElement(CardElement);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { name: `${firstName} ${lastName}` }
        });

        if (result.error) {
            lockPayment(false);
            this.setState({ error: result.error });
            throw result.error;
        } else {
            this.setState({ error: null });
            executePayment({}, PAYMENT_METHOD.CARD, {
                payment_method_id: result.paymentMethod.id
            });
        }
    };

    async componentDidUpdate(prevProps, prevState) {
        const {
            payment_intent_client_secret,
            stripe,
            executePayment,
            lockPayment
        } = this.props;

        if (
            payment_intent_client_secret !==
            prevProps.payment_intent_client_secret
        ) {
            const result = await stripe.handleCardAction(
                payment_intent_client_secret
            );

            if (result.error) {
                lockPayment(false);
                this.setState({ error: result.error });
                throw result.error;
            } else {
                executePayment({}, PAYMENT_METHOD.CARD, {
                    payment_intent_id: result.paymentIntent.id
                });
            }
        }
    }

    render() {
        const { invoice, currency } = this.props;

        return (
            <div className="checkout">
                <CardElement />
                <button className="btn" onClick={this.submit}>{`Pay ${
                    currency ? currency.ICON : invoice.currency || '€'
                } ${invoice.totalCost}`}</button>
                {this.state.error && ErrorMessage(this.state.error)}
                {/*payment.isLock && <Spinner />*/}
            </div>
        );
    }
}

const InjectedCheckoutForm = props => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => {
                return stripe ? (
                    <CheckoutForm
                        elements={elements}
                        stripe={stripe}
                        {...props}
                    />
                ) : (
                    <span />
                );
            }}
        </ElementsConsumer>
    );
};

export default InjectedCheckoutForm;
