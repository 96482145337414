import React from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SignInForm, UserProfile } from 'auth';
import { showModal } from '../auth/actions';

class PaymentInfo extends React.PureComponent {
    static propTypes = {
        user: object
    };

    onSignUpClick = () => {
        const { showModal } = this.props;

        showModal('signup');
    };

    render() {
        const { user } = this.props;

        return (
            <div className="summary payment-info">
                <div className="title">
                    {user ? 'Payment information' : 'Please login to pay'}
                </div>
                {!user && (
                    <div className="content-container">
                        <SignInForm />
                        <span className="sign-up-help">
                            Don’t have an account?{' '}
                            <span
                                className="btn-text clickable"
                                onClick={this.onSignUpClick}
                            >
                                Sign up
                            </span>
                        </span>
                    </div>
                )}
                {user && (
                    <div className="content-container">
                        <UserProfile user={user} />
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            showModal
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(PaymentInfo);
