export const COMMON = {
    FETCH_LANGUAGES: 'FETCH_LANGUAGES',
    ON_LANGUAGES_FETCHED: 'ON_LANGUAGES_FETCHED'
};

export const ORDER = {
    START_NEW_ORDER: 'START_NEW_ORDER',
    CALCULATE_FILE: 'CALCULATE_FILE',
    CALCULATE_PRICE: 'CALCULATE_PRICE',
    CHECKOUT_ORDER: 'CHECKOUT_ORDER',
    CLOSE_MESSAGE: 'CLOSE_MESSAGE',
    FETCH_ORDER: 'FETCH_ORDER',
    FILL_ORDER_INFORMATION: 'FILL_ORDER_INFORMATION',
    INIT_NEW_ORDER: 'INIT_NEW_ORDER',
    UPLOAD_FILE: 'UPLOAD_FILE',
    DOWNLOAD_FILE: 'DOWNLOAD_FILE',
    GET_FILE_CONTENT: 'GET_FILE_CONTENT',
    REMOVE_FILE: 'REMOVE_FILE',
    ON_BACK: 'ORDER_STEP_ON_BACK',
    ON_NEXT: 'ORDER_STEP_ON_NEXT',
    ON_FILE_CALCULATED: 'ON_FILE_CALCULATED',
    ON_FILE_UPLOADED: 'ON_FILE_UPLOADED',
    ON_FILE_DOWNLOADED: 'ON_FILE_DOWNLOADED',
    ON_ASSET_FILE_UPLOADED: 'ON_ASSET_FILE_UPLOADED',
    ON_ASSET_FILE_REMOVED: 'ON_ASSET_FILE_REMOVED',
    ON_FILE_CONTENT_GOT: 'ON_FILE_CONTENT_GOT',
    ON_FILE_SETTING_CONFIRMED: 'ON_FILE_SETTING_CONFIRMED',
    ON_ORDER_INFORMATION_SAVED: 'ON_ORDER_INFORMATION_SAVED',
    ON_NEW_ORDER_INITED: 'ON_NEW_ORDER_INITED',
    ON_ORDER_CHECKOUT: 'ON_ORDER_CHECKOUT',
    ON_ORDER_CURRENCY_CHANGED: 'ON_ORDER_CURRENCY_CHANGED',
    ON_ORDER_FETCHED: 'ON_ORDER_FETCHED',
    ON_PRICE_CALCULATED: 'ON_PRICE_CALCULATED',
    ON_UPLOAD_FILE_FAILED: 'ON_UPLOAD_FILE_FAILED',
    ON_FAILED: 'ON_FAILED',
    REFRESH_STEPS: 'REFRESH_STEPS'
};

export const PAYMENT = {
    LOCK_PAYMENT: 'PROCESS_PAYMENT',
    ON_AUTHORIZE_PAYMENT: 'ON_AUTHORIZE_PAYMENT',
    ON_EXECUTED_PAYMENT: 'ON_EXECUTED_PAYMENT',
    ON_PAYMENT_INTENT_CREATED: 'ON_PAYMENT_INTENT_CREATED'
};
