import React from 'react';
import './Spinner-style.css';

export default function Spinner(props) {
    return (
        <div className="spinner-wrapper">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
        </div>
    );
}
