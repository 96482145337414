import React from 'react';
import { object, func, string } from 'prop-types';
import classNames from 'classnames';

import { Tab } from 'components';
import {
    checkAllQuestionResolved,
    checkAtleast1QuestionHaveNoAnswers
} from './help';
const TAB_ITEMS = [
    { value: 'all', label: 'All', icon: <div className="active-line" /> },
    {
        value: 'unresolved',
        label: 'Unresolved',
        icon: <div className="active-line" />
    }
];

const TAB_ITEM_DEFAULT = 'all';

const QSelectItem = ({
    value,
    label,
    isSelected,
    onSelect,
    isNotResolvedAll
}) => {
    const onItemClick = () => {
        if (onSelect) {
            onSelect({ value, label });
        }
    };

    const itemClass = classNames('question-select-item clickable', {
        select: isSelected
    });

    return (
        <li className={itemClass} onClick={onItemClick}>
            {isNotResolvedAll && <span className="indicator"></span>}
            <p className="card-subtitle small">
                {label !== 'undefined' ? 'Source Text' : 'General'}
            </p>
            <p className="card-title">{label !== 'undefined' ? label : ''}</p>
        </li>
    );
};

class QSelectionListView extends React.PureComponent {
    static propTypes = {
        selectedSourceText: string,
        sources: object,
        onSelect: func,
        onTapSwitched: func
    };
    static defaultProps = {
        questions: []
    };

    state = {
        selectedTab: TAB_ITEM_DEFAULT
    };

    onItemSelect = item => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(item);
        }
    };

    onTabSelect = value => {
        const { onTapSwitched } = this.props;
        const { selectedTab } = this.state;
        if (value !== selectedTab) {
            this.setState(
                {
                    selectedTab: value
                },
                () => {
                    onTapSwitched(value);
                }
            );
        }
    };

    buildSelectingItems = () => {
        const { sources, selectedSourceText } = this.props;
        const { selectedTab } = this.state;
        const items = [];

        Object.keys(sources).forEach(key => {
            const questions = sources[key];
            let isNotResolvedAll = !checkAllQuestionResolved(questions);
            let isAtleast1QuestionHaveNoAnswer = checkAtleast1QuestionHaveNoAnswers(
                questions
            );
            if (selectedTab === 'all') {
                items.push(
                    <QSelectItem
                        key={`q-selecting-${key}`}
                        isSelected={selectedSourceText + '' === key + ''}
                        label={key}
                        onSelect={() => this.onItemSelect({ key, questions })}
                        isNotResolvedAll={isAtleast1QuestionHaveNoAnswer}
                    />
                );
            }
            if (selectedTab === 'unresolved') {
                isNotResolvedAll &&
                    items.push(
                        <QSelectItem
                            key={`q-selecting-${key}`}
                            isSelected={selectedSourceText + '' === key + ''}
                            label={key}
                            isNotResolvedAll={isAtleast1QuestionHaveNoAnswer}
                            onSelect={() =>
                                this.onItemSelect({ key, questions })
                            }
                        />
                    );
            }
        });

        return items;
    };

    render() {
        return (
            <div className="source-selection-list-view">
                <Tab items={TAB_ITEMS} onSelect={this.onTabSelect} />
                <ul className="selection-area">{this.buildSelectingItems()}</ul>
            </div>
        );
    }
}

export default QSelectionListView;
