import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Yup from 'yup';
import Cookies from 'js-cookie';

import { TextFieldInput, Message } from 'components';
import { changePassword } from 'services/auth';
import { getMessage } from 'common/getMessage';
import { notifyToSlack } from 'common/gtmScripts';
import { onPasswordChangedSuccess } from './actions';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'Password length must be at least 8'),
        confirmPassword: Yup.string()
            .required('Confirm new password is required')
            .min(8, 'Password length must be at least 8')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
    }),
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        const token = Cookies.get('resetToken');

        setStatus({ error: null });
        changePassword({
            token,
            ...payload
        })
            .then(data => {
                Cookies.remove('resetToken');
                props.onPasswordChangedSuccess(data);
                setSubmitting(false);
            })
            .catch(e => {
                setStatus({
                    error: getMessage({
                        token: e.message,
                        httpCode: e.code || e.status
                    })
                });
                setSubmitting(false);

                notifyToSlack({
                    userEmail: payload.email,
                    status: 'failed',
                    stepName: 'Change password',
                    errorCode: e.message
                });
            });
    },
    displayName: 'ChangePasswordForm'
});

export const ChangePasswordForm = props => {
    const {
        values,
        touched,
        errors,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        header
    } = props;

    const message = status &&
        status.error && <Message type="danger" message={status.error} />;
    const redirect =
        status && status.redirectTo ? (
            <Redirect to={status.redirectTo} />
        ) : null;

    return (
        <div className="change-password-form">
            {message}
            {redirect}
            {!!header && header}
            <form onSubmit={handleSubmit}>
                <TextFieldInput
                    id="newPassword"
                    type="password"
                    label="New password"
                    className="input-line"
                    placeholder=""
                    error={touched.newPassword && errors.newPassword}
                    value={values.newPassword || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                />
                <TextFieldInput
                    id="confirmPassword"
                    type="password"
                    label="Confirm new password"
                    className="input-line"
                    placeholder=""
                    error={touched.confirmPassword && errors.confirmPassword}
                    value={values.confirmPassword || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                />
                <div className="submit-btns">
                    <button
                        type="submit"
                        className={`btn btn-login ${isSubmitting
                            ? 'loading'
                            : ''}`}
                        disabled={isSubmitting || !isValid}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(ChangePasswordForm);

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            onPasswordChangedSuccess
        },
        dispatch
    );
};
export default connect(null, mapDispatchToProps)(EnhancedForm);
