import React from 'react';
import { array, object, number, func } from 'prop-types';
import { Table, Popover } from 'components';
import classNames from 'classnames';
import { SettingSelect } from './SettingSelect';
import SettingTableCell from './SettingTableCell';
import RowSelection from './RowSelection';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import './SettingTable-style.css';

const MAX_ROWS_DISPLAY = 10;

class SettingTable extends React.Component {
    static propTypes = {
        sheet: number,
        startRow: number,
        data: object,
        selectedData: array,
        onColumnSetting: func,
        onRowSetting: func
    };

    state = {
        showHeaderPopover: true,
        rowStartIndex: this.props.startRow, // row start to translate
        rowOverIndex: null
    };

    static getDerivedStateFromProps(props, state) {
        if (state.rowStartIndex !== props.startRow) {
            return {
                rowStartIndex: props.startRow
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    onOver = rowIndex => {
        this.setState({
            rowOverIndex: rowIndex
        });
    };

    onDrop = rowIndex => {
        const { onRowSetting, sheet } = this.props;

        this.setState({
            rowStartIndex: rowIndex
        });

        onRowSetting(sheet, rowIndex);
    };

    onDragEnd = () => {
        this.setState({
            rowOverIndex: null
        });
    };

    headerRenderer = params => {
        const { data, sheet, selectedData, onColumnSetting } = this.props;
        const { rowStartIndex, showHeaderPopover } = this.state;
        const columnIndex = parseInt(params.dataKey.replace('cl_', ''));
        const headerRow = data.headerRow || [];
        let onHeaderSelect = null;

        if (onColumnSetting) {
            onHeaderSelect = data => {
                onColumnSetting({ ...data, rowStartIndex, columnIndex, sheet });
            };
        }

        return (
            <div className="setting-select-cell">
                {columnIndex === 0 &&
                    showHeaderPopover && (
                        <Popover show className="setting-tip-popover">
                            <div className="setting-tip">
                                <span>
                                    Click the header of each column to define
                                    its content
                                </span>
                            </div>
                        </Popover>
                    )}
                <SettingSelect
                    selectedValue={
                        selectedData[columnIndex] &&
                        selectedData[columnIndex].id
                    }
                    isLast={
                        columnIndex > 1 && columnIndex === headerRow.length - 1
                    }
                    onSelect={onHeaderSelect}
                />
            </div>
        );
    };

    renderRowNo = params => {
        const { rowStartIndex, rowOverIndex } = this.state;
        const cellClass = classNames({
            'start-translate': params.rowIndex === rowStartIndex,
            dropable: rowOverIndex !== null && params.rowIndex >= rowOverIndex
        });

        return (
            <div>
                {params.rowIndex === rowStartIndex && (
                    <RowSelection
                        onDragEnd={this.onDragEnd}
                        selectedRowIndex={rowStartIndex + 1}
                        selectingRowIndex={rowOverIndex + 1}
                    />
                )}
                <SettingTableCell
                    className={cellClass}
                    cellData={params.rowIndex + 1}
                    rowIndex={params.rowIndex}
                    onOver={this.onOver}
                    onDrop={this.onDrop}
                />
            </div>
        );
    };

    renderRowContent = params => {
        const { rowStartIndex, rowOverIndex } = this.state;
        const cellClass = classNames({
            'start-translate': params.rowIndex === rowStartIndex,
            dropable: rowOverIndex !== null && params.rowIndex >= rowOverIndex
        });

        return (
            <SettingTableCell
                className={cellClass}
                cellData={params.cellData}
                rowIndex={params.rowIndex}
                onOver={this.onOver}
                onDrop={this.onDrop}
            />
        );
    };

    buildColumns = data => {
        const headerRow = data.headerRow || [];
        //const widths = get(data, 'excelMetaData.columnWidths', []);
        const columns = [
            {
                key: 'rowNo',
                label: '',
                width: 46,
                cellRenderer: this.renderRowNo,
                className: 'row-no'
            }
        ];

        headerRow.forEach((header, index) => {
            columns.push({
                label: header,
                key: `cl_${index}`,
                index: index,
                width: 300,
                headerRenderer: this.headerRenderer,
                cellRenderer: this.renderRowContent
            });
        });

        return columns;
    };

    buildRows = (columns, rowData) => {
        const rows = [];
        const maxRowCount =
            rowData.length < MAX_ROWS_DISPLAY
                ? rowData.length
                : MAX_ROWS_DISPLAY;

        for (let i = 0; i < maxRowCount; i++) {
            const data = rowData[i];
            const row = {};

            data.forEach((rowValue, index) => {
                row[columns[index + 1].key] = rowValue;
            });

            rows.push(row);
        }

        return rows;
    };

    render() {
        const { data } = this.props;
        const columns = this.buildColumns(data);
        const list = this.buildRows(columns, data.rows);

        return (
            <Table className="setting-table" columns={columns} data={list} />
        );
    }
}

export default DragDropContext(HTML5Backend)(SettingTable);
