import React from 'react';
import { Column, Table as VirtualizedTable } from 'react-virtualized';
import { array, string, number, func, oneOfType } from 'prop-types';
import classNames from 'classnames';

import 'react-virtualized/styles.css'; // only needs to be imported once
import './Table-style.css';

const DEFAULT_COLUMN_WIDTH = 200;
const DEFAULT_ROW_HEIGHT = 40;

class Table extends React.Component {
    static propTypes = {
        className: string,
        data: array.isRequired,
        columns: array,
        width: number,
        height: number,
        rowHeight: oneOfType([number, func])
    };

    headerRenderer = params => {
        return params.label;
    };

    cellRenderer = params => {
        return params.cellData;
    };

    getDefaultTableWidth = () => {
        const { columns } = this.props;

        return columns.reduce((totalWidth, column) => {
            return totalWidth + (column.width || DEFAULT_COLUMN_WIDTH);
        }, 0);
    };

    getDefaultTableHeight = () => {
        const { data, rowHeight } = this.props;

        return data.length * (rowHeight || DEFAULT_ROW_HEIGHT) + 40;
    };

    buildColumns = columns => {
        const elements = [];

        columns.forEach(column => {
            const colClass = classNames('cell', column.className);

            elements.push(
                <Column
                    key={column.key || column.columnKey}
                    label={column.label}
                    dataKey={column.key || column.columnKey}
                    width={column.width || DEFAULT_COLUMN_WIDTH}
                    flexGrow={
                        column.flexGrow !== undefined ? column.flexGrow : 1
                    }
                    disableSort={!column.sort}
                    headerRenderer={
                        column.headerRenderer || this.headerRenderer
                    }
                    cellRenderer={column.cellRenderer || this.cellRenderer}
                    className={colClass}
                />
            );
        });

        return elements;
    };

    render() {
        const {
            data,
            columns,
            width,
            height,
            rowHeight,
            className
        } = this.props;
        const componentClass = classNames('table-container', className);

        return (
            <div className={componentClass}>
                <VirtualizedTable
                    width={width || this.getDefaultTableWidth()}
                    height={height || this.getDefaultTableHeight()}
                    className="Table"
                    headerHeight={40}
                    headerClassName="header-cell"
                    rowHeight={rowHeight || DEFAULT_ROW_HEIGHT}
                    rowCount={data.length}
                    rowGetter={({ index }) => data[index]}
                >
                    {this.buildColumns(columns)}
                </VirtualizedTable>
            </div>
        );
    }
}

export default Table;
