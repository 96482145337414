import React from 'react';
import { func, bool, any, number, string } from 'prop-types';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';

class SettingTableCell extends React.PureComponent {
    static propTypes = {
        className: string,
        connectDropTarget: func.isRequired,
        isOver: bool.isRequired,
        cellData: any,
        rowIndex: number,
        onDrop: func,
        onOver: func
    };

    componentDidUpdate() {
        const { rowIndex, isOver, onOver } = this.props;

        if (isOver) {
            onOver(rowIndex);
        }
    }

    render() {
        const { className, cellData, isOver, connectDropTarget } = this.props;

        const componentClass = classNames('cell-content', className, {
            dropable: isOver
        });

        return connectDropTarget(
            <div className={componentClass} title={cellData}>
                {cellData}
            </div>
        );
    }
}

const collect = (connect, monitor) => {
    return {
        isOver: monitor.isOver(),
        connectDropTarget: connect.dropTarget()
    };
};
const dropOnTarget = {
    drop(props, monitor) {
        props.onDrop(props.rowIndex);
    }
};

export default DropTarget('row-start-translate', dropOnTarget, collect)(
    SettingTableCell
);
