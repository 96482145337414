const GAME_DATA = {
    CN: {
        population: 850,
        revenue: '34,400',
        languages: ['zhCN']
    },
    US: {
        population: 265,
        revenue: '31,535',
        languages: ['enUS']
    },
    JP: {
        population: 121,
        revenue: '17,715',
        languages: ['jaJP']
    },
    KR: {
        population: 48,
        revenue: '5,764',
        languages: ['koKR']
    },
    DE: {
        population: 76,
        revenue: '4,989',
        languages: ['deDE']
    },
    GB: {
        population: 64,
        revenue: '4,731',
        languages: ['enUS']
    },
    FR: {
        population: 58,
        revenue: '3,366',
        languages: ['frFR']
    },
    CA: {
        population: 34,
        revenue: '2,399',
        languages: ['frCA']
    },
    ES: {
        population: 39,
        revenue: '2,202',
        languages: ['esES']
    },
    IT: {
        population: 40,
        revenue: '2,168',
        languages: ['itIT']
    },
    RU: {
        population: 113,
        revenue: '1,657',
        languages: ['ruRU']
    },
    MX: {
        population: 86,
        revenue: '1,577',
        languages: ['esMX']
    },
    BR: {
        population: 142,
        revenue: '1,452',
        languages: ['ptBR']
    },
    AU: {
        population: 23,
        revenue: '1,293',
        languages: ['enUS']
    },
    TW: {
        population: 20,
        revenue: '1,231',
        languages: ['zhTW']
    },
    IN: {
        population: 481,
        revenue: '1,105',
        languages: ['hiIN']
    },
    ID: {
        population: 82,
        revenue: '1,084',
        languages: ['idID', 'jwID']
    },
    TR: {
        population: 53,
        revenue: 853,
        languages: ['trTR']
    },
    TH: {
        population: 38,
        revenue: 667,
        languages: ['thTH']
    },
    NL: {
        population: 16,
        revenue: 646,
        languages: ['nlNL']
    },
    MY: {
        population: 27,
        revenue: 633,
        languages: ['msMY']
    },
    SA: {
        population: 26,
        revenue: 577,
        languages: ['arSA']
    },
    PL: {
        population: 30,
        revenue: 541,
        languages: ['plPL']
    },
    CH: {
        population: 8,
        revenue: 537,
        languages: ['deDE']
    },
    HK: {
        population: 7,
        revenue: 502,
        languages: ['zhHK']
    },
    IR: {
        population: 49,
        revenue: 487,
        languages: []
    },
    VN: {
        population: 52,
        revenue: 472,
        languages: ['viVN']
    },
    SE: {
        population: 9,
        revenue: 463,
        languages: ['svSE']
    },
    PH: {
        population: 66,
        revenue: 461,
        languages: ['tlPH']
    },
    AR: {
        population: 34,
        revenue: 448,
        languages: ['esMX']
    },
    IL: {
        population: 7,
        revenue: 402,
        languages: ['heIL']
    },
    AT: {
        population: 8,
        revenue: 378,
        languages: ['deDE']
    },
    BE: {
        population: 10,
        revenue: 378,
        languages: ['nlNL']
    },
    NO: {
        population: 5,
        revenue: 346,
        languages: ['noNO']
    },
    AE: {
        population: 9,
        revenue: 342,
        languages: ['arSA']
    },
    CO: {
        population: 32,
        revenue: 322,
        languages: ['esMX']
    },
    SG: {
        population: 5,
        revenue: 319,
        languages: ['zhCN']
    },
    DK: {
        population: 6,
        revenue: 297,
        languages: ['daDK']
    },
    PT: {
        population: 8,
        revenue: 297,
        languages: ['ptPT']
    },
    EG: {
        population: 48,
        revenue: 293,
        languages: ['arSA']
    },
    FI: {
        population: 5,
        revenue: 253,
        languages: ['fiFI']
    },
    CL: {
        population: 13,
        revenue: 234,
        languages: ['esMX']
    },
    ZA: {
        population: 35,
        revenue: 216,
        languages: ['enUS']
    },
    KZ: {
        population: 15,
        revenue: 207,
        languages: []
    },
    IE: {
        population: 4,
        revenue: 205,
        languages: ['enUS']
    },
    UA: {
        population: 26,
        revenue: 179,
        languages: ['ukUA']
    },
    RO: {
        population: 13,
        revenue: 176,
        languages: ['roRO']
    },
    GR: {
        population: 8,
        revenue: 171,
        languages: ['elGR']
    },
    CZ: {
        population: 9,
        revenue: 157,
        languages: ['csCZ']
    },
    PE: {
        population: 17,
        revenue: 152,
        languages: ['esMX']
    },
    PK: {
        population: 39,
        revenue: 131,
        languages: ['urIN']
    },
    MA: {
        population: 24,
        revenue: 129,
        languages: ['arSA']
    },
    NG: {
        population: 61,
        revenue: 122,
        languages: ['enUS']
    },
    HU: {
        population: 8,
        revenue: 119,
        languages: ['huHU']
    },
    BD: {
        population: 38,
        revenue: 108,
        languages: ['bnBD']
    },
    DZ: {
        population: 21,
        revenue: 107,
        languages: ['arSA']
    },
    IQ: {
        population: 10,
        revenue: 105,
        languages: ['arSA']
    },
    KW: {
        population: 3,
        revenue: 105,
        languages: ['arSA']
    },
    LB: {
        population: 5,
        revenue: 102,
        languages: ['arSA']
    },
    QA: {
        population: 3,
        revenue: 85,
        languages: ['arSA']
    },
    AZ: {
        population: 8,
        revenue: 85,
        languages: []
    },
    SK: {
        population: 5,
        revenue: 81,
        languages: ['skSK']
    },
    NZ: {
        population: 4,
        revenue: 77,
        languages: ['enUS']
    },
    MM: {
        population: 16,
        revenue: 76,
        languages: []
    },
    EC: {
        population: 10,
        revenue: 75,
        languages: ['esMX']
    },
    RS: {
        population: 6,
        revenue: 74,
        languages: []
    },
    BY: {
        population: 7,
        revenue: 69,
        languages: []
    },
    PR: {
        population: 3,
        revenue: 67,
        languages: ['esMX']
    },
    JO: {
        population: 7,
        revenue: 66,
        languages: ['arSA']
    },
    HR: {
        population: 3,
        revenue: 60,
        languages: []
    },
    DO: {
        population: 7,
        revenue: 58,
        languages: ['esMX']
    },
    OM: {
        population: 4,
        revenue: 57,
        languages: ['arSA']
    },
    UZ: {
        population: 17,
        revenue: 54,
        languages: []
    },
    KH: {
        population: 6,
        revenue: 52,
        languages: []
    },
    LT: {
        population: 2,
        revenue: 52,
        languages: []
    },
    BG: {
        population: 5,
        revenue: 52,
        languages: ['bgBG']
    },
    LK: {
        population: 8,
        revenue: 48,
        languages: []
    },
    UY: {
        population: 3,
        revenue: 47,
        languages: ['esMX']
    },
    TN: {
        population: 7,
        revenue: 45,
        languages: ['arSA']
    },
    SI: {
        population: 2,
        revenue: 44,
        languages: []
    },
    BS: {
        population: 2,
        revenue: 43,
        languages: ['enUS']
    },
    GT: {
        population: 7,
        revenue: 42,
        languages: ['esMX']
    },
    CR: {
        population: 4,
        revenue: 42,
        languages: ['esMX']
    },
    LU: {
        population: 1,
        revenue: 34,
        languages: ['deDE']
    },
    CU: {
        population: 5,
        revenue: 33,
        languages: ['esMX']
    },
    LV: {
        population: 2,
        revenue: 33,
        languages: []
    },
    CY: {
        population: 1,
        revenue: 27,
        languages: ['elGR']
    },
    PA: {
        population: 3,
        revenue: 27,
        languages: ['esMX']
    },
    PY: {
        population: 4,
        revenue: 27,
        languages: ['esMX']
    },
    ET: {
        population: 21,
        revenue: 26,
        languages: []
    },
    BT: {
        population: 5,
        revenue: 26,
        languages: []
    },
    BA: {
        population: 2,
        revenue: 25,
        languages: []
    },
    EE: {
        population: 1,
        revenue: 24,
        languages: []
    },
    MO: {
        population: 1,
        revenue: 24,
        languages: ['zhCN']
    },
    AM: {
        population: 2,
        revenue: 24,
        languages: []
    },
    KE: {
        population: 16,
        revenue: 23,
        languages: []
    },
    GH: {
        population: 12,
        revenue: 23,
        languages: ['enUS']
    },
    AL: {
        population: 2,
        revenue: 22,
        languages: []
    },
    AO: {
        population: 5,
        revenue: 22,
        languages: ['ptBR']
    },
    GE: {
        population: 3,
        revenue: 20,
        languages: []
    }
};

export default GAME_DATA;
