import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';
import { CUSTOMER } from './actionConstants';
import { MESSAGES } from './constants';
import { LOG_OUT } from '../auth/actionConstants';
import { ORDER } from '../order/actionConstants';

const INITIAL_STATE = fromJS({
    error: null,
    orders: {
        loading: false,
        total: null,
        data: null
    },
    questions: {
        loading: false,
        id: null, // order Id
        data: null
    },
    answers: {
        loading: false,
        id: null, // question id
        data: null
    },
    sources: []
});

const handlers = {
    [ORDER.START_NEW_ORDER]() {
        return INITIAL_STATE;
    },
    [LOG_OUT]() {
        return INITIAL_STATE;
    },
    [CUSTOMER.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
        });
    },
    [CUSTOMER.FETCH_ORDER_LIST](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['orders', 'loading'], true);
        });
    },
    [CUSTOMER.ON_ORDER_LIST_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['orders', 'loading'], false);
            mutableState.setIn(
                ['orders', 'data'],
                fromJS((data && data.rows) || [])
            );
            mutableState.setIn(
                ['orders', 'total'],
                fromJS((data && data.total) || 0)
            );
        });
    },
    [CUSTOMER.ON_ORDER_LIST_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['orders', 'loading'], false);
        });
    },
    [CUSTOMER.FETCH_QUESTION_LIST](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['questions', 'loading'], true);
        });
    },
    [CUSTOMER.ON_QUESTION_LIST_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['questions', 'loading'], false);
            mutableState.setIn(['questions', 'data'], fromJS(data));
        });
    },
    [CUSTOMER.ON_QUESTION_LIST_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['questions', 'loading'], false);
        });
    },
    [CUSTOMER.FETCH_ANSWER_LIST](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['answers', 'loading'], true);
        });
    },
    [CUSTOMER.ON_ANSWER_LIST_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['answers', 'loading'], false);
            mutableState.setIn(['answers', 'data'], fromJS(data));
        });
    },
    [CUSTOMER.ON_ANSWER_LIST_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['answers', 'loading'], false);
        });
    },
    [CUSTOMER.ON_ADD_ANSWER_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], MESSAGES.ERROR);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['executing'], false);
        });
    },
    [CUSTOMER.ON_ANSWER_ADDED](state, { data }) {
        const { sourceText, questionId } = data;
        const sources = state.getIn(['questions', 'data']).toJS();
        const questions = sources[sourceText ? sourceText : 'undefined'];
        const index = questions.findIndex(
            question => question.id === questionId
        );
        const answers = questions[index]['answers'];
        answers.push(data);

        return state.withMutations(mutableState => {
            mutableState.set(['error'], null);
            mutableState.setIn(
                ['questions', 'data', sourceText, index, 'answers'],
                fromJS(answers)
            );
        });
    },
    [CUSTOMER.ON_ADD_ANSWER_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], MESSAGES.ERROR);
            mutableState.setIn(['loading'], false);
            mutableState.setIn(['executing'], false);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
