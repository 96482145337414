import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Yup from 'yup';
import { TextFieldInput, Message } from 'components';
import { resetPassword } from 'services/auth';
import { getMessage } from 'common/getMessage';
import { onResetPasswordSuccess } from './actions';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
    }),
    handleSubmit: (payload, { setSubmitting, setStatus, props }) => {
        setStatus({ error: null });
        resetPassword(payload)
            .then(data => {
                props.onResetPasswordSuccess(data);
                setSubmitting(false);
            })
            .catch(e => {
                setStatus({
                    error: getMessage({
                        token: e.message,
                        httpCode: e.code || e.status
                    })
                });
                setSubmitting(false);
            });
    },
    displayName: 'ForgotPasswordForm'
});

export const ForgotPasswordForm = props => {
    const {
        values,
        touched,
        errors,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        header
    } = props;

    const message = status &&
        status.error && <Message type="danger" message={status.error} />;
    const redirect =
        status && status.redirectTo ? (
            <Redirect to={status.redirectTo} />
        ) : null;

    return (
        <div className="forgot-password-form">
            {message}
            {redirect}
            {!!header && header}
            <form onSubmit={handleSubmit}>
                <TextFieldInput
                    id="email"
                    type="email"
                    label="Email"
                    className="input-line"
                    placeholder=""
                    error={touched.email && errors.email}
                    value={values.email || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="submit-btns">
                    <button
                        type="submit"
                        className={`btn btn-login ${isSubmitting
                            ? 'loading'
                            : ''}`}
                        disabled={isSubmitting || !isValid}
                    >
                        Reset your password
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(ForgotPasswordForm);

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            onResetPasswordSuccess
        },
        dispatch
    );
};
export default connect(null, mapDispatchToProps)(EnhancedForm);
