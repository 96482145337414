import React from 'react';
import { array, func, string, object } from 'prop-types';
import classNames from 'classnames';
import MenuItem from './MenuItem';
import onClickOutside from 'react-onclickoutside';
import { SearchInput } from 'components';

const DIRECTION_MAP = {
    up: 'dropup',
    down: '',
    left: 'dropleft',
    right: 'dropright'
};

class MenuDropdown extends React.Component {
    static propTypes = {
        label: string,
        selectedOption: object,
        searchName: string,
        optionGroups: array,
        options: array,
        className: string,
        direction: string,
        groupDirection: string,
        onChange: func
    };
    state = {
        show: false,
        filter: ''
    };

    handleClickOutside = event => {
        this.setState({
            show: false
        });
    };

    toggleShow = () => {
        const { show } = this.state;

        this.setState({
            show: !show
        });
    };

    buildMenuItems = (options, selectedOption, filter) => {
        const elements = [];
        const { groupDirection } = this.props;

        options.forEach(option => {
            const found = !(
                filter &&
                filter.length > 0 &&
                option.label.toLowerCase().search(filter.toLowerCase()) === -1
            );

            if (found) {
                elements.push(
                    <MenuItem
                        key={option.value}
                        option={option}
                        selectedOption={selectedOption}
                        searchName={option.searchName}
                        direction={groupDirection}
                        onClick={this.onItemClick}
                    />
                );
            }
        });

        return elements;
    };

    buildGroupMenuItems = (options, selectedOption, filter, optionGroups) => {
        const elements = [];

        optionGroups.forEach(optionGroup => {
            elements.push(
                <div
                    key={`dropdown-group-${optionGroup.value}`}
                    className="dropdown-group"
                >
                    {optionGroup.label}
                </div>
            );

            const groupOptions = options.filter(
                option => optionGroup.value === option.optionGroup
            );
            const groupItems = this.buildMenuItems(
                groupOptions,
                selectedOption,
                filter
            );

            elements.push(groupItems);
        });

        return elements;
    };

    onItemClick = option => {
        const { onChange } = this.props;

        this.setState({
            show: false,
            filter: ''
        });

        onChange(option);
    };

    onSearchInput = text => {
        this.setState({
            filter: text
        });
    };

    render() {
        const {
            label,
            selectedOption,
            options,
            optionGroups,
            searchName,
            className,
            direction
        } = this.props;
        const { show, filter } = this.state;
        const btnClass = classNames(
            'btn-group',
            className,
            DIRECTION_MAP[direction]
        );
        const menuClass = classNames('dropdown-menu', { show: show });
        const title = label ? label : selectedOption.label;

        options.forEach(option => {
            if (option.value === selectedOption.value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        });

        const menuItems = optionGroups
            ? this.buildGroupMenuItems(
                  options,
                  selectedOption,
                  filter,
                  optionGroups
              )
            : this.buildMenuItems(options, selectedOption, filter);

        return (
            <div className={btnClass}>
                <div className="btn dropdown-toggle" onClick={this.toggleShow}>
                    {!!selectedOption.icon && selectedOption.icon}
                    {title}
                </div>
                <div className={menuClass}>
                    {searchName && (
                        <SearchInput
                            placeholder={searchName}
                            onChange={this.onSearchInput}
                            value={filter}
                        />
                    )}
                    {menuItems}
                </div>
            </div>
        );
    }
}

export default onClickOutside(MenuDropdown);
