import React from 'react';
import { Navbar, Nav } from 'components';
import StepNavItem from './StepNavItem';

import './StepNavBar-style.css';

const STEPS = [
    {
        name: 'Upload file'
    },
    {
        name: 'Setup file'
    },
    {
        name: 'Select languages'
    },
    {
        name: 'Add information'
    },
    {
        name: 'Complete & Pay'
    }
];

const StepNavBar = ({ currentStep, onStepBack }) => {
    return (
        <Navbar classNames="step-provider-header mr-auto justify-content-center">
            {currentStep > 1 && (
                <button
                    type="button"
                    className="btn btn-back"
                    onClick={onStepBack}
                >
                    <i className="fas fa-arrow-left" />
                    Back
                </button>
            )}
            <Nav classNames="nav-pills nav-fill">
                {STEPS.map(({ path, name }, ix) => {
                    return (
                        <StepNavItem
                            key={`step-nav-item-${ix}`}
                            title={name}
                            index={ix + 1}
                            currentStep={currentStep}
                            length={STEPS.length}
                        />
                    );
                })}
            </Nav>
        </Navbar>
    );
};

export default StepNavBar;
