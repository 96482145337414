import React from 'react';

import './order.css';

const StepWrapper = ({ id, show = true, children }) => {
    return (
        show && (
            <section id={id} className="step-wrapper">
                {children}
            </section>
        )
    );
};

export default StepWrapper;
