import { API_URL } from 'common/const';

const DEFAULT_OPTIONS = {
    method: 'POST',
    headers: {}
};
const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

export function invoicePaymentRequest({
    path = '/api/v1/db',
    params = {},
    options = {},
    host = API_URL,
    serviceURI = '',
    useDefaultHeader = true
} = {}) {
    const reqOptions = {
        ...DEFAULT_OPTIONS,
        ...options
    };
    reqOptions.headers = {
        ...(useDefaultHeader ? DEFAULT_HEADERS : {}),
        ...reqOptions.headers
    };
    const queryString = queryParams(params);

    const requestURL = `${host}${serviceURI}${path}${
        queryString.length > 0 ? `?${queryString}` : ''
    }`;
    return fetch(requestURL, reqOptions).then(handleJSONResponse);
}

function handleJSONResponse(response) {
    const requestId = response.headers
        ? response.headers.get('x-request-id')
        : 'unknown';

    return response.json().then(json => {
        if (response.ok) {
            return json;
        } else {
            const error = Object.assign({}, json, {
                status: response.status,
                statusText: response.statusText,
                requestId
            });
            return Promise.reject(error);
        }
    });
}

function queryParams(params) {
    return Object.entries(params)
        .reduce((qs, [k, v]) => {
            if (Array.isArray(v)) {
                v.forEach(p => {
                    qs.push(
                        `${encodeURIComponent(k)}=${encodeURIComponent(p)}`
                    );
                });
            } else {
                qs.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }

            return qs;
        }, [])
        .join('&');
}
