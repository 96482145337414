import React from 'react';
import './Status-style.css';

const COMPLETED = 2048;
const Status = ({ state = 0 }) => {
    return (
        <span
            className="status"
            style={{ color: state !== COMPLETED ? '#d29d33' : '#584487' }}
        >
            {state !== COMPLETED ? 'In Progress' : 'Completed'}
        </span>
    );
};
export default Status;
