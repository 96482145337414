import React from 'react';
import { func, string, any, number } from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTimeInput-style.scss';

const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy';

class DateTimeInput extends React.Component {
    static propTypes = {
        value: any,
        placeholder: string,
        className: string,
        utcOffset: number, // in minutes
        onChange: func
    };

    static defaultProps = {
        utcOffset: 0
    };

    state = {
        value: null,
        valueDisplay: null
    };

    getDateTimeFormat() {
        return `${DEFAULT_DATE_FORMAT}`;
    }

    handleChange = date => {
        const { utcOffset } = this.props;
        let val = null;

        if (date) {
            // Get date and time value without timezone
            const format = this.getDateTimeFormat();
            val = moment(date, format);
            const offsetInMinutes = val.utcOffset();

            // UTC date time before parsing to iso to prevent the date time value auto converted from local to utc.
            val = moment
                .utc(val)
                .add(offsetInMinutes - utcOffset, 'm')
                .toISOString();
        }

        this.setState({
            value: val,
            valueDisplay: date
        });

        return date;
    };

    handleCalendarClose = () => {
        const { value } = this.state;
        const { onChange } = this.props;

        if (value) {
            onChange(value);
        } else {
            onChange(undefined);
        }
    };

    onBlur = () => {
        this.handleCalendarClose();
    };

    getValue = () => {
        const { value, utcOffset } = this.props;

        if (value) {
            var date = new Date(value);
            const offset = new Date().getTimezoneOffset() + utcOffset;

            date.setTime(date.getTime() + offset * 60 * 1000);

            return date;
        }

        return undefined;
    };

    render() {
        const { placeholder, autoFocus } = this.props;
        const { valueDisplay } = this.state;
        const selectedValue = valueDisplay
            ? typeof valueDisplay === 'string'
                ? new Date(valueDisplay)
                : valueDisplay
            : this.getValue();

        return (
            <div className="date-input">
                <DatePicker
                    selected={selectedValue}
                    dateFormat={this.getDateTimeFormat()}
                    placeholderText={placeholder || this.getDateTimeFormat()}
                    onChange={this.handleChange}
                    onCalendarClose={this.handleCalendarClose}
                    onBlur={this.onBlur}
                    timeIntervals={30}
                    autoFocus={autoFocus}
                />
                <i className="far fa-calendar-alt" />
            </div>
        );
    }
}

export default DateTimeInput;
