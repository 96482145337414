import get from 'lodash/get';
import { requestData, receiveData } from '../api/actions';
import * as trackerApi from 'services/tracker';

import { TRACKER } from './actionConstants';

export function closeMessage() {
    return {
        type: TRACKER.CLOSE_MESSAGE
    };
}

export function fetchOrder(orderId) {
    return (dispatch, getState) => {
        dispatch(requestData(TRACKER.EXECUTING));

        trackerApi
            .getOrder(orderId)
            .then(payload => {
                const orderPayload = get(payload, ['data'], {});

                const order = orderPayload.order;
                const user = orderPayload.user;
                const project = orderPayload.project;
                const orderNumber = get(payload, 'orderNumber');

                dispatch(
                    receiveData(TRACKER.ON_ORDER_FETCHED, {
                        order,
                        orderNumber,
                        user,
                        project
                    })
                );
            })
            .catch(e => {
                dispatch(receiveData(TRACKER.ON_FAILED, e));
            });
    };
}

export function confirmOrder({ orderId, state, comments }) {
    return (dispatch, getState) => {
        dispatch(requestData(TRACKER.EXECUTING));

        trackerApi
            .confirmOrder({ orderId, state, comments })
            .then(payload => {
                //const orderPayload = get(payload, ['data'], {});

                dispatch(
                    receiveData(TRACKER.ON_CONFIRMED, {
                        orderId,
                        state,
                        comments
                    })
                );
            })
            .catch(e => {
                dispatch(receiveData(TRACKER.ON_FAILED, e));
            });
    };
}

export const startNewOrder = () => {
    window.location = '/';
};
