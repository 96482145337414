import React from 'react';
import classNames from 'classnames';

const StepNavItem = ({ index, currentStep, title, length = 0 }) => {
    const componentClass = classNames('nav-item step-nav-item', {
        active: index === currentStep,
        done: index < currentStep
    });

    return (
        <li className={componentClass}>
            <div className="content">
                <i className="far fa-circle icon pending-icon" />
                <i className="far fa-check-circle icon check-icon" />
                {title}
            </div>
            {index !== length && <div className="conector" />}
        </li>
    );
};

export default StepNavItem;
