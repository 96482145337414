import { combineReducers } from 'redux-immutable';
import authReducer from '../auth/reducer';
import orderReducer from '../order/reducer';
import customerReducer from '../customer/reducer';
import paymentReducer from '../payment/reducer';
import trackerReducer from '../tracker/reducer';

export default combineReducers({
    auth: authReducer,
    order: orderReducer,
    customer: customerReducer,
    payment: paymentReducer,
    tracker: trackerReducer
});
