import React from 'react';
import { func, object, string } from 'prop-types';
import StripePayProvider from '../components/payment/stripe/PayProvider';
import RequestPayProvider from '../components/payment/stripe/PaymentRequestProvider';
//import PaypalPayProvider from '../components/payment/paypal/PayProvider';

const PAY_METHODS = {
    CARD: 'card',
    SAVED_CARD: 'saved_card',
    PAYPAL: 'paypal'
};

class PaymentMethod extends React.PureComponent {
    static propTypes = {
        invoice: object.isRequired,
        selectedMethod: string,
        user: object.isRequired,
        payment: object.isRequired,
        executePayment: func.isRequired,
        setupPayment: func.isRequired,
        lockPayment: func.isRequired,
        onSelect: func
    };
    state = {
        canMakeRequestPayment: false
    };

    changeMethod = e => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(e.target.value);
        }
    };

    onRequestPayProviderReady = isSupport => {
        this.setState({
            canMakeRequestPayment: isSupport
        });
    };

    render() {
        const { selectedMethod } = this.props;
        const { canMakeRequestPayment } = this.state;

        return (
            <div className="summary payment-method">
                <div className="title">Payment method</div>
                <div className="content-container">
                    <div>
                        <input
                            id="credit-card-item"
                            type="radio"
                            name="payment-method"
                            value={PAY_METHODS.CARD}
                            checked={selectedMethod === PAY_METHODS.CARD}
                            onChange={this.changeMethod}
                        />
                        <label htmlFor="credit-card-item" className="selection">
                            Credit card
                        </label>
                        <span className="items">
                            <img
                                title="Visa card"
                                alt="Visa card"
                                src="/img/logo_visa.png"
                            />
                            <img
                                title="Master card"
                                alt="Master card"
                                src="/img/logo_mastercard.png"
                            />
                            <img
                                title="American Express card"
                                alt="American Express card"
                                src="/img/logo_amercan_express.png"
                            />
                        </span>
                        {selectedMethod === PAY_METHODS.CARD && (
                            <StripePayProvider {...this.props} />
                        )}
                    </div>
                    {/*}
                    <div>
                        <input
                            id="paypal-item"
                            type="radio"
                            name="payment-method"
                            value={PAY_METHODS.PAYPAL}
                            checked={selectedMethod === PAY_METHODS.PAYPAL}
                            onChange={this.changeMethod}
                        />
                        <label htmlFor="paypal-item" className="selection">
                            Paypal
                        </label>
                        <span className="items">
                            <img
                                title="Paypal card"
                                alt="Paypal card"
                                src="/img/logo_paypal.png"
                            />
                        </span>
                            //Issue: user clicking on the pay button while the paypal scripts still on loading.
                            //Quick solution: load the Paypal when page loading so that have enough time for scripts to load, 
                            //then will show it when Paypal selected.
                        <PaypalPayProvider
                            {...this.props}
                            show={selectedMethod === PAY_METHODS.PAYPAL}
                        />
                    </div>
                    */}
                    {canMakeRequestPayment && (
                        <div>
                            <input
                                id="saved-card-item"
                                type="radio"
                                name="payment-method"
                                value={PAY_METHODS.SAVED_CARD}
                                checked={
                                    selectedMethod === PAY_METHODS.SAVED_CARD
                                }
                                onChange={this.changeMethod}
                            />
                            <label
                                htmlFor="saved-card-item"
                                className="selection"
                            >
                                Saved card
                            </label>
                            <span className="items">
                                <img
                                    className="card-icon"
                                    title="Visa card"
                                    alt="Visa card"
                                    src="/img/apple-pay-icon.jpg"
                                />
                                <img
                                    className="card-icon"
                                    title="Master card"
                                    alt="Master card"
                                    src="/img/google-pay-icon.png"
                                />
                                <img
                                    className="card-icon"
                                    title="American Express card"
                                    alt="American Express card"
                                    src="/img/window-pay-icon.png"
                                />
                            </span>
                        </div>
                    )}
                    <RequestPayProvider
                        {...this.props}
                        show={selectedMethod === PAY_METHODS.SAVED_CARD}
                        onReady={this.onRequestPayProviderReady}
                    />
                </div>
            </div>
        );
    }
}

export default PaymentMethod;
