import React from 'react';
import get from 'lodash/get';

import { toFloatCurrencyString } from 'common/stringUtils';
import { CURRENCIES } from 'common/const';

const PaymentSummary = ({ order }) => {
    const currency = get(order, 'currency', '');
    const currencyIcon = CURRENCIES[currency] ? CURRENCIES[currency].ICON : '';
    //const discount = get(order, 'totalDiscountAmount', 0);

    return (
        <div className="summary payment-summary row">
            <div className="col-xl-5 col-md-4" />
            <div className="col-xl-7 col-md-8">
                <div className="cost">
                    <span className="label">{`Total cost`}</span>
                    <span className="value bold-2">
                        {toFloatCurrencyString(
                            get(order, 'totalTranslationCost', '')
                        )}
                    </span>
                </div>
                {get(order, 'pmFee', 0) > 0 && (
                    <div className="fee">
                        <span className="label">Project management cost</span>
                        <span className="value">
                            {toFloatCurrencyString(get(order, 'pmFee', ''))}
                        </span>
                    </div>
                )}
                {/*discount !== 0 && (
                    <div className="discount">
                        <span className="label">Discount</span>
                        <span className="value">{`- ${discount}`}</span>
                    </div>
                )*/}
                {get(order, 'minimumAdjustmentFee', 0) > 0 && (
                    <div className="min-adjustment-fee">
                        <span className="label">Min. adjustment fee</span>
                        <span className="value">
                            {toFloatCurrencyString(
                                get(order, 'minimumAdjustmentFee', '')
                            )}
                        </span>
                    </div>
                )}
                {get(order, 'totalVAT', 0) > 0 && (
                    <React.Fragment>
                        <hr className="line-1" />
                        <div className="sub-total-payed">
                            <span className="label">{`Total excl. TAX`}</span>
                            <span className="value">
                                {toFloatCurrencyString(
                                    get(order, 'netCost', '')
                                )}
                            </span>
                        </div>
                        <div className="vat">
                            <span className="label">{`VAT`}</span>
                            <span className="value">
                                {toFloatCurrencyString(
                                    get(order, 'totalVAT', '')
                                )}
                            </span>
                        </div>
                    </React.Fragment>
                )}
                <hr className="line-2" />
                <div className="total-payed">
                    <span className="label bold-1">{`Total to pay (${currency})`}</span>
                    <span className="value bold">
                        {`${currencyIcon} ${toFloatCurrencyString(
                            get(order, 'totalCost', '')
                        )}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentSummary;
