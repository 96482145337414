import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyPasswordReset } from './actions';

class AuthPage extends React.Component {
    componentDidMount() {
        const {
            match: { params: { token, userUid } },
            verifyPasswordReset,
            history
        } = this.props;

        verifyPasswordReset({ token, userUid, history });
    }

    render() {
        return <div />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            verifyPasswordReset
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(AuthPage);
