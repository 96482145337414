import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { array, object, number, func } from 'prop-types';
import unionWith from 'lodash/unionWith';
import xorWith from 'lodash/xorWith';
import get from 'lodash/get';

import { Spinner, toJS } from 'components';
import {
    fetchAllLanguages,
    checkoutOrder,
    calculateOrderPrice
} from './actions';
import LangSelectionListView from './LangSelectionListView';
import LangSelectionMapView from './LangSelectionMapView';
import LangSelectionTotal from './LangSelectionTotal';
import { CURRENCIES } from 'common/const';

import './MarketSelectionStep-style.css';

class MarketSelectionStep extends React.PureComponent {
    static propTypes = {
        defaultSelectedValue: array,
        languages: object,
        sourceWordCount: number,
        fetchAllLanguages: func
    };

    state = {
        changed: false,
        selectedValue: [],
        selectedCurrency:
            get(this.props.quotation, 'currency') || CURRENCIES.EUR.CODE
    };

    static getDerivedStateFromProps(props, state) {
        const currency = get(props.quotation, 'currency');

        if (!state.changed) {
            return {
                selectedValue: props.defaultSelectedValue,
                selectedCurrency: currency
            };
        } else if (currency !== state.selectedCurrency) {
            return {
                selectedCurrency: currency
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    onLanguageItemSelect = items => {
        const { orderId, calculateOrderPrice } = this.props;
        const { selectedValue, selectedCurrency } = this.state;
        const value = xorWith(selectedValue, items, (a, b) => a.id === b.id);

        this.setState({
            changed: true,
            selectedValue: value
        });

        calculateOrderPrice(orderId, value, selectedCurrency);
    };

    onCurrencySelect = currencyValue => {
        const { orderId, calculateOrderPrice } = this.props;
        const { selectedValue } = this.state;

        this.setState({
            selectedCurrency: currencyValue
        });

        calculateOrderPrice(orderId, selectedValue, currencyValue);
    };

    onCheckout = () => {
        const { orderId, checkoutOrder } = this.props;
        const { selectedValue, selectedCurrency } = this.state;

        if (checkoutOrder) {
            checkoutOrder(orderId, selectedValue, selectedCurrency);
        }
    };

    componentDidMount() {
        const { languages, fetchAllLanguages } = this.props;

        if (!languages.loading && !languages.all) {
            fetchAllLanguages();
        }
    }

    render() {
        const { sourceWordCount, quotation, languages } = this.props;

        if (languages.loading || !languages.all) {
            return <Spinner />;
        }

        const { selectedValue, selectedCurrency } = this.state;
        const allLangs = languages.all;
        const langPrices = get(quotation, 'items', []);

        allLangs.forEach(langItem => {
            const price = langPrices.find(
                priceItem => priceItem.lang === langItem.id
            );

            if (price) {
                Object.assign(langItem, price);
            }
        });

        return (
            <div className="step-page market-selection">
                <div className="page-title">
                    Select what markets or users to reach
                </div>
                <div className="selection-content row">
                    <div className="list-view col-md-4">
                        <LangSelectionListView
                            allLanguages={allLangs}
                            selectedValue={selectedValue}
                            selectedCurrency={selectedCurrency}
                            onSelect={this.onLanguageItemSelect}
                            onCurrencySelect={this.onCurrencySelect}
                        />
                    </div>
                    <div className="map-view col-md-8">
                        <LangSelectionMapView
                            supportedLangs={allLangs}
                            selectedValue={selectedValue}
                            onSelect={this.onLanguageItemSelect}
                        />
                    </div>
                </div>
                <div className="btn-container">
                    <LangSelectionTotal
                        selectedOptions={selectedValue}
                        wordCount={sourceWordCount}
                        quotation={quotation}
                        onLanguageSelect={this.onLanguageItemSelect}
                        onCheckout={this.onCheckout}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const sourceWordCount = state.getIn([
        'order',
        'fileUploading',
        'data',
        'wordCount',
        'sourceWordCount'
    ]);
    const langsSetting = state.getIn(['order', 'fileSettings', 'targets']);
    const selectedLanguages = ownProps.fileSettings || [];

    const languagesState = state.getIn(['order', 'languages']);
    const languages = languagesState ? languagesState.toJS() : {};
    const allLanguages = languages.all || [];
    let unionLangs = [];

    selectedLanguages.forEach(sheet => {
        unionLangs = unionWith(
            unionLangs,
            get(sheet, 'targetColumns', []),
            (a, b) => a.lang === b.lang
        );
    });
    unionLangs = unionLangs.filter(langItem => {
        return allLanguages.find(element => element.id === langItem.lang);
    });

    const defaultItems =
        langsSetting || unionLangs.map(element => element.lang);

    return {
        defaultSelectedValue: allLanguages.filter(lang =>
            defaultItems.find(element => element === lang.id)
        ),
        languages,
        sourceWordCount
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchAllLanguages,
            checkoutOrder,
            calculateOrderPrice
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(MarketSelectionStep));
