import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, object, bool, string } from 'prop-types';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { Alert, Spinner, toJS } from 'components';
import { fetchQuestionList, closeMessage } from './actions';
import QSelectionListView from './QSelectionListView';
import SourceDetailsView from './SourceDetailsView';
import NoQuestionSVG from '../assets/images/no-questions.svg';
import NoUnresolveQuestionSVG from '../assets/images/no-unresolve.svg';

import './QnAList-style.css';
import { checkAllQuestionResolved } from './help';

function returnFirstValue(sources) {
    const arr = Object.keys(sources ? sources : 'null');
    const selectedSourceText = arr.length ? arr[0] : null;
    const selectedQuestions = selectedSourceText
        ? sources[selectedSourceText]
        : [];

    const unresolvedArr =
        arr.map(key => {
            const questions = sources[key] || [];
            return (
                !checkAllQuestionResolved(questions) && {
                    key,
                    questions
                }
            );
        }) || [];
    let unresolved = {
        selectedQuestions: [],
        selectedSourceText: null
    };

    if (unresolvedArr.filter(Boolean).length) {
        unresolved = {
            selectedQuestions: unresolvedArr[0].questions,
            selectedSourceText: unresolvedArr[0].key
        };
    }
    return {
        all: {
            selectedQuestions,
            selectedSourceText
        },
        unresolved
    };
}

class QnAList extends React.PureComponent {
    static propTypes = {
        id: string,
        data: object,
        fetchData: func,
        loading: bool
    };

    state = {
        selectedQuestions: [],
        selectedSourceText: null,
        firstDefaultValue: {
            all: {
                selectedQuestions: [],
                selectedSourceText: null
            },
            unresolved: {
                selectedQuestions: [],
                selectedSourceText: null
            }
        },
        tapFirstValue: {
            selectedQuestions: [],
            selectedSourceText: null
        }
    };

    onQuestionItemSelect = ({ questions, key }) => {
        this.setState({
            tapFirstValue: {
                selectedQuestions: questions,
                selectedSourceText: key
            }
        });
    };

    onTapSwitched = value => {
        const { firstDefaultValue } = this.state;
        this.setState({
            tapFirstValue: firstDefaultValue[value]
        });
    };

    componentDidMount() {
        const { loading, id, fetchData } = this.props;
        if (!loading) {
            fetchData(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { error, id, fetchData, data } = this.props;
        if (prevProps.data !== data) {
            const { all, unresolved } = returnFirstValue(data);
            this.setState({
                firstDefaultValue: {
                    all,
                    unresolved
                },
                tapFirstValue: all
            });
        }
        if (!error && !!prevProps.error) {
            fetchData(id);
        }
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack('/orders');
    };

    render() {
        const { loading, id, data, closeMessage, error } = this.props;
        const { tapFirstValue } = this.state;
        const { selectedSourceText, selectedQuestions } = tapFirstValue;

        if (error) {
            return (
                <Alert
                    type="danger"
                    title="Oops! Something went wrong"
                    message={`We encountered an unexpected problem. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`}
                    onClose={closeMessage}
                    className="error-alert error-message"
                />
            );
        }

        if (loading || !data) {
            return <Spinner />;
        }

        return (
            <div className="qna-list container-full">
                <span className="btn btn-back" onClick={this.handleBack}>
                    <i className="fas fa-arrow-left" />
                    Back
                </span>
                <div className="box container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-slection-list-view content-padding">
                            <div className="page-title">
                                {`Q&A - Order ID ${id}`}
                            </div>
                            <div className="box-content">
                                <QSelectionListView
                                    selectedSourceText={selectedSourceText}
                                    sources={data}
                                    onSelect={this.onQuestionItemSelect}
                                    onTapSwitched={this.onTapSwitched}
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-details-view">
                            {Object.keys(data).length === 0 && (
                                <div className="no-question-box">
                                    <div className="no-question-content">
                                        <img
                                            src={NoQuestionSVG}
                                            alt="no Question"
                                        />
                                        <h2>
                                            You don’t have any questions yet.
                                        </h2>
                                        <p>
                                            Questions on your orders will appear
                                            here.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {Object.keys(data).length !== 0 &&
                                selectedQuestions.length === 0 && (
                                    <div className="no-question-box">
                                        <div className="no-question-content">
                                            <img
                                                src={NoUnresolveQuestionSVG}
                                                alt="no Unresolve Question"
                                            />
                                            <h2>
                                                You don’t have any unresolved
                                                questions.
                                            </h2>
                                            <p>
                                                Great, you have resolved all
                                                questions.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            {selectedQuestions.length > 0 && (
                                <div className="box-content">
                                    <SourceDetailsView
                                        data={selectedQuestions}
                                        selectedSourceText={selectedSourceText}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const detailId = get(ownProps, ['match', 'params', 'id']);

    return {
        loading: state.getIn(['customer', 'questions', 'loading']),
        id: detailId,
        data: state.getIn(['customer', 'questions', 'data']),
        error: state.getIn(['customer', 'error'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchData: fetchQuestionList,
            closeMessage
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(toJS(withRouter(QnAList)));
