import { fromJS } from 'immutable';
import { createReducer } from '../store/utils';
import { TRACKER } from './actionConstants';

const INITIAL_STATE = fromJS({
    loading: false,
    error: null,
    actionState: null,
    orderId: null,
    order: null,
    user: null
});

const handlers = {
    [TRACKER.CLOSE_MESSAGE](state) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
        });
    },
    [TRACKER.EXECUTING](state) {
        return state.setIn(['loading'], true);
    },
    [TRACKER.ON_FAILED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], data);
            mutableState.setIn(['loading'], false);
        });
    },
    [TRACKER.ON_ORDER_FETCHED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['error'], null);
            mutableState.setIn(['loading'], false);

            mutableState.setIn(['order'], data.order);
            mutableState.setIn(['orderId'], data.orderNumber);
            mutableState.setIn(['user'], data.user);
            mutableState.setIn(['project'], data.project);
        });
    },
    [TRACKER.ON_CONFIRMED](state, { data }) {
        return state.withMutations(mutableState => {
            mutableState.setIn(['actionState'], data.state);
            mutableState.setIn(['loading'], false);
        });
    }
};

export default createReducer(INITIAL_STATE, handlers);
