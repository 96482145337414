import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spinner, toJS } from 'components';
import { PAGES } from 'common/const';
import { queryParamsToObject } from 'common/urlQueryUtils';

import {
    fetchInvoice,
    setupPayment,
    executePayment,
    lockPayment,
    closeMessage
} from './actions';
import PaymentMethod from './PaymentMethod';
import LangSummaryTable from './LangSummaryTable';
import PaymentInfo from './PaymentInfo';
import PaymentSummary from './PaymentSummary';
import PaidSuccessResult from './PaidSuccessResult';
import ErrorHandler from './ErrorHandler';

import './PaymentPage-style.css';

class PaymentPage extends React.PureComponent {
    static defaultProps = {
        payment: {},
        invoice: {},
        user: {}
    };

    state = {
        selectedMethod: ''
    };

    componentDidMount() {
        const { loading, fetchInvoice, location } = this.props;
        const params = queryParamsToObject(location.search);

        if (!loading && params.iid) {
            fetchInvoice(params.iid);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, fetchInvoice, location } = this.props;
        const currentQueryParams = queryParamsToObject(location.search);
        const prevQueryParams = queryParamsToObject(prevProps.location.search);

        if (
            !loading &&
            currentQueryParams.iid &&
            currentQueryParams.iid !== prevQueryParams.iid
        ) {
            fetchInvoice(currentQueryParams.iid);
        }
    }

    handleMethodChange = value => {
        this.setState({ selectedMethod: value });
    };

    render() {
        const {
            loading,
            user,
            paymentConfig,
            error,
            paymentState,
            setupPayment,
            executePayment,
            payment_intent_client_secret,
            lockPayment,
            closeMessage
        } = this.props;
        const errorAlert = (
            <ErrorHandler error={error} onClose={closeMessage} />
        );

        if (paymentState === 'Paid') {
            return <PaidSuccessResult />;
        }

        const { selectedMethod } = this.state;
        const invoice =
            (selectedMethod === 'card' || selectedMethod === 'saved_card') &&
            this.props.invoice._withFees
                ? this.props.invoice._withFees
                : this.props.invoice;

        return (
            <div className="page-content payment-page">
                {loading && <Spinner />}
                {error && errorAlert}
                <div className="order-main">
                    <section className="step-wrapper">
                        <div className="header">
                            <a href={PAGES.LANDING} className="navbar-brand">
                                <img
                                    className="logo"
                                    title="Home"
                                    alt="logo"
                                    src="/img/logo_ld_large.png"
                                />
                            </a>
                        </div>
                        <div className="step-page checkout-and-pay container">
                            <div className="page-title">
                                Translation service order
                            </div>
                            <div className="summary-container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <LangSummaryTable
                                            data={
                                                invoice ? invoice.projects : []
                                            }
                                        />
                                        <PaymentSummary invoice={invoice} />
                                    </div>

                                    <div className="summary-info-col col-lg-5">
                                        <PaymentInfo user={user} />
                                        {invoice && !invoice.isPaid && (
                                            <PaymentMethod
                                                key={`${selectedMethod}-${invoice.totalCost}`}
                                                invoice={invoice}
                                                selectedMethod={selectedMethod}
                                                user={user}
                                                payment={paymentConfig}
                                                setupPayment={setupPayment}
                                                executePayment={executePayment}
                                                payment_intent_client_secret={
                                                    payment_intent_client_secret
                                                }
                                                lockPayment={lockPayment}
                                                onSelect={
                                                    this.handleMethodChange
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container">
                                <div className="container-full">
                                    {invoice && !invoice.isPaid && (
                                        <button
                                            type="button"
                                            className="btn btn-next"
                                            disabled={true}
                                        >
                                            {`Pay ${invoice.currency || '$'} ${
                                                invoice.totalCost
                                            }`}
                                        </button>
                                    )}
                                    {invoice && invoice.isPaid && (
                                        <div className="text-success">
                                            <i className="fas fa-check" /> Paid
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div>
                                Localize Direct AB, Henckels Torg 4, SE-252 36,
                                Helsingborg, Sweden
                            </div>
                            <div>+46 42 181962 | VAT No: SE556783457601</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.getIn(['payment', 'loading']),
        error: state.getIn(['payment', 'error']),
        invoice: state.getIn(['payment', 'invoice']),
        user: state.getIn(['payment', 'user']),
        paymentConfig: state.getIn(['payment', 'paymentConfig']),
        payment_intent_client_secret: state.getIn([
            'payment',
            'payment',
            'payment_intent_client_secret'
        ]),
        paymentState: state.getIn(['payment', 'state'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchInvoice,
            setupPayment,
            executePayment,
            lockPayment,
            closeMessage
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PaymentPage));
