import React from 'react';

function Pagination({
    totalPages,
    handleNextClicked,
    handlePreviousClicked,
    handlePageClicked,
    currentPage
}) {
    return (
        <nav aria-label="...">
            <ul className="pagination">
                <li
                    onClick={handlePreviousClicked}
                    className={`page-item ${
                        currentPage <= 1 ? 'disabled' : ''
                    }`}
                    style={{
                        pointerEvents: currentPage <= 1 ? 'none' : '',
                        cursor: 'pointer'
                    }}
                >
                    <span className="page-link" href="#">
                        Previous
                    </span>
                </li>
                {Array.from(Array(totalPages).keys()).map(index => (
                    <li
                        key={index}
                        onClick={() => handlePageClicked(index + 1)}
                        className={`page-item ${
                            currentPage === index + 1 ? 'active' : ''
                        }`}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <span className="page-link" href="#">
                            {index + 1}
                        </span>
                    </li>
                ))}
                <li
                    onClick={handleNextClicked}
                    className={`page-item ${
                        currentPage >= totalPages ? 'disabled' : ''
                    }`}
                    style={{
                        pointerEvents: currentPage >= totalPages ? 'none' : '',
                        cursor: 'pointer'
                    }}
                >
                    <span className="page-link" href="#">
                        Next
                    </span>
                </li>
            </ul>
        </nav>
    );
}

export default React.memo(Pagination);
