import { request } from './request';
import { invoicePaymentRequest } from './paymentRequest';

export function getInvoice(invoiceId) {
    return invoicePaymentRequest({
        path: `/purchase/payment/invoices/${encodeURIComponent(invoiceId)}`,
        options: {
            method: 'GET'
        }
    });
}

export function setupInvoicePayment({ invoiceId }) {
    return invoicePaymentRequest({
        path: `/purchase/payment/paypal/${invoiceId}/setup`,
        options: {
            method: 'POST',
            body: JSON.stringify({})
        }
    });
}

export function executeInvoicePayment({ invoiceId, token, method, params }) {
    return invoicePaymentRequest({
        path: `/purchase/payment/${method}/${invoiceId}/execute`,
        options: {
            method: 'POST',
            body: JSON.stringify({ token, params })
        }
    });
}

export function executePayment(orderId, csrf_token, token, method, params) {
    const execute = method !== 'bypass' ? 'execute' : '';

    return request({
        path: `/purchase/order/pay/${method}/${orderId}/${execute}`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify(Object.assign({ token }, { params }))
        }
    });
}

export function setupPayment(orderId, csrf_token) {
    return request({
        path: `/purchase/order/pay/paypal/${orderId}/setup`,
        options: {
            method: 'POST',
            headers: {
                csrf_token
            },
            body: JSON.stringify({})
        }
    });
}
