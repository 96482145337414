import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, func } from 'prop-types';
import {
    calculateFile,
    getFileContent,
    removeFile,
    downloadFile,
    onFileSettingInput
} from './actions';
import { toJS } from 'components';
import FileDownload from './FileDownload';

import './FileSettingInput-style.css';

const DEFAULT_START_ROW = 1;

class FileSettingPreview extends React.Component {
    static propTypes = {
        rawFile: string,
        fileName: string,
        fileType: string,
        downloadFile: func,
        onFileSettingInput: func
    };

    state = {
        sheets: [[{ id: 'sourceLanguage' }]],
        sheetStartRows: [DEFAULT_START_ROW]
    };

    onDownload = () => {
        const { orderId, fileName, downloadFile } = this.props;

        downloadFile(orderId, fileName);
    };

    onContinueClick = () => {
        const { orderId, calculateFile, onFileSettingInput } = this.props;
        const { sheets, sheetStartRows } = this.state;

        onFileSettingInput(sheets);
        calculateFile(orderId, sheets, sheetStartRows);
    };

    componentDidMount() {
        const { orderId, fileName, getFileContent } = this.props;

        getFileContent(orderId, fileName);
    }

    render() {
        const { rawFile, fileName, removeFile } = this.props;

        return (
            <div className="step-page setup-file">
                <div className="page-title">View your file</div>

                <div className="file-setting-row">
                    <pre className="raw-file">{rawFile}</pre>

                    <div className="btn-container">
                        <div className="container-full">
                            <FileDownload
                                fileName={fileName}
                                onDownload={this.onDownload}
                                onRemove={removeFile}
                            />
                            <button
                                type="button"
                                className="btn btn-next"
                                disabled={false}
                                onClick={this.onContinueClick}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        rawFile: state.getIn(['order', 'fileUploading', 'rawFile'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            calculateFile,
            removeFile,
            downloadFile,
            getFileContent,
            onFileSettingInput
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(
    toJS(FileSettingPreview)
);
