import React from 'react';

import './FileFormatList-style.css';

export const getFileFormat = fileType => {
    let format = null;
    const type = fileType
        ? fileType.substr(1, fileType.length).toLowerCase()
        : null;

    switch (type) {
        case 'xls':
        case 'xlsx':
            format = {
                icon: <i className="far fa-file" />,
                name: 'Excel file'
            };
            break;
        case 'csv':
            format = {
                icon: <i className="far fa-file" />,
                name: 'CSV file'
            };
            break;

        default:
            format = {
                icon: <i className="far fa-file" />,
                name: type ? `${type.toUpperCase()} file` : ''
            };
    }

    format.type = fileType.substr(1, 4);

    return format;
};

export const FileFormatItem = ({ fileType }) => {
    const format = getFileFormat(fileType);

    return (
        <div title={format.name} className="file-format-icon">
            {format.icon}
            <div className="file-type">{format.type}</div>
        </div>
    );
};

const FileFormatList = ({ formats }) => {
    const items = [];

    formats.forEach(format => {
        items.push(<FileFormatItem key={format} fileType={format} />);
    });

    return <div className="file-formats">{items}</div>;
};

export default FileFormatList;
