/***
 * #Prerequisites
 *      https://stripe.com/docs/stripe-js/elements/payment-request-button#prerequisites
 * #Testing
 *      https://stripe.com/docs/stripe-js/elements/payment-request-button#testing
 *      Apple pay
 *          https://support.apple.com/en-vn/HT204506
 */
import React, { Component } from 'react';
import { bool, object, func } from 'prop-types';
import {
    PaymentRequestButtonElement,
    ElementsConsumer
} from '@stripe/react-stripe-js';
import { PAYMENT_METHOD, DEFAULT_CURRENCY } from 'common/const';
import round from 'lodash/round';

const ErrorMessage = error => {
    return (
        <div className="alert alert-danger" role="alert">
            {error.message || ''}
        </div>
    );
};

const zeroDecimalCurrency = amount => {
    return round(amount * DEFAULT_CURRENCY.ZERO_DECIMAL, 0);
};

class PaymentRequestForm extends Component {
    constructor(props) {
        super(props);
        const { stripe, invoice, currency } = props;
        const currencyCode = currency
            ? currency.CODE
            : invoice.currency || DEFAULT_CURRENCY.CODE;

        const paymentRequest = stripe.paymentRequest({
            country: 'SE',
            currency: currencyCode.toLowerCase(),
            total: {
                label: 'Total cost',
                amount: zeroDecimalCurrency(invoice.totalCost)
            }
        });

        this.state = {
            canMakePayment: false,
            paymentRequest,
            error: null
        };
    }

    static propTypes = {
        show: bool,
        invoice: object.isRequired,
        user: object.isRequired,
        payment: object.isRequired,
        currency: object,
        executePayment: func.isRequired,
        lockPayment: func.isRequired,
        onReady: func
    };

    async componentDidUpdate(prevProps, prevState) {
        const {
            show,
            payment_intent_client_secret,
            stripe,
            executePayment,
            lockPayment
        } = this.props;

        if (
            payment_intent_client_secret !==
                prevProps.payment_intent_client_secret &&
            show
        ) {
            const result = await stripe.handleCardAction(
                payment_intent_client_secret
            );

            if (result.error) {
                lockPayment(false);
                this.setState({ error: result.error });
                throw result.error;
            } else {
                executePayment({}, PAYMENT_METHOD.CARD, {
                    payment_intent_id: result.paymentIntent.id
                });
            }
        }
    }

    componentDidMount() {
        const { paymentRequest } = this.state;
        const { executePayment, lockPayment, onReady } = this.props;

        paymentRequest.on('paymentmethod', async ev => {
            lockPayment(true);
            this.setState({ error: null });
            executePayment({}, PAYMENT_METHOD.CARD, {
                payment_method_id: ev.paymentMethod.id
            });

            ev.complete('success');
        });

        paymentRequest.canMakePayment().then(result => {
            this.setState({ canMakePayment: !!result });

            if (onReady) {
                onReady(!!result);
            }
        });
    }

    render() {
        const { error, canMakePayment, paymentRequest } = this.state;
        const { invoice, show, currency } = this.props;

        return (
            <div
                className={`saved-cards-pay-button checkout ${
                    show ? 'show' : 'hide'
                }`}
            >
                {this.state.error && ErrorMessage(error)}
                {canMakePayment && (
                    <div className="btn btn-next">
                        {`Pay ${
                            currency ? currency.ICON : invoice.currency || '€'
                        } ${invoice.totalCost}`}
                        <PaymentRequestButtonElement
                            options={{
                                paymentRequest,
                                className: 'payment-request-button',
                                style: {
                                    paymentRequestButton: {
                                        theme: 'dark',
                                        height: '64px'
                                    }
                                }
                            }}
                            paymentRequest={paymentRequest}
                            className="payment-request-button"
                            style={{
                                paymentRequestButton: {
                                    theme: 'dark',
                                    height: '64px'
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const InjectedPaymentRequestForm = props => (
    <ElementsConsumer>
        {({ stripe }) => {
            return stripe ? (
                <PaymentRequestForm {...props} stripe={stripe} />
            ) : (
                <span />
            );
        }}
    </ElementsConsumer>
);

export default InjectedPaymentRequestForm;
