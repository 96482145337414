import React from 'react';

import { toIntCurrencyString, toFloatCurrencyString } from 'common/stringUtils';

import './LangSummaryTable-style.css';

const UNITS = {
    w: 'words',
    h: 'hours',
    d: 'days',
    st: 'item'
};

const LangSummaryTable = ({ data }) => {
    const buildLangItems = langDataRows => {
        const items = [];

        langDataRows &&
            langDataRows.forEach((data, index) => {
                items.push(
                    <li
                        key={`lang-sum-item-${index}`}
                        className="lang-summary-item"
                    >
                        <div className="cell name" title={data.name}>
                            {data.name}
                        </div>
                        <div className="cell count">
                            {toIntCurrencyString(data.wordCount)}
                        </div>
                        <div className="cell unit">
                            {UNITS[data.unit] || data.unit}
                        </div>
                        <div className="cell cost-per-word">
                            {toFloatCurrencyString(data.costPerWord)}
                        </div>
                        <div className="cell discount">
                            {data.discount > 0 ? `${data.discount} %` : ''}
                        </div>
                        <div className="cell total-cost">
                            {toFloatCurrencyString(data.totalCost)}
                        </div>
                    </li>
                );
            });

        return items;
    };

    const buildProjectItems = projects => {
        const items = [];

        projects &&
            projects.forEach((project, index) => {
                items.push(
                    <div
                        key={project.name + index}
                        className="content-container box"
                    >
                        <div className="project-info-wrapper">
                            <span className="label">Project:</span>
                            <span>{' ' + project.name}</span>
                        </div>
                        <div className="lang-summary-table">
                            <div className="header">
                                <div className="cell name">Name</div>
                                <div className="cell count">Quantity</div>
                                <div className="cell unit">Unit</div>
                                <div className="cell cost-per-word">
                                    Price
                                    <br />
                                    per unit
                                </div>
                                <div className="cell discount">Discount</div>
                                <div className="cell total-cost">Total</div>
                            </div>
                            <ul className="body">
                                {buildLangItems(project.rows)}
                            </ul>
                        </div>
                    </div>
                );
            });

        return items;
    };

    return (
        <div className="summary order-summary">
            <div className="title">Summary</div>
            {buildProjectItems(data)}
        </div>
    );
};

export default LangSummaryTable;
