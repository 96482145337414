import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import getStripe from './getStripe';
import PaymentRequestForm from './PaymentRequestForm';

class PaymentRequestProvider extends Component {
    static propTypes = {
        invoice: object.isRequired,
        currency: object,
        user: object.isRequired,
        payment: object.isRequired,
        executePayment: func.isRequired,
        lockPayment: func.isRequired,
        onReady: func
    };
    render() {
        const { currency, payment } = this.props;
        const stripePromise = getStripe(payment.stripeKey);

        return (
            <Elements stripe={stripePromise}>
                <PaymentRequestForm
                    key={`paymentRequestFormKey${
                        currency ? currency.CODE : ''
                    }`}
                    {...this.props}
                />
            </Elements>
        );
    }
}

export default PaymentRequestProvider;
