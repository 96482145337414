import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import { toJS } from 'components';
import {
    lockPayment,
    setupPayment,
    executePayment,
    setOrderCurrency,
    downloadQuote
} from './actions';
import OrderSummary from './OrderSummary';
import PaymentSummary from './PaymentSummary';
import PaymentInfo from './PaymentInfo';
import PaymentMethod from './PaymentMethod';
import CurrencySelection from './CurrencySelection';
import FileDownload from './FileDownload';

import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

import './PaymentStep-style.css';

class PaymentStep extends React.PureComponent {
    state = {
        selectedMethod: ''
    };

    handleCurrencyChange = value => {
        const { quotation, setOrderCurrency, orderId } = this.props;

        const currency =
            CURRENCIES[get(quotation, 'currency')] || DEFAULT_CURRENCY;

        if (value !== currency.CODE) {
            setOrderCurrency(orderId, value);
        }
    };

    handleDownloadQuote = () => {
        const { info, downloadQuote, orderId } = this.props;

        const safeName =
            info && info.gameTitle
                ? info.gameTitle.replace(/[^a-z0-9]/gi, '').toLowerCase()
                : '';
        const fileName = safeName ? `quote-${safeName}.pdf` : 'quote.pdf';

        downloadQuote(orderId, fileName);
    };

    handleMethodChange = value => {
        this.setState({ selectedMethod: value });
    };

    render() {
        const {
            file,
            fileType,
            selectedLanguages,
            wordCount,
            user,
            allLanguages,
            payment,
            lockPayment,
            setupPayment,
            executePayment,
            orderId,
            payment_intent_client_secret
        } = this.props;

        const { selectedMethod } = this.state;
        const quotation =
            (selectedMethod === 'card' || selectedMethod === 'saved_card') &&
            this.props.quotation._withFees
                ? this.props.quotation._withFees
                : this.props.quotation;

        const onContinue = () => {};
        const currency =
            CURRENCIES[get(quotation, 'currency')] || DEFAULT_CURRENCY;

        if (!(selectedLanguages && allLanguages)) {
            return <span />;
        }

        return (
            <div className="step-page checkout-and-pay">
                <div className="page-title">{'Complete & Pay'}</div>

                <div className="summary-container">
                    <div className="row">
                        <div className="col-md-7">
                            <OrderSummary
                                file={file}
                                fileType={fileType}
                                selectedLanguages={selectedLanguages}
                                wordCount={wordCount}
                                quotation={quotation}
                                allLanguages={allLanguages}
                            />
                            <PaymentSummary quotation={quotation} />
                        </div>

                        <div className="summary-info-col col-md-5">
                            <PaymentInfo user={user} />
                            <div className="summary payment-info user-profile payment-currency">
                                <span className="title">Pay in</span>
                                <CurrencySelection
                                    value={currency.CODE}
                                    onSelect={this.handleCurrencyChange}
                                    requiredEdit
                                />
                            </div>
                            {user && payment.stripeKey ? (
                                <PaymentMethod
                                    key={`${selectedMethod}-${quotation.totalCost}`}
                                    invoice={quotation}
                                    selectedMethod={selectedMethod}
                                    user={user}
                                    payment={payment}
                                    lockPayment={lockPayment}
                                    setupPayment={setupPayment}
                                    executePayment={executePayment}
                                    payment_intent_client_secret={
                                        payment_intent_client_secret
                                    }
                                    onSelect={this.handleMethodChange}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="btn-container">
                    <div className="total-section row container-full">
                        <div className="col-md-7">
                            <FileDownload
                                orderId={orderId}
                                fileName={'Download preliminary quote'}
                                onDownload={this.handleDownloadQuote}
                            />
                        </div>
                        <button
                            type="button"
                            className="btn btn-next"
                            disabled={true}
                            onClick={onContinue}
                        >
                            {`Pay ${currency.ICON} ${quotation.totalCost}`}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedLanguages: state.getIn([
            'order',
            'checkout',
            'data',
            'targets'
        ]),
        allLanguages: state.getIn(['order', 'languages', 'all']),
        file: state.getIn(['order', 'fileUploading', 'data', 'original']),
        fileType: state.getIn(['order', 'fileUploading', 'data', 'type']),
        wordCount: state.getIn(['order', 'fileUploading', 'data', 'wordCount']),
        quotation: state.getIn(['order', 'quotation', 'data']),
        info: state.getIn(['order', 'info', 'data']),
        payment: state.getIn(['order', 'payment']),
        payment_intent_client_secret: state.getIn([
            'order',
            'payment',
            'payment_intent_client_secret'
        ]),
        orderState: state.getIn(['order', 'state'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            lockPayment,
            setupPayment,
            executePayment,
            setOrderCurrency,
            downloadQuote
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PaymentStep));
