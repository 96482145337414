import { SLACK_WEBHOOK_URL } from './const';
import { version } from '../../package.json';

export function submitPageViewEventToGtm(pageName, dataObj = {}) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'pageView',
        pageName: pageName,
        ...dataObj
    });
}

export function submitActionEventToGtm(actionType) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'actionSubmit',
        actionType: actionType
    });
}

function toMsgItem(label, value) {
    return value ? ` ${label}: ${value}` : '';
}

const SLACK_ICONS = {
    start: ':point_right:',
    exit: ':point_left:',
    did: ':white_check_mark:',
    failed: ':X:',
    done: ':white_check_mark:',
    error: ':X:'
};

export function notifyToSlack({
    status = 'start',
    userEmail,
    orderId,
    stepName,
    requestId,
    fileName,
    projectName,
    total,
    wordCount,
    paymentMethod,
    currency,
    errorCode
}) {
    const errorMsg =
        status === 'error'
            ? `${toMsgItem('AppVer', version)}${toMsgItem(
                  'Browser',
                  navigator.userAgent
              )}`
            : '';

    const message = `${userEmail || 'Guest'} ${
        SLACK_ICONS[status]
    } *${stepName}*${toMsgItem('Order Id', orderId)}${toMsgItem(
        'Request Id',
        requestId
    )}${toMsgItem('File name', fileName)}${toMsgItem(
        'Game name',
        projectName
    )}${toMsgItem('Total', total)}${toMsgItem('Currency', currency)}${toMsgItem(
        'Word count',
        wordCount
    )}${toMsgItem('Via', paymentMethod)}${toMsgItem(
        'Error code',
        errorCode
    )}${errorMsg}`;

    fetch(SLACK_WEBHOOK_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        },
        body: JSON.stringify({ text: message })
    }).then(response => {
        return response;
    });
}
