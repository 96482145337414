import React from 'react';
import get from 'lodash/get';

const PaymentInfo = ({ user }) => {
    return (
        <div className="summary payment-info">
            <div className="title">Payment information</div>
            <div className="content-container">
                <div className="user-profile">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="label">Customer name</div>
                            <div className="value">
                                {get(user, 'firstName', '')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="label">Email</div>
                            <div className="value">
                                {get(user, 'email', '')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="label">Country/ Region</div>
                            <div className="value">
                                {get(user, 'country', '')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="label">Address</div>
                            <div className="value">
                                {get(user, 'address', '')}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="label">ZIP code</div>
                            <div className="value">
                                {get(user, 'zipcode', '')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="label">City</div>
                            <div className="value">{get(user, 'city', '')}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="label">Phone number</div>
                            <div className="value">
                                {get(user, 'phone', '')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentInfo;
