import React, { Component } from 'react';
import { arrayOf, object } from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PAGES, THEMES } from 'common/const';
import { ErrorBoundary, AppContext, Page } from './components';
import { PaymentPage } from 'payment';
import DeliveryPage from './order/DeliveryPage';

import OrderConfirmationPage from './tracker/OrderConfirmationPage';

import './app.css';

const Routes = ({ pages }) => {
    return (
        <Switch>
            {pages.map(({ path, component, exact }) => (
                <Route key={path} exact={exact} path={path}>
                    {data => (
                        <AppContext.Consumer>
                            {ctx => {
                                return (
                                    <Page
                                        component={component}
                                        data={data}
                                        ctx={ctx}
                                    />
                                );
                            }}
                        </AppContext.Consumer>
                    )}
                </Route>
            ))}
        </Switch>
    );
};

class AppPage extends Component {
    constructor(props) {
        super(props);

        this.handleChangePage = page => {
            this.setState({ page });
        };

        this.handleChangeTheme = theme => {
            this.setState({ theme });
        };

        this.state = {
            page: PAGES.LANDING,
            theme: THEMES.TEAL,
            changePage: this.handleChangePage,
            changeTheme: this.handleChangeTheme
        };
    }

    static propTypes = {
        pages: arrayOf(object).isRequired
    };

    static defaultProps = {
        pages: [
            {
                title: '',
                path: PAGES.PAYMENT,
                component: PaymentPage,
                exact: true
            },
            {
                title: '',
                path: PAGES.DELIVERY,
                component: DeliveryPage,
                exact: true
            },
            {
                title: '',
                path: PAGES.CONFIRMATION,
                component: OrderConfirmationPage,
                exact: true
            },
            {
                title: '',
                path: PAGES.OFFERS,
                component: OrderConfirmationPage,
                exact: true
            }
        ]
    };

    render() {
        const { pages, store } = this.props;

        return (
            <Provider store={store}>
                <BrowserRouter>
                    <AppContext.Provider value={this.state}>
                        <ErrorBoundary>
                            <div className="page-container">
                                <Routes pages={pages} />
                            </div>
                        </ErrorBoundary>
                    </AppContext.Provider>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default AppPage;
