import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { string, func, any } from 'prop-types';
import { downloadFile } from '../order/actions';

class FileDownload extends React.PureComponent {
    static propTypes = {
        orderId: any,
        fileName: string,
        handleFileDownload: func
    };

    onDownload = () => {
        const {
            downloadFile,
            handleFileDownload,
            orderId,
            fileName
        } = this.props;

        if (handleFileDownload) {
            handleFileDownload({ orderId, fileName });
        } else {
            downloadFile({ orderId, fileName });
        }
    };

    render() {
        const { fileName } = this.props;
        return (
            <span
                className="file-download clickable"
                onClick={this.onDownload}
                title={fileName}
            >
                {fileName}
            </span>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({ downloadFile }, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(FileDownload);
