const open = () => {
    //window.$crisp.push(['do', 'chat:open']);
};

const showMessage = message => {
    //window.$crisp.push(['do', 'message:show', ['text', message]]);
};

const triggerMessage = (sessionKey, messages) => {
    /*const exist = window.$crisp.get('session:data', sessionKey);

    open();
    if (!exist) {
        window.$crisp.push(['set', 'session:data', [[[sessionKey, true]]]]);
        messages.forEach(message => {
            showMessage(message);
        });
    }*/
};

export default {
    showMessage,
    triggerMessage,
    open
};
