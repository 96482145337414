import React from 'react';
import { Alert } from 'components';
const PAYMENT_ERROR_CODE_PREFIX = 'FAILED_STRIPE_';
const DEFAULT_TITLE = 'Oops! Something went wrong';
const DEFAULT_MSG = `We encountered an unexpected problem. Contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`;
const PAYMENT_FAILURE_MSG = `We could not proceed your purchase with your credit card.\n <br />Please use another one or contact our Customer Service to get support at <a href="mailto:sales@localizedirect.com" target="_blank">sales@localizedirect.com</a>.`;

const ErrorHandler = ({ error, onClose }) => {
    let title = DEFAULT_TITLE;
    let message = DEFAULT_MSG;

    const errorString = JSON.stringify(error) || '';

    if (errorString.includes(PAYMENT_ERROR_CODE_PREFIX)) {
        const errorArr =
            Array.isArray(error.code) && Array.isArray(error.code[0])
                ? error.code[0]
                : [];

        title = errorArr[1] || title;
        message = PAYMENT_FAILURE_MSG;
    }

    return (
        <Alert
            type="danger"
            title={title}
            message={message}
            onClose={onClose}
            className="error-message"
        />
    );
};

export default ErrorHandler;
