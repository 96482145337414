import { RESOLVED_STATE } from './constants';

export const authorName = (members, authorId, userUid) => {
    if (authorId === userUid) return 'You';
    return members[authorId] || 'unknow';
};

export const defaultUuid = '00000000-0000-0000-0000-000000000000';

export const groupQuestionsBy = (list, groupKey = 'general') => {
    const groups = list.reduce((reduction, item) => {
        const groupKeyVal = item[groupKey];
        if (!reduction[groupKeyVal])
            reduction[groupKeyVal] = [item.ctx, [item]];
        else reduction[groupKeyVal][1].push(item);
        return reduction;
    }, {});
    return groups;
};

export const listQuestionGroups = groupMap => {
    return Object.entries(groupMap).map(([_, v]) => v);
};

export const checkAllQuestionResolved = questions => {
    let arr = questions.filter(question => question.state !== RESOLVED_STATE);
    return !arr.length;
};

export const checkAtleast1QuestionHaveNoAnswers = questions => {
    let arr = questions.filter(
        question => question.answers && question.answers.length === 0
    );
    return !!arr.length;
};
