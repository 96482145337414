import React from 'react';
import { func, string, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { MenuDropdown, toJS } from 'components';
import { fetchAllLanguages } from './actions';

import './SettingSelect-style.css';

const ADDITIONAL_OPTIONS = {
    stringIdentifier: { value: 'stringIdentifier', label: 'String Identifier' },
    maxCharacters: { value: 'maxCharacters', label: 'Max Character Limit' },
    additionalInfo: {
        value: 'additionalInfo',
        label: 'Additional Information'
    },
    dialogue: [
        {
            value: 'character',
            label: 'Character',
            group: 'dialogue'
        },
        {
            value: 'talking',
            label: 'Talking to',
            group: 'dialogue'
        },
        {
            value: 'direction',
            label: 'Direction',
            group: 'dialogue'
        }
    ]
};

export const getAdditionalOptions = () => {
    return [
        ADDITIONAL_OPTIONS['stringIdentifier'],
        ADDITIONAL_OPTIONS['maxCharacters'],
        ADDITIONAL_OPTIONS['additionalInfo']
    ].concat(ADDITIONAL_OPTIONS['dialogue']);
};

class SettingSelectClass extends React.Component {
    static propTypes = {
        languages: object,
        selectedValue: string,
        isLast: bool,
        fetchAllLanguages: func,
        onSelect: func
    };
    state = {
        selectedValue: this.props.selectedValue || 'notused'
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedValue !== state.selectedValue) {
            return {
                selectedValue: props.selectedValue || 'notused'
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    findValueInOptions = (value, options = []) => {
        for (let i = 0; i < options.length; i++) {
            if (options[i].value === value) {
                return options[i];
            } else if (options[i].options) {
                const found = this.findValueInOptions(
                    value,
                    options[i].options
                );
                if (found.value !== 'notused') {
                    return found;
                }
            }
        }

        return { value: 'notused', label: 'What is this column?' };
    };

    buildOptions = () => {
        const { languages } = this.props;
        const allLanguages = languages.all || [];
        const languagesOptions = allLanguages.map(language => ({
            value: language.id,
            label: language.name,
            group: 'targetLanguages',
            icon: (
                <span className="country-flag">
                    <ReactCountryFlag
                        code={language.id.substring(2).toLowerCase()}
                        svg
                    />
                </span>
            ),
            optionGroup: language.recommended ? 'common' : 'others'
        }));

        return [
            { value: 'sourceLanguage', label: 'Source Language (English)' },
            {
                value: 'targetLanguages',
                label: 'Target Languages',
                type: 'group',
                searchName: 'Search a language',
                optionGroups: [
                    { value: 'common', label: 'Common Languages' },
                    { value: 'others', label: 'Others' }
                ],
                options: languagesOptions
            },
            ADDITIONAL_OPTIONS['stringIdentifier'],
            ADDITIONAL_OPTIONS['maxCharacters'],
            ADDITIONAL_OPTIONS['additionalInfo'],
            {
                value: 'dialogue',
                label: 'Dialogue',
                type: 'group',
                options: ADDITIONAL_OPTIONS['dialogue']
            }
        ];
    };

    onMenuSelect = option => {
        const { selectedValue, onSelect } = this.props;

        const newOption =
            option.value === selectedValue
                ? { value: 'notused', label: 'What is this column?' }
                : option;

        this.setState({
            selectedValue: newOption.value
        });

        onSelect(newOption);
    };

    componentDidMount() {
        const { languages, fetchAllLanguages } = this.props;

        if (!languages.loading && !languages.all) {
            fetchAllLanguages();
        }
    }

    render() {
        const { selectedValue } = this.state;
        const { isLast, onSelect } = this.props;
        const componentClass = classNames('setting-select', {
            main: selectedValue === 'sourceLanguage'
        });
        const options = this.buildOptions();
        const selectedOption = this.findValueInOptions(selectedValue, options);
        const label =
            selectedOption.value === 'notused' ? selectedOption.label : null;

        if (!onSelect) {
            return <div className="title">{selectedOption.label}</div>;
        } else {
            return (
                <MenuDropdown
                    className={componentClass}
                    options={options}
                    onChange={this.onMenuSelect}
                    selectedOption={selectedOption}
                    label={label}
                    groupDirection={isLast ? 'left' : 'right'}
                />
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        languages: state.getIn(['order', 'languages'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            fetchAllLanguages
        },
        dispatch
    );
};

export const SettingSelect = connect(mapStateToProps, mapDispatchToProps)(
    toJS(SettingSelectClass)
);
