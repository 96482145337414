import React from 'react';
import OrderHome from 'order';

export class Order extends React.Component {
    render() {
        return (
            <div className="page-content">
                <OrderHome />
            </div>
        );
    }
}
