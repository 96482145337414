import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import getStripe from './getStripe';
import CheckoutForm from './CheckoutForm';

class PayProvider extends Component {
    static propTypes = {
        invoice: object.isRequired,
        user: object.isRequired,
        payment: object.isRequired,
        executePayment: func.isRequired,
        lockPayment: func.isRequired
    };
    render() {
        const { payment } = this.props;
        const stripePromise = getStripe(payment.stripeKey);

        return (
            <Elements stripe={stripePromise}>
                <CheckoutForm {...this.props} />
            </Elements>
        );
    }
}

export default PayProvider;
