import React from 'react';
import { array, func, object } from 'prop-types';

import { FileFormatItem } from './FileFormatList';
import LangSummaryTable from './LangSummaryTable';
import { CURRENCIES, DEFAULT_CURRENCY } from 'common/const';

import './OrderSummary-style.css';

class OrderSummary extends React.PureComponent {
    static propTypes = {
        selectedLanguages: array,
        allLanguages: array,
        wordCount: object,
        quotation: object,
        onBack: func
    };

    render() {
        const {
            file,
            fileType,
            selectedLanguages,
            wordCount,
            quotation,
            allLanguages
        } = this.props;
        const selectedOptions = allLanguages.filter(lang =>
            selectedLanguages.find(element => element === lang.id)
        );
        const currency = CURRENCIES[quotation.currency] || DEFAULT_CURRENCY;
        const langsPrice = quotation.items || [];
        const sourceWordCount = wordCount ? wordCount.sourceWordCount : 0;

        const data = selectedOptions.map(element => {
            const langPrice = langsPrice.find(item => item.lang === element.id);

            if (langPrice) {
                element.wordCount = langPrice.wordCount;
                element.totalCost = langPrice.cost;
                element.hasTM = langPrice.hasTM;
                element.price = langPrice.price;
            } else {
                element.price =
                    currency.CODE === CURRENCIES.USD.CODE
                        ? element.priceUSD
                        : element.price;
            }
            element.costPerWord = element.price;

            return element;
        });

        return (
            <div className="summary order-summary">
                <div className="title">Order summary</div>
                <div className="content-container box">
                    <div className="file-info-wrapper">
                        <div className="file">
                            <span className="icon">
                                <FileFormatItem fileType={fileType} />
                            </span>
                        </div>
                        <div className="info">
                            {file}
                            <div className="small-text">
                                <span className="count-text">
                                    {sourceWordCount}
                                </span>{' '}
                                word(s) to translate into{' '}
                                <span className="count-text">
                                    {selectedLanguages.length}
                                </span>{' '}
                                language(s)
                            </div>
                        </div>
                    </div>
                    <LangSummaryTable data={data} currency={currency} />
                </div>
            </div>
        );
    }
}

export default OrderSummary;
