import { requestData, receiveData } from '../api/actions';
import {
    getOrderList,
    getQuestionList,
    postAnswer,
    getMergedFile,
    getFile
} from 'services/customer';
import { groupBy } from 'lodash';

import { CUSTOMER } from './actionConstants';

export function closeMessage() {
    return {
        type: CUSTOMER.CLOSE_MESSAGE
    };
}

export function fetchOrderList({ offset = 0, limit = 50 }) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_ORDER_LIST));

        getOrderList({ offset, limit })
            .then(payload => {
                dispatch(
                    receiveData(CUSTOMER.ON_ORDER_LIST_FETCHED, payload.data)
                );
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ORDER_LIST_FAILED, e));
            });
    };
}

function formatQuestions(data) {
    return groupBy(data, 'ctx.sourceText');
}

export function fetchQuestionList(orderId) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_QUESTION_LIST));

        getQuestionList(orderId)
            .then(payload => {
                const data = formatQuestions(payload.data || []);
                dispatch(receiveData(CUSTOMER.ON_QUESTION_LIST_FETCHED, data));
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_QUESTION_LIST_FAILED, e));
            });
    };
}

export function fetchAnswerList(sourceId) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.FETCH_ANSWER_LIST));

        getQuestionList(sourceId)
            .then(payload => {
                dispatch(
                    receiveData(CUSTOMER.ON_ANSWER_LIST_FETCHED, payload.data)
                );
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ANSWER_LIST_FAILED, e));
            });
    };
}

export function addAnswer({ questionId, content, sourceText, callback }) {
    return dispatch => {
        dispatch(requestData(CUSTOMER.ADD_ANSWER));

        postAnswer(questionId, content)
            .then(payload => {
                const { data } = payload;
                dispatch(
                    receiveData(CUSTOMER.ON_ANSWER_ADDED, {
                        ...data,
                        sourceText
                    })
                );
                callback && callback();
            })
            .catch(e => {
                dispatch(receiveData(CUSTOMER.ON_ADD_ANSWER_FAILED, e));
                callback && callback(e);
            });
    };
}

export function downloadMergedFile({ orderId, fileName }) {
    return () => {
        getMergedFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}

function openDownloadedFile(fileName, payload) {
    let a = document.createElement('a');
    a.style = 'display: none';
    const blob = new Blob([payload], {
        type: 'application/octet-stream'
    });

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 100);
}

export function downloadFile({ orderId, fileName }) {
    return () => {
        getFile(orderId, fileName)
            .then(payload => {
                openDownloadedFile(fileName, payload);
            })
            .catch(e => {
                console.log(e);
            });
    };
}
