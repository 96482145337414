import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func, bool, string, number, object } from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Spinner, toJS } from 'components';
import { UserSessionCheck } from 'auth';
import { submitPageViewEventToGtm, notifyToSlack } from 'common/gtmScripts';

import {
    initNewOrder,
    fetchOrder,
    fetchAllLanguages,
    closeMessage,
    onStepBack,
    refreshSteps
} from './actions';
import StepWrapper from './StepWrapper';
import FileUpload from './FileUpload';
import FileSettingInput from './FileSettingInput';
import FileSettingPreview from './FileSettingPreview';
import MarketSelectionStep from './MarketSelectionStep';
import OrderInformation from './OrderInformation';
import PaymentStep from './PaymentStep';
import PaidSuccessResult from './PaidSuccessResult';
import StepNavBar from './StepNavBar';
import ErrorHandler from './ErrorHandler';
import { ORDERING_STATES, ORDER_STEPS } from './constants';

import './order.css';

const RAW_FILE_TYPES = [
    '.po',
    '.json',
    '.resx',
    '.xml',
    '.strings',
    '.xliff',
    '.xlf'
];

const EVENT_STEPS = [
    ORDER_STEPS.STEP_1,
    ORDER_STEPS.STEP_2,
    ORDER_STEPS.STEP_3,
    ORDER_STEPS.STEP_4,
    ORDER_STEPS.STEP_5
];

class OrderMain extends React.PureComponent {
    static propTypes = {
        orderId: string,
        loading: bool,
        calculating: bool,
        user: object,
        company: object,
        orderState: string,
        fetched: bool,
        error: object,
        currentStep: number,
        initNewOrder: func.isRequired,
        closeMessage: func
    };

    constructor(props) {
        super(props);

        window.addEventListener('beforeunload', this.onComponentWillUnmount);
    }

    isRawFile = fileType => {
        return fileType && RAW_FILE_TYPES.includes(fileType.toLowerCase());
    };

    submitEventToGtm = currentStep => {
        const eventName = EVENT_STEPS[currentStep - 1];

        if (eventName) {
            submitPageViewEventToGtm(eventName);
        }
    };

    notifyToMonitor = () => {
        const { user, orderId, currentStep } = this.props;
        const userEmail = get(user, 'email');
        const eventName = EVENT_STEPS[currentStep - 1];

        if (eventName && orderId) {
            notifyToSlack({
                userEmail,
                orderId,
                stepName: `${eventName}`,
                status: 'start'
            });
        }
    };

    onComponentWillUnmount = () => {
        const { user, orderId } = this.props;
        const userEmail = get(user, 'email');

        notifyToSlack({
            userEmail,
            orderId,
            stepName: `Exited`,
            status: 'exit'
        });
    };

    componentDidMount() {
        const {
            orderId,
            loading,
            languages,
            currentStep,
            fetched,
            initNewOrder,
            fetchOrder,
            fetchAllLanguages
        } = this.props;

        if (!loading) {
            if (!orderId) {
                initNewOrder();
            } else if (!fetched) {
                fetchOrder(orderId);
            }
        }

        if (!languages.loading && !languages.all) {
            fetchAllLanguages();
        }

        this.submitEventToGtm(currentStep);
        this.notifyToMonitor();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            orderId,
            loading,
            error,
            user,
            company,
            currentStep,
            fileUploading,
            initNewOrder,
            fetchOrder,
            refreshSteps
        } = this.props;

        if (
            !loading &&
            ((!orderId && !error) ||
                (error && error.code === 'INVOICE_ALREADY_PAID'))
        ) {
            // don't have orderId
            initNewOrder();
        } else if (!loading && !orderId && !fileUploading && currentStep > 1) {
            // don't have file uploaded
            refreshSteps();
        } else if (
            (!prevProps.user && user) ||
            (prevProps.company &&
                prevProps.company.companyUpdatedAt !== company.companyUpdatedAt) // company info changed
        ) {
            fetchOrder(orderId);
        }

        if (prevProps.currentStep !== currentStep) {
            this.submitEventToGtm(currentStep);
        }

        if (
            prevProps.orderId !== orderId ||
            !isEqual(prevProps.user, user) ||
            prevProps.currentStep !== currentStep
        ) {
            this.notifyToMonitor();
        }
    }

    render() {
        const {
            orderId,
            loading,
            calculating,
            currentStep,
            fileUploading,
            quotation,
            info,
            orderState,
            error,
            user,
            closeMessage,
            onStepBack
        } = this.props;

        const errorAlert = (
            <ErrorHandler error={error} onClose={closeMessage} />
        );

        if (loading && !fileUploading) {
            return <Spinner />;
        }

        if (orderState === ORDERING_STATES.PAID) {
            return (
                <div className="step-provider">
                    <PaidSuccessResult />
                </div>
            );
        }

        let component = <span />;

        if (!orderId) {
            component = <div className="order-main">{error && errorAlert}</div>;
        } else {
            const fileContent = get(fileUploading, ['meta']);
            const fileType = get(fileUploading, ['type']);

            component = (
                <div className="order-main">
                    {(calculating || loading) && <Spinner />}
                    {error && errorAlert}
                    <StepWrapper
                        id="order-step-1"
                        title="Upload your file"
                        displayOrder={1}
                        show={currentStep < 2}
                    >
                        <FileUpload orderId={orderId} user={user} />
                    </StepWrapper>

                    <StepWrapper
                        id="order-step-2"
                        title="Setup your file"
                        displayOrder={2}
                        show={currentStep === 2}
                    >
                        {fileContent && this.isRawFile(fileType) ? (
                            <FileSettingPreview
                                orderId={orderId}
                                fileName={get(fileUploading, ['original'])}
                                fileType={fileType}
                            />
                        ) : (
                            <FileSettingInput
                                step={currentStep}
                                orderId={orderId}
                                fileContent={fileContent}
                                fileSettings={get(fileUploading, ['settings'])}
                                fileName={get(fileUploading, ['original'])}
                                fileType={fileType}
                            />
                        )}
                    </StepWrapper>

                    <StepWrapper
                        id="order-step-3"
                        title="Select what markets/users to reach"
                        displayOrder={3}
                        show={currentStep === 3}
                    >
                        <MarketSelectionStep
                            orderId={orderId}
                            quotation={quotation}
                            fileSettings={get(fileUploading, ['settings'])}
                        />
                    </StepWrapper>

                    <StepWrapper
                        id="order-step-4"
                        title="Order information"
                        displayOrder={4}
                        show={currentStep === 4}
                    >
                        <OrderInformation
                            orderId={orderId}
                            quotation={quotation}
                            info={info}
                            user={user}
                        />
                    </StepWrapper>

                    <StepWrapper
                        id="order-step-5"
                        title="Checkout"
                        displayOrder={5}
                        show={currentStep === 5}
                    >
                        <PaymentStep user={user} orderId={orderId} />
                    </StepWrapper>
                </div>
            );
        }

        return (
            <UserSessionCheck>
                <div className="step-provider">
                    <StepNavBar
                        currentStep={currentStep}
                        onStepBack={onStepBack}
                    />
                    <div className="step-provider-body">{component}</div>
                </div>
            </UserSessionCheck>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        orderId: state.getIn(['order', 'id']),
        loading: state.getIn(['order', 'loading']),
        calculating: state.getIn(['order', 'calculating']),
        error: state.getIn(['order', 'error']),
        fetched: state.getIn(['order', 'fetched']),
        currentStep: state.getIn(['order', 'currentStep']),
        languages: state.getIn(['order', 'languages']),
        fileUploading: state.getIn(['order', 'fileUploading', 'data']),
        quotation: state.getIn(['order', 'quotation', 'data']),
        info: state.getIn(['order', 'info', 'data']),
        user: state.getIn(['auth', 'user']),
        company: state.getIn(['auth', 'company']),
        orderState: state.getIn(['order', 'state'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            initNewOrder,
            fetchOrder,
            fetchAllLanguages,
            closeMessage,
            onStepBack,
            refreshSteps
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(OrderMain));
