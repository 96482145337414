import React from 'react';
import get from 'lodash/get';

const PaymentSummary = ({ invoice }) => {
    return (
        <div className="summary payment-summary row">
            <div className="col-xl-5 col-md-4" />
            <div className="col-xl-7 col-md-8">
                <div className="cost">
                    <span className="label">Translation cost</span>
                    <span className="value bold-2">
                        {get(invoice, 'netCost', '')}
                    </span>
                </div>
                <div className="free">
                    <span className="label">Credit card processing fee</span>
                    <span className="value bold-2">
                        {get(invoice, 'totalTransactionFee', 0)}
                    </span>
                </div>
                <div className="vat">
                    <span className="label">
                        {`VAT (${get(invoice, 'currency', '')})`}
                    </span>
                    <span className="value">
                        {get(invoice, 'totalVAT', '')}
                    </span>
                </div>
                <hr className="line-2" />
                <div className="total-payed">
                    <span className="label bold-1">
                        {`Total to pay (${get(invoice, 'currency', '')})`}
                    </span>
                    <span className="value bold">
                        {get(invoice, 'totalCost', '')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentSummary;
