import React from 'react';
import get from 'lodash/get';
import { array } from 'prop-types';
import { Table } from 'components';
import { AutoSizer } from 'react-virtualized';

import { dateTimeToString, DATE_TIME_FORMAT } from 'common/timeUtils';

import FileDownload from './FileDownload';
import Status from './Status';
import OrderIdCell from './OrderIdCell';
import QnACell from './QnACell';

class OrderTable extends React.Component {
    static propTypes = {
        data: array
    };

    orderIdRender = ({ rowData, columnKey }) => {
        const orderId = rowData['id'];
        return <OrderIdCell orderId={orderId} />;
    };

    purchasedDateRender = ({ rowData, columnKey }) => {
        return (
            <span>
                {dateTimeToString(
                    rowData.createdAt,
                    DATE_TIME_FORMAT.MM_DD_YYYY
                )}
            </span>
        );
    };

    projectRender = ({ rowData, columnKey }) => {
        return <span>{get(rowData, ['project'])}</span>;
    };

    translatedFileRender = ({ rowData, columnKey }) => {
        const fileName = get(rowData, ['merge', 'original']);
        const uid = get(rowData, ['uid']);
        const { downloadMergedFile } = this.props;
        return (
            <FileDownload
                orderId={uid}
                fileName={fileName}
                handleFileDownload={downloadMergedFile}
            />
        );
    };

    orderStatusRender = ({ rowData, columnKey }) => {
        const state = rowData['state'];
        return <Status state={state} />;
    };

    qaRender = ({ rowData, columnKey }) => {
        const orderId = rowData['id'];
        const questions = rowData['questions'] || [];
        return <QnACell orderId={orderId} length={questions.length} />;
    };

    totalPayRender = ({ rowData }) => {
        const { payment } = rowData;

        if (payment) {
            let { invoice } = payment;
            try {
                invoice = JSON.parse(invoice);
            } catch (err) {
                invoice = {};
            }

            return <span>{invoice.totalCost || 0}</span>;
        } else {
            return <span>-</span>;
        }
    };

    buildColumns = () => {
        return [
            {
                label: 'Order ID',
                columnKey: 'id',
                cellRenderer: this.orderIdRender,
                flexGrow: 0,
                width: 150
            },
            {
                label: 'Purchased date',
                columnKey: 'createdAt',
                cellRenderer: this.purchasedDateRender,
                flexGrow: 0,
                width: 150
            },
            {
                label: 'Game name',
                columnKey: 'project',
                cellRenderer: this.projectRender,
                flexGrow: 1
            },
            {
                label: 'Total paid (€)',
                columnKey: 'total',
                flexGrow: 0,
                width: 150,
                cellRenderer: this.totalPayRender
            },
            {
                label: 'Translated file',
                columnKey: 'translatedFile',
                cellRenderer: this.translatedFileRender,
                flexGrow: 1
            },
            {
                label: 'Status',
                columnKey: 'state',
                cellRenderer: this.orderStatusRender,
                flexGrow: 0,
                width: 200
            },
            {
                label: 'Q&A',
                columnKey: 'sourceFile',
                cellRenderer: this.qaRender,
                flexGrow: 0,
                width: 100
            }
        ];
    };

    render() {
        const { data } = this.props;
        const columns = this.buildColumns(data);

        return (
            <div>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            className="order-table"
                            width={width}
                            columns={columns}
                            data={data}
                        />
                    )}
                </AutoSizer>
            </div>
        );
    }
}

export default OrderTable;
