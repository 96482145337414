import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withFormik } from 'formik';
import Yup from 'yup';
import get from 'lodash/get';
import { TextFieldInput, Message, CountrySelect, toJS } from 'components';
import { editProfile } from 'services/auth';
import { getMessage } from 'common/getMessage';
import { notifyToSlack } from 'common/gtmScripts';
import { onEditProfileSuccess } from './actions';
import { ERROR_CODE_INVALID_VAT_NUMBER } from './constants';

const DEFAULT_SCHEMA = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    country: Yup.string().required('Country/ Region is required'),
    companyName: Yup.string().required('Company name is required'),
    street: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required')
});

const formikEnhancer = withFormik({
    validationSchema: DEFAULT_SCHEMA,
    mapPropsToValues: ({ user, company }) => {
        const address = get(company, 'address', {});

        return {
            ...user,
            ...company,
            ...address
        };
    },
    handleSubmit: (
        payload,
        {
            setSubmitting,
            setStatus,
            props: { user, company, onEditProfileSuccess }
        }
    ) => {
        editProfile({
            city: payload.city,
            companyName: payload.companyName,
            country: payload.country,
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            password: payload.password,
            state: payload.state,
            street: payload.street,
            vatNumber: payload.vatNumber,
            userUid: user.userUid,
            companyUid: get(company, 'companyUuid')
        })
            .then(res => {
                onEditProfileSuccess({
                    user: {
                        ...user,
                        firstName: res.firstName,
                        lastName: res.lastName
                    },
                    company: {
                        ...res.company
                    }
                });
                setSubmitting(false);
            })
            .catch(e => {
                setSubmitting(false);
                setStatus({
                    error: getMessage({
                        token: e.message,
                        httpCode: e.code || e.status
                    })
                });

                notifyToSlack({
                    userEmail: payload.email,
                    status: 'failed',
                    stepName: 'Edit profile',
                    errorCode: e.message
                });
            });
    },
    displayName: 'EditProfileForm'
});

const EditProfileForm = props => {
    const {
        values,
        touched,
        errors,
        stateError,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        status,
        header
    } = props;

    const message = status && status.error && (
        <Message type="danger" message={status.error} />
    );

    const isInvalidVAT =
        stateError && stateError.code === ERROR_CODE_INVALID_VAT_NUMBER;

    return (
        <div className="edit-profile-form">
            {message}
            {!!header && header}
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                    <div className="col-md-6">
                        <TextFieldInput
                            id="firstName"
                            type="text"
                            label="First name"
                            placeholder=""
                            error={touched.firstName && errors.firstName}
                            value={values.firstName}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextFieldInput
                            id="lastName"
                            type="text"
                            label="Last name"
                            placeholder=""
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Email</label>
                        <div className="value">{values.email}</div>
                    </div>
                    <div className="col-md-6">
                        <TextFieldInput
                            id="companyName"
                            type="text"
                            label="Company name"
                            placeholder=""
                            error={touched.companyName && errors.companyName}
                            value={values.companyName}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <CountrySelect
                            id="country"
                            label="Country/ Region"
                            value={values.country}
                            required
                            placeholder=""
                            error={touched.country && errors.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextFieldInput
                            id="street"
                            type="text"
                            label="Address"
                            placeholder="Area and street address"
                            error={touched.street && errors.street}
                            value={values.street}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextFieldInput
                            id="city"
                            type="text"
                            label="City"
                            placeholder=""
                            error={touched.city && errors.city}
                            value={values.city}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextFieldInput
                            id="vatNumber"
                            type="text"
                            label="VAT number"
                            placeholder=""
                            error={isInvalidVAT ? 'Invalid VAT number' : null}
                            value={values.vatNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className="note">
                            E.g: Your country code is SE and VAT number is
                            123456789000. VAT number format will be
                            SE123456789000.
                        </div>
                        <div className="read-more-link">
                            <a
                                href="http://ec.europa.eu/taxation_customs/vies/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Information on how to check the VAT number
                            </a>
                        </div>
                    </div>
                </div>

                <div className="submit-btns">
                    <button
                        type="submit"
                        className={`btn btn-signup ${
                            isSubmitting ? 'loading' : ''
                        }`}
                        disabled={isSubmitting || !isValid}
                    >
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

const EnhancedForm = formikEnhancer(EditProfileForm);

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.getIn(['auth', 'user']),
        company: state.getIn(['auth', 'company']),
        stateError: state.getIn(['order', 'error'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            onEditProfileSuccess
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EnhancedForm));
