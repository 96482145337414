import React from 'react';
import { array, bool, func } from 'prop-types';
import xor from 'lodash/xor';

import ButtonItem from './ButtonItem';
import './ButtonGroup-style.css';

class ButtonGroup extends React.PureComponent {
    static propTypes = {
        items: array,
        isMultipleChoice: bool,
        selectedItems: array,
        onChange: func
    };

    static defaultProps = {
        selectedItems: []
    };

    onSelect = ({ value }) => {
        const { isMultipleChoice, selectedItems, onChange } = this.props;
        let values = [value];

        if (isMultipleChoice) {
            values = xor(selectedItems, [value]);
        }

        if (onChange) {
            onChange(values);
        }
    };

    render() {
        const { items, selectedItems } = this.props;
        const btnItems = [];

        items.forEach((item, index) => {
            btnItems.push(
                <ButtonItem
                    key={`btn_item_${index}`}
                    value={item.value}
                    label={item.label}
                    selected={selectedItems.includes(item.value)}
                    onSelect={this.onSelect}
                />
            );
        });

        return <div className="button-selection-group">{btnItems}</div>;
    }
}

export default ButtonGroup;
