import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func } from 'prop-types';

import { toJS } from 'components';
import { showModal, logOut, fetchUserCompanyInfo } from './actions';
import { FORM_TYPES, ERROR_CODE_INVALID_VAT_NUMBER } from './constants';
import AuthModal from './AuthModal';
import UserDropdown from './UserDropdown';

import './UserNav-style.css';

class UserNav extends React.Component {
    static propTypes = {
        showModal: func
    };

    onSignInClick = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.SIGN_IN);
    };

    onSignUpClick = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.SIGN_UP);
    };

    onEdit = () => {
        const { showModal } = this.props;

        showModal(FORM_TYPES.EDIT_PROFILE);
    };

    onLogOut = history => {
        const { logOut } = this.props;

        logOut(history);
    };

    isAuthenticated = user => {
        return !!user;
    };

    componentDidMount() {
        const { user, company, fetchUserCompanyInfo } = this.props;

        if (!company && this.isAuthenticated(user)) {
            fetchUserCompanyInfo(user);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            company,
            fetchUserCompanyInfo,
            error,
            showModal
        } = this.props;

        if (!company && this.isAuthenticated(user)) {
            fetchUserCompanyInfo(user);
        }

        if (
            !prevProps.error &&
            error &&
            error.code === ERROR_CODE_INVALID_VAT_NUMBER
        ) {
            showModal(FORM_TYPES.EDIT_PROFILE);
        }
    }

    render() {
        const { user } = this.props;
        const isAuthenticated = this.isAuthenticated(user);

        return (
            <div className="user-nav">
                {isAuthenticated && (
                    <UserDropdown
                        user={user}
                        handleLogout={this.onLogOut}
                        handleEdit={this.onEdit}
                    />
                )}
                {!isAuthenticated && (
                    <div className="auth-group">
                        <button
                            type="button"
                            className="btn btn-login"
                            onClick={this.onSignInClick}
                        >
                            Log in
                        </button>
                        <button
                            type="button"
                            className="btn btn-signup"
                            onClick={this.onSignUpClick}
                        >
                            Sign up
                        </button>
                    </div>
                )}
                <AuthModal />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.getIn(['auth', 'user']),
        company: state.getIn(['auth', 'company']),
        error: state.getIn(['order', 'error'])
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchUserCompanyInfo,
            showModal,
            logOut
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(UserNav));
