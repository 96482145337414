import { invoicePaymentRequest } from './paymentRequest';

// Get Fortnox Order
export function getOrder(orderId) {
    return invoicePaymentRequest({
        path: `/tracker/orders/${encodeURIComponent(orderId)}`,
        options: {
            method: 'GET'
        }
    });
}

export function confirmOrder({ orderId, state, comments }) {
    return invoicePaymentRequest({
        path: `/tracker/orders/${encodeURIComponent(orderId)}/confirm`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                state,
                comments
            })
        }
    });
}
